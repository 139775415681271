// ** React Imports
import React from "react";

// ** MUI Imports
import { Box, Typography, Grid } from "@mui/material";

import CardsDesignPdfGenerator from "./CardsDesignPdfGenerator";

//USE ONLY WITH PREVIEW
// import { PDFViewer } from "@react-pdf/renderer";
// import CardsPdfDocument from "./CardsPdfDocument";

const InvitationCard = ({
  cardData,
  cardsBackgroundImageNameAndTitle,
  eventData,
}) => {
  const { groomsName, description, logo, qrCodeImage } = cardData;
  const { title, name, bgImg } = cardsBackgroundImageNameAndTitle;

  const pdfData = { ...cardData, ...cardsBackgroundImageNameAndTitle };

  const {
    name: eventName,
    payment: { eventMaxPeople },
  } = eventData;

  const documentName = `${eventName}_${eventMaxPeople}`;

  return (
    <Grid item xs={12} md={6} lg={4} marginBottom={5}>
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
        <Typography variant="h5">{name}</Typography>
        <Box
          sx={{
            height: "189px",
            aspectRatio: 9 / 5,
            position: "relative",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            marginBottom: "20px",
            alignSelf: "center",
          }}
        >
          <img
            src={bgImg}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: "0",
            }}
            alt="Card"
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              padding: "18px 19px 20px 19px",
              zIndex: "1",
            }}
          >
            <span
              style={{
                fontFamily: "PinyonScriptRegular",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "26px",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {groomsName}
            </span>
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                marginTop: "6px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#E91E63",
                    fontFamily: "MontserratBold",
                    fontWeight: 700,
                    fontSize: "10px",
                  }}
                >
                  {title}
                </span>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      marginTop: title ? "8px" : "6px",
                      padding: "0 3px",
                      fontFamily: "MontserratItalic",
                      fontWeight: 400,
                      fontSize: "10px",
                      fontStyle: "italic",
                      whiteSpace: "pre-line",
                      color: "black",
                    }}
                  >
                    {description}
                  </span>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: "72px", height: "24px", marginTop: "6px" }}
                  />
                </Box>
              </Box>
              <img
                src={qrCodeImage}
                alt="QR Code"
                style={{ width: "100px", height: "100px" }}
              />
            </Box>
          </Box>
        </Box>
        {/* When working with PDF de-comment this line to see in page a preview of the PDF cards */}
        {/* <PDFViewer height={"205px"} showToolbar={false} style={{border: '0px', backgroundColor:"white"}} >
          <CardsPdfDocument pdfData={pdfData} />
        </PDFViewer> */}
        <CardsDesignPdfGenerator
          pdfData={pdfData}
          documentName={documentName}
        />
      </Box>
    </Grid>
  );
};

export default InvitationCard;
