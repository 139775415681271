import { FormControl, Modal, ModalDialog } from "@mui/joy";
import Button from "@mui/joy/Button/Button";
import Typography from "@mui/joy/Typography";
import React from "react";
import { styles } from "../../config/index";
import { useTranslation } from "react-i18next";
import { ErrorTypes } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const ErrorModal = ({ modalType }) => {
  const { t } = useTranslation("EventPage");
  const navigate = useNavigate();

  const modalData = {
    [ErrorTypes.EventLimit]: {
      description: t("modalDescriptionOverLimit"),
    },
    [ErrorTypes.EventOverUser]: {
      description: t("modalDescriptionEventOver"),
    },
    [ErrorTypes.EventOver]: {
      description: t("modalDescriptionEventExpired"),
    },
    [ErrorTypes.EventBroken]: {
      description: t("modalDescriptionEventBroken"),
    },
  };

  const openMainPage = () => {
    switch (modalType) {
      case ErrorTypes.EventBroken:
        navigate(0);
        break;

      default:
        window.location.href = `https://cloudofmemories.${
          import.meta.env.VITE_REGION
        }`;
        break;
    }
  };

  return (
    <Modal
      aria-labelledby="modal-over-limit"
      aria-describedby="modal-people-over-limit"
      open={modalType !== ""}
      sx={{ zIndex: "99999999" }}
    >
      <>
        {modalType !== "" && (
          <ModalDialog sx={{ width: 350, bgcolor: "background.paper" }}>
            <FormControl sx={{ mb: 2 }}>
              <Typography level="h4">{t("modalErrorTitle")}</Typography>
              <Typography
                level="body3"
                textColor={styles.primaryColor}
                textAlign={"left"}
              >
                {modalData[modalType].description}
              </Typography>
            </FormControl>
            <Button variant="contained" onClick={() => openMainPage()}>
              {t("modalErrorButton")}
            </Button>
          </ModalDialog>
        )}
      </>
    </Modal>
  );
};

export default ErrorModal;
