import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import i18n from "./i18n";

import MediaPage from "./pages/MediaPage";
import MessagesPage from "./pages/MessagesPage";
import EventPage from "./pages/EventPage";
import AdminRegister from "./pages/AdminRegister";
import AdminLogin from "./pages/AdminLogin";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import EntryPage from "./pages/EntryPage";
import TryDemoInstant from "./pages/TryDemoInstant";
import SugestionPage from "./pages/SugestionPage";
import CatalogPage from "./pages/CatalogPage";

import { handleVisibilityChange } from "./utils/helpers";

import "./i18n";
import "./App.css";
import "@fontsource/public-sans";
import "./components/common/cardFonts/Montserrat-Bold.ttf";
import "./components/common/cardFonts/Montserrat-Italic.ttf";
import "./components/common/cardFonts/PinyonScript-Regular.ttf";
import AppLayout from "./components/common/AppLayout";

function App() {
  const region = import.meta.env.VITE_REGION;

  const autoRefreshTab = 0;

  useEffect(() => {
    i18n.changeLanguage(region === "com" ? "en" : "ro");

    Promise.all([document.fonts.ready]).then(() => {
      const loader = document.getElementById("preAssetLoader");
      if (loader) loader.style.display = "none";
    });

    if (autoRefreshTab) {
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/media" element={<AppLayout />}>
          <Route index element={<MediaPage />} />
        </Route>
        <Route path="/messages" element={<AppLayout />}>
          <Route index element={<MessagesPage />} />
        </Route>
        <Route path="/event" element={<AppLayout />}>
          <Route index element={<EventPage />} />
        </Route>
        <Route path="/dashboard" element={<AppLayout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="/sugestions" element={<AppLayout />}>
          <Route index element={<SugestionPage />} />
        </Route>

        <Route path="/catalog-page" element={<CatalogPage />} />
        <Route path="/admin-register" element={<AdminRegister />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/demo" element={<TryDemoInstant />} />
        <Route path="/*" element={<EntryPage />} />
      </Routes>
    </>
  );
}

export default App;
