// React Imports
import * as React from "react";

// ** Mui Imports
import Box from "@mui/joy/Box";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

import { useTranslation } from "react-i18next";

import { formatDateStringShort } from "../../utils/helpers";

const ActiveEventCard = ({ event }) => {
  const { t } = useTranslation("EventPage");
  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        width: "100%",
        marginTop: "10px",
        flexWrap: "wrap",
        zIndex: 0,
      }}
    >
      <CardContent>
        <Box>
          <Typography level="h2" fontSize="lg" textAlign="left">
            {t("dashboardCurrentTitle")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "start",
                  width: "100%",
                  fontWeight: "bold",
                  color: "#e7007e",
                }}
                fontSize="md"
                aria-describedby="card-description"
                mb={0}
                level="body2"
              >
                {event.name}
              </Typography>
              <Typography
                sx={{ textAlign: "start", width: "100%" }}
                fontSize="md"
                aria-describedby="card-description"
                mb={2}
                level="body2"
              >
                {formatDateStringShort(event.date)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActiveEventCard;
