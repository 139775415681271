import React from 'react';
import Button from '@mui/joy/Button/Button';

const ShareButton = ({ link, buttonText }) => {
  const shareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          url: link,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported');
      const textarea = document.createElement('textarea');
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
  };

  return (
    <Button      
      sx={{ width: "100%" }}
      variant="solid"
      color="primary"
      size="sm"      
      onClick={shareLink}
    >
      {buttonText}
    </Button>
  );
};

export default ShareButton;
