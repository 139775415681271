import { createSlice } from "@reduxjs/toolkit";

const discountModalDelaySeconds = import.meta.env
  .VITE_DISCOUNT_MODAL_DELAY_SECONDS;

const initialState = {
  discountModal: {
    isDiscountModalViewed: false,
    discountModalDelaySeconds,
    modalShowTimer: 0,
    isTimerRunning: false,
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleDiscountModalViewed: (state, action) => {
      state.discountModal.isDiscountModalViewed = action.payload;
    },
    startDiscountModalTimer: (state) => {
      state.discountModal.isTimerRunning = true;
    },
    pauseDiscountModalTimer: (state) => {
      state.discountModal.isTimerRunning = false;
    },
    resumeDiscountModalTimer: (state) => {
      state.discountModal.isTimerRunning = true;
    },
    resetDiscountModalTimer: (state) => {
      state.discountModal.modalShowTimer = 0;
      state.discountModal.isTimerRunning = false;
    },
    tickDiscountModalTimer: (state) => {
      if (state.discountModal.isTimerRunning) {
        state.discountModal.modalShowTimer += 1;
      }
    },
  },
});

export const {
  toggleDiscountModalViewed,
  toggleDiscountModalDone,
  startDiscountModalTimer,
  pauseDiscountModalTimer,
  resumeDiscountModalTimer,
  resetDiscountModalTimer,
  tickDiscountModalTimer,
} = modalsSlice.actions;
export default modalsSlice.reducer;
