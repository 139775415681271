// ** React Imports:

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// ** Mui Imports
import Button from "@mui/joy/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

// ** Logo Imports
import LogoImgSkeleton from "../assets/logoSkeleton.jpg";
import { images } from "../config/index";

// ** Image Imports
import celebrationImage from "../assets/celebrationQrCard.svg";
import familyImage from "../assets/familyQrCard.svg";

const eventType = import.meta.env.VITE_EVENT_TYPE;
const region = import.meta.env.VITE_REGION;

const eventTypeObject = {
  family: {
    secondaryColor: "rgba(239, 133, 236, 0.2)",
    showCard: true,
    image: familyImage,
  },
  corporate: {
    secondaryColor: "rgba(0.431, 0.396, 0.839, 0.2)",
    showCard: false,
    image: null,
  },
  vacation: {
    secondaryColor: "rgba(0.176, 0.718, 0.718, 0.2)",
    showCard: false,
    image: null,
  },
  celebration: {
    secondaryColor: "rgba(126, 76, 233)",
    showCard: true,
    image: null,
  },
};

const EntryPage = () => {
  const { t } = useTranslation("EntryPage");
  const navigate = useNavigate();

  function redirectToExternalSite() {
    window.location.href = "https://cloudofmemories.ro/";
  }

  function redirectToLogin() {
    navigate("/admin-login");
  }

  function redirectToExternalRegionSite() {
    window.location.href = `https://cloudofmemories.${region}/${eventType}`;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      maxWidth="637px !important"
      marginLeft={"auto"}
      marginRight={"auto"}
      marginTop={"10px"}
      marginBottom={"10px"}
      style={{ minHeight: "100vh", overflowX: "hidden" }}
    >
      <Container
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowX: "hidden",
        }}
      >
        <Box>
          <LazyLoadImage
            src={images.logo}
            placeholderSrc={LogoImgSkeleton}
            width={150}
            effect="blur"
            alt="logo"
            style={{ marginBottom: "auto", cursor: "pointer" }}
            onClick={redirectToExternalRegionSite}
          />
        </Box>
        <Box>
          <Card
            sx={{
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#FAFAFA",
              marginTop: "auto",
              marginBottom: "14px",
              boxShadow: "none",
            }}
          >
            <Card
              sx={{
                display: "inline-block",
                padding: "8px 12px",
                borderRadius: "8px",
                backgroundColor: eventTypeObject[eventType]["secondaryColor"],
                boxShadow: "none",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "Raleway",
                  fontWeight: "bold",
                  lineHeight: "24px",
                  color: "#000000",
                }}
              >
                {t("organizerQuestion")}
              </Typography>
            </Card>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "16px",
                marginTop: "8px",
                marginBottom: "4px",
                fontFamily: "Raleway",
              }}
            >
              {t("accesAdministration")}
            </Typography>
            <Button
              sx={{
                color: "FAFAFA",
                fontWeight: "bold",
                fontSize: "16px",
                fontFamily: "Raleway",
                lineHeight: "18px",
                borderRadius: "4px !important",
                padding: "8px 22px !important",
                marginTop: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;",
              }}
              onClick={redirectToLogin}
            >
              {t("administrationPage")}
            </Button>
          </Card>

          <Card
            sx={{
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#FAFAFA",
              marginBottom: "14px",
              boxShadow: "none",
            }}
          >
            <Card
              sx={{
                display: "inline-block",
                padding: "8px 12px",
                borderRadius: "8px",
                backgroundColor: eventTypeObject[eventType]["secondaryColor"],
                boxShadow: "none",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "Raleway",
                  fontWeight: "bold",
                  lineHeight: "24px",
                  color: "#000000",
                }}
              >
                {t("invited")}
              </Typography>
            </Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8px",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Raleway",
                  //lineHeight: "16px",
                  marginRight: "4px",
                  lineHeight: "22px",
                }}
              >
                {t("scanQRfirst")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Raleway",
                  //lineHeight: "16px",
                  fontWeight: "bold",
                  marginRight: "4px",
                  lineHeight: "22px",
                }}
              >
                {t("scanQRsecond")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Raleway",
                  marginRight: "4px",
                  lineHeight: "22px",
                }}
              >
                {t("scanQRthird")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: "16px",
                  //lineHeight: "16px",
                  marginRight: "4px",
                  lineHeight: "22px",
                }}
              >
                {t("scanQRfourth")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Raleway",

                  fontSize: "16px",
                  //lineHeight: "16px",
                  marginRight: "4px",
                  fontWeight: "bold",
                  lineHeight: "22px",
                }}
              >
                {t("scanQRfifth")}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Raleway",

                  fontSize: "16px",
                  //lineHeight: "16px",
                  lineHeight: "22px",
                }}
              >
                {t("scanQRsixth")}
              </Typography>
            </Box>
          </Card>

          <Card
            sx={{
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#FAFAFA",
              marginBottom: "14px",
              boxShadow: "none",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: "28px",
                fontFamily: "Raleway",
              }}
            >
              {t("knowMoreFirst")}
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: "28px",
                fontFamily: "Raleway",
              }}
            >
              {t("knowMoreSecond")}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                fontFamily: "Raleway",
                lineHeight: "18px",
                borderRadius: "4px !important",
                padding: "8px 22px !important",
                marginTop: "12px",
              }}
              onClick={redirectToExternalSite}
            >
              {t("siteAcces")}
            </Button>
          </Card>
        </Box>

        <Box sx={{ mb: "10px", py: 3, backgroundColor: "#f8f8f8" }}>
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} CloudOfMemories. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Grid>
  );
};

export default EntryPage;
