export default {
	primaryColor: "#2c76d2",
	infoColor: "#006ac0",
	infoBackgroundColor: "#e1fffb",
	disabledBackgroundColor: "#6765e1",
	trackBackgroundColor: "#6fa8dc",
	autofillBackgroundColor: "#edd0e0",
	autofillBoxShadowColor: "#cdd0ff",
	errorColor: "	#ff3333",
};
