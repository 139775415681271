// React and Hooks Imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

// API and Configuration Imports
import { validateEventById } from "../../../api/eventApi";

// ** Event Page components
import ErrorModal from "../eventPage/ErrorModal";
import useEventBlock from "../../hooks/useEventBlock";
import { ErrorTypes } from "../../utils/helpers";

const EventValidate = () => {
  const [errorType, setErrorType] = useEventBlock();

  const navigate = useNavigate();
  const query = useQuery();

  const validateEvent = async () => {
    const eventId = query.get("event");
    if (!eventId) navigate("/");
    try {
      await validateEventById(eventId);
    } catch (error) {
      console.error(error);
      const message = error.response?.data;
      if (message === "Event expired!") {
        setErrorType(ErrorTypes.EventOver);
      }
    }
  };

  useEffect(() => {
    validateEvent();
  }, []);

  return (
    <>
      <ErrorModal modalType={errorType} />
    </>
  );
};

export default EventValidate;
