// ** React Imports
import React, { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/joy/Box";
import Alert from "@mui/joy/Alert";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ReportIcon from "@mui/icons-material/Report";

// ** Hooks Imports
import useAlerts from "../hooks/mediaPage/useAlerts";

const MUIAlert = () => {
  const { alertMessage, setAlertMessage, showAlertTime } = useAlerts();

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, showAlertTime || 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlertTime, setAlertMessage]);

  if (!alertMessage) return null;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        width: "100%",
        flexDirection: "column",
        position: "fixed",
        top: 20,
        left: 0,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Alert
          sx={{ alignItems: "flex-start", width: "92%" }}
          startDecorator={React.cloneElement(<ReportIcon />, {
            sx: { mt: "2px", mx: "4px", width: "20px", height: "20px" },
            fontSize: "15px",
          })}
          variant="soft"
          color={"danger"}
          endDecorator={
            <IconButton
              className="formButton"
              variant="soft"
              size="sm"
              color={"danger"}
              onClick={() => setAlertMessage("")}
            >
              <CloseRoundedIcon />
            </IconButton>
          }
        >
          <div style={{ margin: "auto" }}>
            <Typography fontWeight="lg" mt={0.25}>
              {"Eroare"}
            </Typography>
            <Typography fontSize="sm" sx={{ opacity: 0.8 }}>
              {alertMessage}
            </Typography>
          </div>
        </Alert>
      </Box>
    </Box>
  );
};

export default MUIAlert;
