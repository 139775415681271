import { Button, Box } from "@mui/joy";

import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import { useTranslation } from "react-i18next";

const PrintGalleryButton = ({ openGallery }) => {
  const { t } = useTranslation("MediaPage");

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          left: "30px",
          bottom: "100px",
          display: "flex",
          flexDirection: "column-reverse",
          zIndex: 800,
        }}
      >
        <Button
          variant="solid"
          color="primary"
          onClick={openGallery}
          startDecorator={<PrintRoundedIcon />}
          tabIndex={-1}
        >
          {t("printButton")}
        </Button>
      </Box>
    </>
  );
};

export default PrintGalleryButton;
