export default {
  userNavigation: {
    firstOption: "Album",
    secondOption: "Mesaje",
    thirdOption: "Detalii",
    forthOption: "Călătorii",
    fifthOption: "Sugestii",
  },
  messages: {
    singleTypeOfMessage: "mesaj",
    addMessage: "Scrie un mesaj",
    multipleTypesOfMessage: "mesaje",
    genderTypeOfMessage: "male",
  },
  tutorialModal: {
    step1title: "Distribuie codul QR",
    step2title: "Album și Mesaje",
    step3title: "Bucură-te de moment!",
    step1text: "Distribuie link-ul călătoriei către partenerii de călătorie.",
    step2text:
      "Întregul grup încarcă poze, clipuri și mesaje în timpul călătoriei.",
    step3text:
      "Elementele încărcate de voi apar direct în albumul digital din Cloud of Memories, de unde le veți putea descărca.",
  },
  tutorialGuestsModal: {
    step1title: "Bine ai venit",
    step2title: "Surpinde și încarcă momente speciale",
    step1text:
      "Împarte momentele speciale surprinse de tine cu partenerii de călătorie.",
    step2text:
      "Mâncăruri inedite🍽️, Poză de grup📸, Poze de cuplu📷💑, Cel mai tare party💃🕺, Un moment special🎂, Peisaje magnifice🏖️, Un selfie🤳👩‍❤️‍💋‍👨",
  },
  sugestionsGuests: {
    sugestion1title: "Mâncăruri inedite🍽️",
    sugestion2title: "Poze de cuplu📷💑",
    sugestion3title: "Cel mai tare party💃🕺",
    sugestion4title: "Un moment special🎂",
    sugestion5title: "Peisaje magnifice🏖️",
    sugestion6title: "Poză de grup📸",
    sugestion7title: "Un selfie🤳👩‍❤️‍💋‍👨",
    sugestion1subTitle:
      "Până și vacanța trece prin stomac! Capturați momentele culinare inedite din vacanță!",

    sugestion2subTitle:
      "Surprinde momentele de bucurie trăite împreună cu jumătatea ta!",

    sugestion3subTitle:
      "Petrecerile din călătorie sunt cele mai tari! Încarcă aici momente pline de bucurie!",

    sugestion4subTitle:
      "Capturați clipele magice! Transformați orice moment într-o amintire veșnică!",

    sugestion5subTitle:
      "Încadrați splendoarea naturii în fiecare fotografie! Adaugă fotografii cu cele mai tari peisaje!",
    sugestion6subTitle:
      "Gașca ta e cea mai tare! Faceți o poză de grup!",
    sugestion7subTitle:
      "Zâmbește la cameră! E momentul să faci un selfie!",
  },
  demoMessages: [
    {
      text: "Haideți să ne bucurăm de fiecare moment! Nu ratăm nimic, profităm de fiecare ocazie de a ne distra!",
      user: { displayName: "Daria Sturza" },
    },
    {
      text: "Închin pentru o vacanță plină de aventură și momente de neuitat!",
      user: { displayName: "lonut Dumitrescu" },
    },
    {
      text: "Câte momente frumoase! Mă bucur foarte mult că am ales să vin cu acest grup minunat în concediu!😊",
      user: { displayName: "Valentina Elena Negru" },
    },
    {
      text: "Abia aștept să fac o grămadă de poze la toate locurile pe care o să le vizităm!📷",
      user: { displayName: "Radu Toma" },
    },
  ],
  registerForm: {
    titluCardPlaceholder: "Ex.: Mallorca 2024 🏖️",
    textCardPlaceholder:
      "Ex.: Scanează și încarcă fotografiile și clipurile pe care le faci în această călătorie.",
  },
};
