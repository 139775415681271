// React Imports
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// ** Mui Imports
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

// ** Third party library imports
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";

// ** Utils imports
import { getQRCode } from "../../utils";

// ** Components imports
import ShareButton from "../ShareButton";
import EventCard from "./EventCard";

// ** Hooks imports
import DownloadCard from "../common/DownloadCard";

const eventType = import.meta.env.VITE_EVENT_TYPE;

const EventData = ({ event, isAdmin }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("EventPage");
  const navigate = useNavigate();

  const copyLinkCardData = {
    title: t("eventLink"),
    subTitle: t("eventLinkInfo"),
  };

  const catalogCardData = {
    title: t("catalogPage"),
    subTitle: t("catalogPageDescription"),
  };

  function downloadImage(url, fileName) {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      {(isAdmin || eventType === "vacation" || eventType === "celebration") && (
        <>
          <DownloadCard event={event} />
          {isAdmin && (
            <>
              {!event?.isDemo && (
                <EventCard cardData={catalogCardData}>
                  <Button
                    sx={{ width: "100%", marginTop: "10px" }}
                    variant="solid"
                    color="primary"
                    size="sm"
                    onClick={() => navigate(`/catalog-page?event=${event._id}`)}
                  >
                    {t("catalog")}
                  </Button>
                </EventCard>
              )}
              <Card
                variant="outlined"
                orientation="horizontal"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  flexWrap: "wrap",
                  zIndex: 0,
                }}
              >
                <CardContent>
                  <Box>
                    <Typography level="h2" fontSize="lg" textAlign={"left"}>
                      {t("qrCode")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      minHeight: "150px",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <AspectRatio
                        ratio="1"
                        sx={{
                          width: "100%",
                          maxWidth: "105px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <img
                          src={getQRCode(
                            import.meta.env.VITE_BACKEND_URL +
                              import.meta.env.VITE_REGION +
                              `/event?event=${event._id}&pin=${event.pin}`
                          )}
                          loading="lazy"
                          alt="qrcode image"
                          role="button"
                        />
                      </AspectRatio>

                      <Typography
                        fontSize="sm"
                        aria-describedby="card-description"
                        mb={1}
                        textAlign={"left"}
                        level="body2"
                      >
                        {t("qrCodeInfo")}
                      </Typography>
                    </Box>
                    <Button
                      sx={{ width: "100%" }}
                      variant="solid"
                      color="primary"
                      size="sm"
                      onClick={() =>
                        downloadImage(
                          getQRCode(
                            import.meta.env.VITE_BACKEND_URL +
                              import.meta.env.VITE_REGION +
                              `/event?event=${event._id}&pin=${event.pin}`
                          ),
                          "QR_Code"
                        )
                      }
                    >
                      {t("downloadQRForGuests")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>

              <EventCard cardData={copyLinkCardData}>
                <ShareButton
                  buttonText={t("sendLinkToGuests")}
                  link={
                    import.meta.env.VITE_BACKEND_URL +
                    import.meta.env.VITE_REGION +
                    `/event?event=${event._id}&pin=${event.pin}`
                  }
                />
              </EventCard>
            </>
          )}
        </>
      )}

      {event?.isDemo && isAdmin && (
        <Button
          sx={{ width: "100%", marginTop: "10px", marginBottom: "-10px" }}
          variant="solid"
          color="primary"
          size="sm"
          onClick={() =>
            (window.location.href =
              "https://cloudofmemories." + import.meta.env.VITE_REGION)
          }
        >
          {t("goBackToHome")}
        </Button>
      )}

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          {
            src: getQRCode(
              import.meta.env.VITE_BACKEND_URL +
                import.meta.env.VITE_REGION +
                `/event?event=${event._id}&pin=${event.pin}`
            ),
          },
        ]}
        plugins={[Download]}
      />
    </div>
  );
};

export default EventData;
