import Typography from "@mui/joy/Typography/Typography";
import LogoImgSkeleton from "../assets/logoSkeleton.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Box, Button } from "@mui/joy";
import { images } from "../config/index";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";
import { getLocalStorageUser } from "../utils/helpers";

const eventType = import.meta.env.VITE_EVENT_TYPE;
const region = import.meta.env.VITE_REGION;

const PageHeader = ({
  title,
  body,
  alignImg,
  alignText,
  newEventButton = false,
  handleEventButton = () => {},
}) => {
  const { t: td } = useTranslation("Dashboard");

  const userData = getLocalStorageUser();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: alignImg === "right" ? "flex-end" : "flex-start",
        }}
      >
        <LazyLoadImage
          src={images.logo}
          placeholderSrc={LogoImgSkeleton}
          width={150}
          effect="blur"
          alt="logo"
          style={{ marginBottom: "10px", cursor: "pointer" }}
          onClick={() => {
            window.location.href = `https://cloudofmemories.${region}/${eventType}`;
          }}
        />
        {userData?._id === "64b7ca4d8565dfcd3d2cdeca" && (
          <Button
            variant={"outlined"}
            sx={{
              position: "absolute",
              right: 0,
              top: 6,
            }}
            color="primary"
            size="sm"
            onClick={() => {
              window.location.href = `https://cloudofmemories.${region}/${eventType}/#first-pack`;
            }}
          >
            <Typography level="body2" sx={{ color: "var(--primary-color)" }}>
              {td("demoOfferText")}
            </Typography>
          </Button>
        )}
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          level="h4"
          sx={{ textAlign: alignText ? alignText : "left" }}
        >
          {title}
        </Typography>
        {newEventButton && (
          <Button
            variant={"outlined"}
            size="sm"
            onClick={handleEventButton}
            sx={{ paddingLeft: "2px", paddingRight: "6px", paddingY: "2px" }}
          >
            <AddOutlinedIcon
              sx={{ color: "var(--primary-color)", fontSize: "1.25rem" }}
            />
            <Typography level="body2" sx={{ color: "var(--primary-color)" }}>
              {td("addEventButton")}
            </Typography>
          </Button>
        )}
      </Box>
      <Typography
        level="body2"
        sx={{ textAlign: alignText ? alignText : "left" }}
      >
        {body}
      </Typography>
    </Box>
  );
};

export default PageHeader;
