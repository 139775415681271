import { Button, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import "cropperjs/dist/cropper.css";
import { Cropper } from "react-cropper";
import { getLocalStorageToken } from "../../utils/helpers";
import useDiscountModalTimer from "../../hooks/useDiscountModalTimer";

const ChangeEventImageModal = ({
  modalEventImageOpen,
  setModalEventImageOpen,
  eventImage,
  eventData,
}) => {
  const cropperRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const { pauseTimer, resumeTimer } = useDiscountModalTimer();

  const handleCropImage = async () => {
    setIsLoaded(false);

    if (cropperRef.current) {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const formData = new FormData();
        const token = getLocalStorageToken();
        formData.append("image", blob, "image.png");

        try {
          await axios.put(
            `${
              import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION
            }/api/events/image/${eventData._id}`,
            formData,
            token && { headers: { Authorization: token } }
          );

          setIsLoaded(true);
          window.location.reload();
        } catch (error) {
          console.error(error);
        }
      }, "image/png");
    }
  };

  useEffect(() => {
    modalEventImageOpen && pauseTimer();

    return () => resumeTimer();
  }, [modalEventImageOpen]);

  return (
    <Modal
      aria-labelledby="modal-event-image"
      aria-describedby="modal-event-image-change"
      onClose={() => setModalEventImageOpen(false)}
      open={modalEventImageOpen}
    >
      <ModalDialog sx={{ width: 350, bgcolor: "background.paper" }}>
        <ModalClose
          sx={{
            width: 20,
            height: 20,
            color: "black!important",
            zIndex: 9999,
          }}
        />

        <Typography level="h4">Ajustează imaginea!</Typography>
        <Box sx={{ borderRadius: "8px", overflow: "hidden" }}>
          <Cropper
            ref={cropperRef}
            style={{
              height: 160,
              width: "100%",
            }}
            zoomTo={0}
            zoomable={false}
            aspectRatio={2}
            preview=".img-preview"
            cropBoxResizable={true}
            src={eventImage}
            viewMode={1}
            autoCropArea={2}
            responsive={true}
            guides={false}
          />
        </Box>
        <Typography sx={{ marginTop: "10px" }} level="h6">
          Previzualizare
        </Typography>

        <Box
          sx={{
            borderRadius: "8px",
            overflow: "hidden",
            width: "100%",
            float: "right",
            display: "inline-block",
            boxSizing: "border-box",
          }}
        >
          <div
            className="img-preview"
            style={{
              width: "100%",
              float: "left",
              height: "160px",
              overflow: "hidden",
            }}
          />
        </Box>
        <Button
          variant="contained"
          disabled={!isLoaded}
          sx={{ marginTop: "10px" }}
          onClick={handleCropImage}
        >
          Schimbă imaginea
        </Button>
      </ModalDialog>
    </Modal>
  );
};

export default ChangeEventImageModal;
