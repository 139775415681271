// ** Styles Imports

import { styles } from "../../config";

export const cardStyle = {
  width: "100%",
  bgcolor: "background.body",
  alignItems: "flex-start",
  marginTop: "10px",
  marginBottom: "10px",
};

export const cardContentStyle = {
  width: "100%",
  bgcolor: "background.body",
  alignItems: "flex-start",
  zIndex: 0,
};

export const contentContainer = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};

export const titleStyle = {
  "--joy-palette-primary-plainColor": styles.primaryColor,
  textAlign: "left",
};

export const subTitleStyle = { textAlign: "left" };

export const iconAndMessageContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "15px",
  minWidth: "100px",
};

export const iconContainer = {
  backgroundColor: "#F4F1F2",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  height: "35px",
  width: "35px",
};
