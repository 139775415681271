// ** React Imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ** Mui Imports
import Email from "@mui/icons-material/Email";
import Key from "@mui/icons-material/Key";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { Box } from "@mui/joy";

// ** Configs
import { styles } from "../config";

// ** Api Imports
import { adminLogin } from "../../api/userApi";

export default function AdminLoginForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("AdminLogin");
  const { t: te } = useTranslation("Errors");

  const [accountData, setAccountData] = useState({
    email: "",
    password: "",
    displayName: "",
  });
  const [status, setStatus] = useState("initial");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    try {
      const adminLoginPayload = {
        password: accountData.password,
        email: accountData.email,
      };

      const { user: userData } = await adminLogin(adminLoginPayload);

      setStatus("sent");
      navigate("/event?event=" + userData.event._id, { replace: true });
    } catch (error) {
      console.error(error);
      if (error.response.status === 404) {
        setStatus("failureLogin");
      } else {
        setStatus("failure");
      }
    }
  };

  useEffect(() => {
    const adminEmail = location.state?.adminEmail;

    if (adminEmail) {
      setAccountData((prev) => ({ ...prev, email: adminEmail }));
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        marginTop: "10px",
      }}
    >
      <form id="details" style={{ width: "100%" }} onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>{t("emailInputLabel")} *</FormLabel>
          <Input
            sx={{ width: "100%", marginBottom: "10px" }}
            placeholder={t("emailInputPlaceholder")}
            type="email"
            required
            variant="plain"
            startDecorator={<Email />}
            value={accountData.email}
            onChange={(event) => {
              const email = event.target.value;
              setAccountData((prevData) => ({
                ...prevData,
                email,
              }));
            }}
            size="md"
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("passwordInputLabel")} *</FormLabel>
          <Input
            sx={{ width: "100%", marginBottom: "10px" }}
            placeholder={t("passwordInputPlaceholder")}
            type="password"
            required
            variant="plain"
            startDecorator={<Key />}
            value={accountData.password}
            onChange={(event) => {
              const password = event.target.value;
              setAccountData((prevData) => ({
                ...prevData,
                password,
              }));
            }}
            size="md"
          />
        </FormControl>
        {status === "failure" && (
          <FormHelperText sx={styles.errorColor}>
            {te("genericError")}
          </FormHelperText>
        )}

        {status === "failureLogin" && (
          <FormHelperText sx={{ color: styles.errorColor + " !important" }}>
            {te("emailOrPasswordMismatch")}
          </FormHelperText>
        )}

        <Button
          sx={{ width: "100%", marginTop: "20px" }}
          variant="solid"
          color="primary"
          size="md"
          loading={status === "loading"}
          type="submit"
        >
          {t("loginButton")}
        </Button>
        <Button
          sx={{ width: "100%", marginTop: "20px", color: "black !important" }}
          variant="plain"
          size="md"
          onClick={() => {
            navigate("/");
          }}
        >
          {t("invitationText")}
        </Button>
        <Button
          sx={{ width: "100%", color: "black !important" }}
          variant="plain"
          size="md"
          onClick={() => {
            navigate("/forgot-password");
          }}
        >
          {t("forgotPasswordText")}
        </Button>
      </form>
    </Box>
  );
}
