// ** React Imports
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// ** MUI Imports
import { Button, Card, CardContent, CardCover, Typography } from "@mui/joy";
import { Box } from "@mui/material";

// ** Config Imports
import { images } from "../../config/index";

const EventHeader = ({
  eventData,
  isUserAdmin,
  setEventImage,
  setModalEventImageOpen,
}) => {
  const eventImageInputRef = useRef(null);

  const { t } = useTranslation("EventPage");

  const handleEventImageChange = async (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setEventImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setModalEventImageOpen(true);
  };

  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const img = new Image();
    img.src = eventData.eventImage
      ? eventData.eventImage
      : images.defaultEventImage;
    img.onload = () => {
      setLoaded(true);
    };
  }, [eventData.eventImage, images.defaultEventImage]);

  const backgroundImage = `url(${
    eventData.eventImage ? eventData.eventImage : images.defaultEventImage
  })`;

  const [textBlurred, setTextBlurred] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTextBlurred(false); // remove the blur after a delay
    }, 250); // 1 second delay, adjust as needed

    return () => clearTimeout(timer); // Clear the timer if the component is unmounted
  }, []);

  return (
    <>
      <Card
        component="li"
        sx={{
          minWidth: 300,
          flexGrow: 1,
          padding: "35px 8px 12px 8px",
          "--Card-radius": isUserAdmin !== null && isUserAdmin && "0px",
          backgroundColor: "#F4F1F2",
          borderImageWidth: 0,
          boxShadow: "none",
          WebkitTransform: "translate3d(0,0,0)",
        }}
      >
        <CardCover
          sx={{
            borderImageWidth: 0,
          }}
          ref={ref}
        >
          <Box
            sx={{
              backgroundImage: backgroundImage,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderImageWidth: 0,

              height: "100%",
              width: "100%",
              backgroundColor: "#F4F1F2",
              opacity: loaded ? 1 : 0.25,
              filter: loaded ? "none" : "blur(10px)",
              transition: "opacity 0.5s ease-in-out, filter 0.35s ease-in-out",
              borderTopLeftRadius:
                isUserAdmin !== null && isUserAdmin && "12px!important",
              borderTopRightRadius:
                isUserAdmin !== null && isUserAdmin && "12px!important",
            }}
          />
        </CardCover>

        <CardContent
          sx={{
            zIndex: 5,
            height: 100,
          }}
        >
          <Typography
            level="h5"
            fontWeight="lg"
            textColor="#fff"
            mt={{ xs: 12, sm: 18 }}
            sx={{
              textShadow: "rgba(0,0,0,0.8) 2px 2px 2px",
              filter: textBlurred ? "blur(4px)" : "none",
              transition: "filter 0.25s ease-in-out",
              zIndex: 5,
            }}
          >
            {eventData.name}
          </Typography>
        </CardContent>
      </Card>
      {isUserAdmin !== null && isUserAdmin && (
        <Card
          sx={{
            "--Card-radius": "0px",
            borderBottomLeftRadius: "12px!important",
            borderBottomRightRadius: "12px!important",
            padding: "0px",
          }}
        >
          <input
            ref={eventImageInputRef}
            type="file"
            accept="image/jpeg, image/png, image/gif"
            onChange={handleEventImageChange}
            style={{ display: "none" }}
          />
          <Button
            variant={"solid"}
            onClick={() => eventImageInputRef.current.click()}
            sx={{
              borderBottomLeftRadius: "12px!important",
              borderBottomRightRadius: "12px!important",
              borderTopLeftRadius: "0px!important",
              borderTopRightRadius: "0px!important",
            }}
          >
            {t("changePicture")}
          </Button>
        </Card>
      )}
    </>
  );
};

export default EventHeader;
