// ** React imports
import React from "react";

// ** Router Imports

// MUI Icons Imports
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

// MUI  Imports
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

// ** Styles Imports
import {
  cardContentStyle,
  cardStyle,
  contentContainer,
  iconAndMessageContainerStyle,
  iconContainer,
  subTitleStyle,
  titleStyle,
} from "./card.styles";
import { formatDateStringShort } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const EventCard = ({ event, handleRedirect }) => {
  const { t } = useTranslation("EventPage");

  return (
    <Card
      orientation="horizontal"
      variant="outlined"
      sx={cardStyle}
      onClick={() => {
        handleRedirect(event._id);
      }}
    >
      <CardContent sx={cardContentStyle}>
        <Box sx={contentContainer}>
          <Box>
            <Typography
              fontWeight="md"
              textColor="primary.plainColor"
              sx={titleStyle}
              style={{ textDecoration: event.expired ? "line-through" : "" }}
            >
              {event.name}
            </Typography>
            <Typography level="body2" sx={subTitleStyle}>
              {event.expired
                ? t("dashboardExpired")
                : formatDateStringShort(event.date)}
            </Typography>
          </Box>

          {!event.expired && (
            <Box
              style={{
                ...iconAndMessageContainerStyle,
              }}
            >
              <Box style={iconContainer}>
                <ArrowForwardRoundedIcon style={{ color: "#E7007E" }} />
              </Box>
              <Typography fontWeight={"700"}>{t("dashboardButon")}</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default EventCard;
