// ** React Imports
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// ** Hooks imports
import useQuery from "../hooks/useQuery";

// ** Third party libraries
import axios from "axios";

// ** Helpers Imports
import { getQRCode } from "../utils";

// ** Images imports
import Logo from "../assets/logo.png";

const backEndUrl = import.meta.env.VITE_BACKEND_URL;
const region = import.meta.env.VITE_REGION;

const defaultQrCode = getQRCode(`${backEndUrl}${region}/demo`);

const useCatalog = () => {
  const { t } = useTranslation("CatalogPage");

  const defaultGroomsName = t("defaultGroomsName");
  const defaultDescription = t("defaultCardText");

  const [catalogData, setCatalogData] = useState({
    groomsName: defaultGroomsName,
    description: defaultDescription,
    logo: Logo,
    qrCodeImage: defaultQrCode,
  });
  const [isEventDemo, setIsEventDemo] = useState(true);
  const [refetchData, setRefetchData] = useState(true);

  const refetchCatalogData = () => setRefetchData((prev) => !prev);

  const query = useQuery();

  const getCatalogData = async () => {
    const eventId = query.get("event");

    if (eventId) {
      const apiUrl = `${backEndUrl}${region}/api/events/${eventId}`;
      const qrCodeImg = getQRCode(
        `${backEndUrl}${region}/event?event=${eventId}`
      );

      axios
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          let { cardTitle, cardText, isDemo } = data;

          setIsEventDemo(isDemo);

          cardTitle = cardTitle || data.name;
          cardText = cardText || defaultDescription;

          const cardData = {
            groomsName: cardTitle,
            description: cardText,
            logo: Logo,
            qrCodeImage: qrCodeImg,
          };

          setCatalogData(cardData);
        })
        .catch((err) => {
          console.log("THE ERROR IS:", err);
        });
    } else {
    }
  };

  useEffect(() => {
    getCatalogData();
  }, [refetchData]);

  return { catalogData, isEventDemo, refetchCatalogData };
};

export default useCatalog;
