import * as React from "react";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { styles } from "../config/index";

export default function WishCard({ message }) {
	function getFormattedTimeFromDate(dateString) {
		const date = new Date(dateString);
		date.setHours(date.getHours() + 2);
		const options = {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		};
		const formattedTime = date.toLocaleTimeString(undefined, options);

		return formattedTime;
	}
	const renderMessageText = () => {
		return message.text.split("\n").map((line, index) => (
			<React.Fragment key={index}>
				{line}
				<br />
			</React.Fragment>
		));
	};

  const displayName = message.user.displayName.replace(/\d+$/, '');

	return (
		<Card
			orientation="horizontal"
			variant="outlined"
			sx={{
				width: "100%",
				bgcolor: "background.body",
				alignItems: "flex-start",
				marginTop: "5px",
			}}>
			<CardContent
				sx={{
					width: "100%",
					bgcolor: "background.body",
					alignItems: "flex-start",
					zIndex: 0,
				}}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}>
					<Typography
						fontWeight="md"
						textColor="primary.plainColor"
						style={{ textAlign: "start" }}
						sx={{ "--joy-palette-primary-plainColor": styles.primaryColor }}>
						{displayName}
					</Typography>
					<Typography fontWeight="md" textColor="gray">
						{/* {getFormattedTimeFromDate(message.createdAt)} */}
					</Typography>
				</div>

				<Typography level="body2" style={{ textAlign: "start" }}>
					{" "}
					{renderMessageText()}
				</Typography>
			</CardContent>
		</Card>
	);
}
