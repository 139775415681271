import React from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TutorialModal from "../mediaPage/TutorialModal";
import { getTutorialSteps } from "../../utils/helpers";
import { useEvent } from "../../hooks/useEvent";
import { useUserData } from "../../hooks/useUserData";
import EventValidate from "./EventValidate";
import NavigationWrapper from "../NavigationWrapper";

const AppLayout = () => {
  const [eventData] = useEvent();
  const [userData, isUserAdmin, , getUser] = useUserData({ eventData });

  return (
    <NavigationWrapper>
      <CssBaseline />
      <Outlet context={[eventData, userData, isUserAdmin, getUser]} />
      {eventData && userData && (
        <TutorialModal
          eventImage={eventData.eventImage}
          steps={getTutorialSteps(eventData)}
        />
      )}
      <EventValidate />
    </NavigationWrapper>
  );
};

export default AppLayout;
