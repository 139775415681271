/**
 * @type {"family" | "corporate"}
 */
const eventType = import.meta.env.VITE_EVENT_TYPE;

/**
 * @type {"ro" | "com"}
 */
const region = import.meta.env.VITE_REGION;

console.debug("Settings color-scheme for eventType:" + eventType + " Enjoy!");
console.debug("Settings texts for region:" + region + " Enjoy!");

import familyStyles from "./stylings/familyStylings";
import corporateStyles from "./stylings/corporateStylings";
import vacationStyles from "./stylings/vacationStylings";
import celebrationStyles from "./stylings/celebrationStylings";

import familyTextsEN from "./texts/EN/familyTexts";
import corporateTextsEN from "./texts/EN/corporateTexts";
import vacationTextsEN from "./texts/EN/vacationTexts";
import familyTextsRO from "./texts/RO/familyTexts";
import corporateTextsRO from "./texts/RO/corporateTexts";
import vacationTextsRO from "./texts/RO/vacationTexts";
import celebrationTextsRO from "./texts/RO/celebrationTexts";

import familyAssets from "./assets/familyAssets";
import corporateAssets from "./assets/corporateAssets";
import vacationAssets from "./assets/vacationAssets";
import celebrationAssets from "./assets/celebrationAssets";

/**
 * @typedef {Object} Styles
 * @property {string} primaryColor
 * @property {string} infoColor
 * @property {string} infoBackgroundColor
 * @property {string} disabledBackgroundColor
 * @property {string} trackBackgroundColor
 * @property {string} autofillBackgroundColor
 * @property {string} autofillBoxShadowColor
 */

/**
 * The styles object contains various properties for styling:
 * Make sure to refer to the specific stylings file for a complete list of available properties.
 * @type {Styles}
 */
let styles;
switch (eventType) {
  case "family":
    styles = familyStyles;
    break;
  case "corporate":
    styles = corporateStyles;
    break;
  case "vacation":
    styles = vacationStyles;
    break;
  case "celebration":
    styles = celebrationStyles;
    break;
  default:
    styles = familyStyles;
}

/**
 * @typedef {Object} Images
 * @property {image} logo
 * @property {image} soloLogo
 * @property {image} tutorialStep1
 * @property {image} tutorialStep2
 * @property {image} tutorialStep3
 * @property {image} defaultEventImage
 */

/**
 * The images object contains various images:
 * Make sure to refer to the specific stylings file for a complete list of available properties (images).
 * @type {Images}
 */
let images;
switch (eventType) {
  case "family":
    images = {
      logo: familyAssets.logoImages.Logo,
      soloLogo: familyAssets.logoImages.SoloLogo,
      tutorialStep1: familyAssets.tutorialStepsImages.TutorialStep1,
      tutorialStep2: familyAssets.tutorialStepsImages.TutorialStep2,
      tutorialStep3: familyAssets.tutorialStepsImages.TutorialStep3,
      defaultEventImage: familyAssets.eventImages.DefaultEventImage,
    };
    break;
  case "corporate":
    images = {
      logo: corporateAssets.logoImages.Logo,
      soloLogo: corporateAssets.logoImages.SoloLogo,
      tutorialStep1: corporateAssets.tutorialStepsImages.TutorialStep1,
      tutorialStep2: corporateAssets.tutorialStepsImages.TutorialStep2,
      tutorialStep3: corporateAssets.tutorialStepsImages.TutorialStep3,
      defaultEventImage: corporateAssets.eventImages.DefaultEventImage,
    };
    break;
  case "vacation":
    images = {
      logo: vacationAssets.logoImages.Logo,
      soloLogo: vacationAssets.logoImages.SoloLogo,
      tutorialStep1: vacationAssets.tutorialStepsImages.TutorialStep1,
      tutorialStep2: vacationAssets.tutorialStepsImages.TutorialStep2,
      tutorialStep3: vacationAssets.tutorialStepsImages.TutorialStep3,
      defaultEventImage: vacationAssets.eventImages.DefaultEventImage,
    };
    break;
  case "celebration":
    images = {
      logo: celebrationAssets.logoImages.Logo,
      soloLogo: celebrationAssets.logoImages.SoloLogo,
      tutorialStep1: celebrationAssets.tutorialStepsImages.TutorialStep1,
      tutorialStep2: celebrationAssets.tutorialStepsImages.TutorialStep2,
      tutorialStep3: celebrationAssets.tutorialStepsImages.TutorialStep3,
      defaultEventImage: celebrationAssets.eventImages.DefaultEventImage,
    };
    break;
  default:
    images = {
      logo: familyAssets.logoImages.Logo,
      soloLogo: familyAssets.logoImages.SoloLogo,
      tutorialStep1: familyAssets.tutorialStepsImages.TutorialStep1,
      tutorialStep2: familyAssets.tutorialStepsImages.TutorialStep2,
      tutorialStep3: familyAssets.tutorialStepsImages.TutorialStep3,
      defaultEventImage: familyAssets.eventImages.DefaultEventImage,
    };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

String.prototype.capitalized = function () {
  return capitalizeFirstLetter(this);
};

/**
 * @typedef {Object} userNavigation
 * @property {"Foto"} firstOption
 * @property {"Feedback" | "Urări"} secondOption
 * @property {"Detalii"} thirdOption
 * @property {"Evenimente"} forthOption
 * @property {"Sugestii"} fifthOption
 *
 */

/**
 * @typedef {Object} messages
 * @property {"mesaj" | "urare"} singleTypeOfMessage
 * @property {"feedbackuri" | "urări"} multipleTypesOfMessage
 * @property {"male" | "female"} genderTypeOfMessage
 */

/**
 * @typedef {Object} tutorialModal
 * @property {string} step1title
 * @property {string} step2title
 * @property {string} step3title
 * @property {string} step1text
 * @property {string} step2text
 * @property {string} step3text
 */

/**
 * @typedef {Object} tutorialGuestsModal
 * @property {string} step1title
 * @property {string} step2title
 * @property {string} step1text
 * @property {string} step2text
 */

/**
 * @typedef {Object} sugestionsGuests
 * @property {string} sugestion1title
 * @property {string} sugestion2title
 * @property {string} sugestion3title
 * @property {string} sugestion4title
 * @property {string} sugestion5title
 * @property {string} sugestion6title
 * @property {string} sugestion7title
 *
 */

/**
 * @typedef {Object} Texts
 * @property {userNavigation} userNavigation
 * @property {messages} messages
 * @property {tutorialModal} tutorialModal
 * @property {tutorialGuestsModal} tutorialGuestsModal
 * @property {sugestionsGuests} sugestionsGuests
 */

/**
 * Selects the appropriate texts based on eventType and region.
 * @param {string} eventType - The type of event (family or corporate).
 * @param {string} region - The region code (e.g., "com" for English, "ro" for Romanian).
 * @returns {Texts} The texts object for the specified eventType and region.
 */
function selectTexts(eventType, region) {
  switch (eventType) {
    case "family":
      return region === "com" ? familyTextsEN : familyTextsRO;
    case "corporate":
      return region === "com" ? corporateTextsEN : corporateTextsRO;
    case "vacation":
      return region === "com" ? vacationTextsEN : vacationTextsRO;
      case "celebration":
      return region === "com" ? celebrationTextsRO : celebrationTextsRO;
    default:
      return region === "com" ? familyTextsEN : familyTextsRO;
  }
}

let texts = selectTexts(eventType, region);

export { styles, images, texts, eventType };
