// ** React Imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ** MUI Imports
import Email from "@mui/icons-material/Email";
import Key from "@mui/icons-material/Key";
import Person from "@mui/icons-material/Person";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card/Card";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, Checkbox } from "@mui/joy";
import { Box } from "@mui/joy";

// ** Third party libaries imports
import axios from "axios";
import dayjs from "dayjs";

// ** Api Imports
import {
  getUserUsedEmail,
  createNewAdminAccount,
  subscribeNewsLetter,
  adminLogin,
} from "../../api/userApi";
import { createNewEvent } from "../../api/eventApi";

// ** Config imports
import { styles, texts } from "../config/index";

// ** Component Imports
import InvitationCardForm from "./InvitationCardForm";

// ** Utils Imports
import { ErrorMessages, createEventPayload } from "../utils/helpers";

const eventType = import.meta.env.VITE_EVENT_TYPE;
const eventIsVacation = eventType === "vacation" || eventType === "celebration";

export default function AdminRegisterForm({ paymentData, setIsPaymentUsed }) {
  const [dateBeforeWarn, setDateBeforeWarn] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [checkBoxNewsletter, setCheckBoxNewsletter] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [adminUser, setAdminUser] = useState(null);
  const [accountData, setAccountData] = useState({
    email: paymentData.customerEmail,
    password: "",
    displayName: "",
  });
  const [status, setStatus] = useState("initial");
  const [data, setData] = useState({
    name: "",
    message: "",
    dateEvent: "",
    sharePics: true,
    shareMessage: eventIsVacation,
  });
  const [invitationCardData, setInvitationCardData] = useState({
    cardTitle: "",
    cardText: "",
    checkBox: false,
  });

  const navigate = useNavigate();
  const { t } = useTranslation("AdminRegister");
  const { t: te } = useTranslation("Errors");

  const defaultCardText = t("defaultCardText");

  function generatePin() {
    const pinLength = 6;
    let pin = "";

    for (let i = 0; i < pinLength; i++) {
      // Generate a random number between 0 and 9
      const randomDigit = Math.floor(Math.random() * 10);
      pin += randomDigit.toString();
    }

    return pin;
  }

  // Error handling function
  const handleError = (error) => {
    console.error(error);

    const errorMessage = error.response?.data?.message || "";

    if (errorMessage === ErrorMessages["PaymentUsed"]) {
      setIsPaymentUsed(true);
    } else if (errorMessage === ErrorMessages["UserNotFound"]) {
      console.error("User could not be created");
    } else {
      setStatus("failure");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkBox) {
      setCheckBoxError(true);
      return;
    }

    setStatus("loading");

    try {
      const newsLetterPayload = {
        newsletter: checkBoxNewsletter,
      };

      if (adminUser?.user) {
        const eventPayload = createEventPayload(
          adminUser.user._id,
          data,
          paymentData,
          invitationCardData
        );

        const newEvent = await createNewEvent(eventPayload, adminUser.token);

        const eventId = newEvent._id;

        await subscribeNewsLetter(eventId, newsLetterPayload);
        navigate("/admin-login", {
          state: { adminEmail: adminUser.user.email },
        });
      } else {
        const newAccountPayload = {
          displayName: accountData.displayName,
          password: accountData.password,
          email: accountData.email,
          ptk: paymentData.uuid,
        };
        const { token, user } = await createNewAdminAccount(newAccountPayload);

        const eventPayload = createEventPayload(
          user._id,
          data,
          paymentData,
          invitationCardData
        );

        const newEvent = await createNewEvent(eventPayload, token);

        const eventId = newEvent._id;

        await subscribeNewsLetter(eventId, newsLetterPayload);

        const adminLoginPayload = {
          password: accountData.password,
          email: accountData.email,
        };

        await adminLogin(adminLoginPayload);

        navigate("/media?event=" + eventId);
      }

      setStatus("sent");
    } catch (error) {
      handleError(error);
    }
  };

  const handleCheckboxChange = () => {
    setCheckBox((prev) => !prev);
  };

  const handleCheckboxChangeNewsLetter = () => {
    setCheckBoxNewsletter((prev) => !prev);
  };

  const handleInvitationCardChange = (field) => (event) => {
    const { type, value, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setInvitationCardData((prev) => ({
      ...prev,
      [field]: fieldValue,
    }));
  };

  useEffect(() => {
    if (checkBox && checkBoxError) {
      setCheckBoxError(false);
    }
  }, [checkBox]);

  useEffect(() => {
    const newCardData = {
      cardTitle: invitationCardData.checkBox ? data.name : "",
      cardText: invitationCardData.checkBox ? defaultCardText : "",
    };

    setInvitationCardData((prev) => ({
      ...prev,
      ...newCardData,
    }));
  }, [invitationCardData.checkBox]);

  useEffect(() => {
    const email = accountData.email;
    const paymentId = paymentData.uuid;
    const checkEmail = async () => {
      try {
        const data = await getUserUsedEmail(email, paymentId);
        setAdminUser(data);
      } catch (e) {
        console.log("error getting user used email", e);
      }
    };
    checkEmail();
  }, [paymentData, accountData]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        marginTop: "10px",
      }}
    >
      <form
        id="details"
        style={{ width: "100%", marginTop: "10px" }}
        onSubmit={handleSubmit}
      >
        <Card variant="outlined">
          <Typography level="h5" sx={{ textAlign: "start" }}>
            {t("accountDetailsTitle")}
          </Typography>

          <FormControl>
            <FormLabel>{t("emailLabel")} *</FormLabel>
            <Input
              sx={{ width: "100%" }}
              type="email"
              required
              disabled
              variant="soft"
              startDecorator={<Email />}
              value={accountData.email}
              onChange={(event) => {
                setAccountData({
                  ...accountData,
                  email: event.target.value,
                });
              }}
              size="md"
            />

            <FormHelperText>
              <Typography
                level="body3"
                sx={{
                  textAlign: "left",
                  color: styles.primaryColor,
                  marginBottom: "10px",
                }}
              >
                {t("emailPlaceholder")}
              </Typography>
            </FormHelperText>
          </FormControl>
          {adminUser?.user ? (
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Typography>{t("emailUsed")}</Typography>
            </Box>
          ) : (
            <>
              <FormControl>
                <FormLabel>{t("passwordLabel")} *</FormLabel>
                <Input
                  sx={{ width: "100%", marginBottom: "10px" }}
                  placeholder={t("passwordPlaceholder")}
                  type="password"
                  required
                  variant="soft"
                  startDecorator={<Key />}
                  value={accountData.password}
                  onChange={(event) => {
                    setAccountData({
                      ...accountData,
                      password: event.target.value,
                    });
                  }}
                  size="md"
                />
              </FormControl>
              <FormControl>
                <FormLabel>{t("fullNameLabel")} *</FormLabel>
                <Input
                  sx={{ width: "100%", marginBottom: "10px" }}
                  placeholder={t("fullNamePlaceholder")}
                  type="text"
                  required
                  variant="soft"
                  startDecorator={<Person />}
                  value={accountData.displayName}
                  onChange={(event) => {
                    setAccountData({
                      ...accountData,
                      displayName: event.target.value,
                    });
                  }}
                  size="md"
                />
              </FormControl>
            </>
          )}
        </Card>

        <Card variant="outlined" sx={{ mt: 2 }}>
          <Typography level="h5" sx={{ textAlign: "start" }}>
            {t("eventDetailsTitle")}
          </Typography>
          <FormControl>
            <FormLabel>{t("eventNameLabel")} *</FormLabel>
            <Input
              sx={{ width: "100%", marginBottom: "10px" }}
              type="text"
              required
              variant="soft"
              slotProps={{
                input: {
                  maxLength: 40,
                },
              }}
              placeholder={t("eventNamePlaceholder")}
              value={data.name}
              onChange={(event) =>
                setData({
                  ...data,
                  name: event.target.value,
                  status: "initial",
                })
              }
              size="md"
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("welcomeMessageLabel")} *</FormLabel>
            <Textarea
              sx={{ width: "100%", marginBottom: "10px" }}
              required
              variant="soft"
              slotProps={{
                input: {
                  maxLength: 50,
                },
              }}
              value={data.message}
              placeholder={t("welcomeMessagePlaceholder")}
              onChange={(event) =>
                setData({
                  ...data,
                  message: event.target.value,
                  status: "initial",
                })
              }
              minRows={3}
              size="md"
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("eventDateLabel")} *</FormLabel>
            <Input
              type="date"
              sx={{ width: "100%", marginBottom: "10px" }}
              required
              variant="soft"
              value={data.dateEvent}
              onChange={(event) => {
                const dateString = event.target.value;
                let newDate = dayjs(dateString);
                let today = dayjs();

                newDate = newDate.startOf("day");
                today = today.startOf("day");

                const todayString = today.format("YYYY-MM-DD");
                newDate.isBefore(today)
                  ? setDateBeforeWarn(true)
                  : setDateBeforeWarn(false);
                newDate.isBefore(today)
                  ? setData({
                      ...data,
                      dateEvent: todayString,
                    })
                  : setData({
                      ...data,
                      dateEvent: dateString,
                    });
              }}
              size="md"
            />
            {dateBeforeWarn && (
              <FormHelperText>
                <Typography level="body2" textColor={styles.primaryColor}>
                  {te("dateNotInPast")}
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
          {!eventIsVacation && (
            <>
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Switch
                    checked={data.sharePics}
                    onChange={(event) => {
                      setData({
                        ...data,
                        sharePics: event.target.checked,
                      });
                    }}
                  />
                }
                label={
                  <Typography level="p">{t("seeAllGalleryLabel")}</Typography>
                }
              />
              <FormHelperText>
                <Typography
                  level="body3"
                  sx={{
                    textAlign: "left",
                    marginTop: "-10px",
                  }}
                >
                  {t("seeAllGalleryDetail")}
                </Typography>
              </FormHelperText>
              <FormControlLabel
                sx={{ width: "100%", marginTop: "10px" }}
                control={
                  <Switch
                    checked={data.shareMessage}
                    onChange={(event) => {
                      setData({
                        ...data,
                        shareMessage: event.target.checked,
                      });
                    }}
                  />
                }
                label={
                  <Typography level="p">
                    {t("seeAllWishesLabel")}{" "}
                    {texts.messages.multipleTypesOfMessage}
                    {import.meta.env.VITE_REGION === "ro" ? "le" : ""}
                  </Typography>
                }
              />
            </>
          )}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Checkbox
              color="danger"
              variant="outlined"
              sx={{
                marginRight: "10px",
                color: "#e7007e",
                "&.Mui-checked": {
                  color: "#e7007e",
                },
                "& .MuiSvgIcon-root": {
                  color: "#e7007e",
                },
              }}
              checked={checkBoxNewsletter}
              onChange={handleCheckboxChangeNewsLetter}
            />
            <Typography sx={{ marginRight: "10px" }}>
              {t("newsletterText")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Checkbox
              color="danger"
              variant="outlined"
              sx={{
                marginRight: "10px",
                color: "#e7007e",
                "&.Mui-checked": {
                  color: "#e7007e",
                },
                "& .MuiSvgIcon-root": {
                  color: "#e7007e",
                },
              }}
              checked={checkBox}
              onChange={handleCheckboxChange}
            />
            <Typography sx={{ marginRight: "10px" }}>
              Sunt de acord cu
            </Typography>
            <Link
              href="https://cloudofmemories.ro/termeni-si-conditii/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                marginRight: "10px",
                color: "#e7007e",
                textDecorationColor: "#e7007e",
              }}
            >
              Termenii și Condițiile
            </Link>
            <Typography sx={{ marginRight: "10px" }}>și cu</Typography>
            <Link
              href="https://cloudofmemories.ro/politica-de-confidentialitate/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#e7007e", textDecorationColor: "#e7007e" }}
            >
              Politica de Confidențialitate
            </Link>
          </Box>
          {checkBoxError && (
            <Typography variant="body2" color="danger" textAlign={"left"}>
              Avem nevoie de acceptul tău pentru a putea continua!
            </Typography>
          )}
        </Card>

        {paymentData?.hasCards && (
          <InvitationCardForm
            invitationCardData={invitationCardData}
            handleInvitationCardChange={handleInvitationCardChange}
          />
        )}

        <Button
          sx={{ width: "100%", margin: "20px 0px" }}
          variant="solid"
          color="primary"
          size="md"
          loading={status === "loading"}
          type="submit"
        >
          {t("createAccountButton")}
        </Button>
        {data.status === "failure" ||
          (status === "failure" && (
            <FormHelperText
              sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
              style={{ justifyContent: "center", marginTop: "5px" }}
            >
              {te("genericError")}
            </FormHelperText>
          ))}
      </form>
    </Box>
  );
}
