import { onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { printingRef } from "../utils/firebaseConfig";

export function usePrinting(eventId) {
  const [printingQueue, setPrintingQueue] = useState([]);

  const initializePrinting = (eventId) => {
    const printQuery = query(
      printingRef,
      where("eventId", "==", eventId),
      where("isPrinted", "==", false)
    );

    const unsubSnapShot = onSnapshot(printQuery, (querySnapshot) => {
      const newPrintingQueue = [];
      querySnapshot.forEach((doc) => {
        const printingImage = doc.data();
        newPrintingQueue.push({ ...printingImage, docId: doc.id });
      });
      const processedPrintingQueue = newPrintingQueue.map((printItem) => {
        const splitIds = printItem.imageLink.split("/");
        const imageId = splitIds[splitIds.length - 1];
        return imageId;
      });
      setPrintingQueue(processedPrintingQueue);
    });
  };

  useEffect(() => {
    initializePrinting(eventId);
  }, [eventId]);

  return [printingQueue];
}
