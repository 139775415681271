import React, { useRef, useEffect } from "react";
import Compressor from "compressorjs";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Romanian from "@uppy/locales/lib/ro_RO";
import English from "@uppy/locales/lib/en_US";
import { useTranslation } from "react-i18next";
import { getLocalStorageUser } from "../../utils/helpers";

import useDiscountModalTimer from "../../hooks/useDiscountModalTimer";

import useAlerts from "../../hooks/mediaPage/useAlerts";

const imageQuality = Number(import.meta.env.VITE_IMAGE_UPLOAD_QUALITY);
const imageMaxWidth = Number(import.meta.env.VITE_IMAGE_UPLOAD_MAX_WIDTH);

const ImageUploader = ({
  eventData,
  handleRefetch,
  imageFileInputRef,
  imageCameraInputRef,
  setLoading,
  setLoadingSingleUpload,
  setShowButtons,
}) => {
  const uppy = useRef();

  const { t: te } = useTranslation("Errors");
  const { pauseTimer, resumeTimer } = useDiscountModalTimer();

  const { setAlertMessage, setShowAlertTime } = useAlerts();

  Romanian.strings.dropHereOr = "%{browseFiles}";
  Romanian.strings.dropHint = "%{browseFiles}";
  Romanian.strings.dropPasteBoth = "%{browseFiles}";
  Romanian.strings.dropPasteFiles = "%{browseFiles}";
  Romanian.strings.browseFiles = "Adaugă Fotografii";

  useEffect(() => {
    configureUppy();
    return () => {
      uppy.current.close();
    };
  }, []);

  const isExtraSupportedFileType = (file) => {
    const supportedTypes = [
      "image/heic",
      "image/dng",
      "image/tif",
      "image/tiff",
    ];
    const supportedExtensions = [".heic", ".dng", ".tif", ".tiff"];

    const fileType = file?.type?.toLowerCase();
    const fileName = file?.name?.toLowerCase();

    return (
      supportedTypes.includes(fileType) ||
      supportedExtensions.some((extension) => fileName.endsWith(extension))
    );
  };

  const configureUppy = () => {
    uppy.current = new Uppy({
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: 100,
      },
      allowedFileTypes: ["image/*"],
      locale: import.meta.env.VITE_REGION === "com" ? English : Romanian,
    });

    uppy.current
      .use(Dashboard, {
        inline: false, // This should ensure that it opens in a modal
        target: "body",
        replaceTargetContent: false,
        showProgressDetails: false,
        note: "Maxim 100 de fotografii!",
        height: "100%",
        metaFields: [{ id: "name", name: "Name", placeholder: "File name" }],
        browserBackButtonClose: false,
        proudlyDisplayPoweredByUppy: false,
        waitForThumbnailsBeforeUpload: true,
      })
      .use(XHRUpload, {
        endpoint:
          import.meta.env.VITE_BACKEND_URL +
          import.meta.env.VITE_REGION +
          "/api/images",
        formData: true,
        fieldName: "files[]",
      });

    uppy.current.on("upload", (data) => {
      setLoading(true);
      const user = getLocalStorageUser();
      uppy.current.setMeta({
        user: user._id,
        event: eventData._id,
      });
    });

    uppy.current.on("dashboard:modal-closed", () => {
      resumeTimer();
      setLoading(false);
    });

    const browseButton = document.querySelector(".uppy-Dashboard-browse");
    if (browseButton) {
      browseButton.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        imageFileInputRef.current.click();
      };
    }

    const processImage = (file) => {
      const unsupportedTypes = [
        "image/x-canon-cr2", // Canon CR2 format
        "image/x-canon-crw", // Canon CRW format
        "image/x-nikon-nef", // Nikon NEF format
        "image/x-sony-arw", // Sony ARW format
        "image/x-pentax-pef", // Pentax PEF format
        "image/x-olympus-orf", // Olympus ORF format
        "image/x-panasonic-raw", // Panasonic RAW format
        "image/x-panasonic-raw2", // Panasonic RAW 2 format
        "image/x-fuji-raf", // Fuji RAF format
        "image/x-samsung-srw", // Samsung SRW format
        "image/x-kodak-kdc", // Kodak KDC format
        "image/x-kodak-dcr", // Kodak DCR format
        "image/x-kodak-k25", // Kodak K25 format
        "image/x-sigma-x3f", // Sigma X3F format
        "image/x-leica-lrw", // Leica LRW format
        "image/x-minolta-mrw", // Minolta MRW format
        "image/x-hasselblad-3fr", // Hasselblad 3FR format
        "image/x-hasselblad-fff", // Hasselblad FFF format
        "image/x-raw", // Generic RAW format
        "image/x-dcraw", // Another generic RAW format
      ];

      // Check if file type is unsupported
      if (
        file &&
        file.type &&
        file.type.length > 0 &&
        unsupportedTypes.includes(file?.type?.toLowerCase())
      ) {
        uppy.current.info(
          `Tipul fișierului "${file.name}" nu este permis.`,
          "error",
          8000
        );
        uppy.current.removeFile(file.id);
        return; // Stop processing this file.
      } else if (isExtraSupportedFileType(file)) {
        uppy.current.setFileState(file.id, {
          data: file.data,
          size: file.data.size,
        });
        return;
      }
      new Compressor(file.data, {
        quality: imageQuality,
        maxWidth: imageMaxWidth,
        mimeType: file?.type || "image/jpeg",
        success: (compressedFile) => {
          uppy.current.setFileState(file.id, {
            data: compressedFile,
            size: compressedFile.size,
          });
        },
        error(err) {
          console.error("Compression error: ", err.message);
        },
      });
    };

    uppy.current.on("file-added", async (file) => {
      try {
        processImage(file);
      } catch (error) {
        console.error("File processing error: ", error.message, error);
      }
    });

    uppy.current.on("upload-error", (file, error, response) => {
      console.error(`Failed to upload ${file.name}. Error: ${error.message}`);
      const message = response.body.message;
      if (message === "Over the limit of space!") {
        setShowAlertTime(7000);
        setAlertMessage(te("photoErrorTooBig"));
        handleRefetch(true);
      } else if (message === "Before event started!") {
        setShowAlertTime(7000);
        setAlertMessage(te("eventNotStartedError"));
      } else if (message === "Already passed 90 days after event!") {
        setShowAlertTime(7000);
        setAlertMessage(te("eventEndedError"));
      } else setAlertMessage(te("genericError"));
      // Stop uploading the rest
      uppy.current.cancelAll();
      setLoading(false);
      setShowButtons(false);
      handleRefetch(true);
      // Close the Uppy Dashboard
      uppy.current.getPlugin("Dashboard").closeModal();
    });

    uppy.current.on("complete", (result) => {
      setLoading(false);
      setShowButtons(false);
      handleRefetch(true);
      if (result.failed.length > 0) {
        result.failed.forEach((erroredUpload) => {
          const message = erroredUpload.response.boody.message;
          if (message === "Over the limit of space!") {
            setShowAlertTime(7000);
            setAlertMessage(te("photoErrorTooBig"));
            handleRefetch(true);
          } else if (message === "Before event started!") {
            setShowAlertTime(7000);
            setAlertMessage(te("eventNotStartedError"));
          } else if (message === "Already passed 90 days after event!") {
            setShowAlertTime(7000);
            setAlertMessage(te("eventEndedError"));
          } else setAlertMessage(te("genericError"));
        });
      } else uppy.current.getPlugin("Dashboard").closeModal();
    });
  };

  const handleSingleImageUpload = async (event) => {
    setLoadingSingleUpload(true);
    const files = Array.from(event.target.files);
    const uploads = files.map((file) => {
      if (isExtraSupportedFileType(file)) {
        return uploadFile(file);
      } else {
        // For non-HEIC files, apply compression
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.8,
            maxWidth: 1920,
            mimeType: "image/jpeg",
            success: (compressedFile) => {
              // After compression, upload the file
              resolve(uploadFile(compressedFile));
            },
            error: (err) => {
              console.error("Compression error: ", err.message);
              reject(err);
            },
          });
        });
      }
    });

    try {
      // Wait for all uploads to complete
      await Promise.all(uploads);
      // Handle post-upload logic here, e.g., hiding loader, showing success message
      setLoadingSingleUpload(false);
      setShowButtons(false);
      handleRefetch(true);
    } catch (error) {
      // Handle any errors here, e.g., showing error messages
      setLoadingSingleUpload(false);
      console.error("Upload error: ", error);
    }
  };

  // Example upload function using Fetch API
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file); // Adjust 'file' depending on your API endpoint
    const user = getLocalStorageUser();
    formData.append("user", user._id);
    formData.append("event", eventData._id);

    const response = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}${
        import.meta.env.VITE_REGION
      }/api/images`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to upload file");
    }

    return response.json(); // Or handle the response as needed
  };

  const handleFileInputChange = (event) => {
    const { target } = event;
    const files = [...target.files];

    uppy.current.cancelAll();
    try {
      files.forEach((file) =>
        uppy.current.addFile({
          name: file.name,
          type: file.type,
          data: file,
          source: "Local",
        })
      );
    } catch (error) {
      console.error(error);
    }
    pauseTimer();
    uppy.current.getPlugin("Dashboard").openModal();
    setTimeout(() => {
      target.value = null;
    }, 0);
  };

  return (
    <>
      <input
        ref={imageCameraInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleSingleImageUpload}
        style={{ display: "none" }}
      />
      <input
        ref={imageFileInputRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
    </>
  );
};

export default ImageUploader;
