// ** React imports
import React, { useEffect, useRef, useState } from "react";

// ** Router Imports
import { useNavigate } from "react-router-dom";

// MUI Icons Imports
import CheckIcon from "@mui/icons-material/Check";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

// MUI  Imports
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

// ** Styles Imports
import {
  cardStyle,
  cardContentStyle,
  titleStyle,
  subTitleStyle,
  iconAndMessageContainerStyle,
  iconContainer,
  contentContainer,
} from "./sugestion.styles";

// ** Common Components
import MediaUploader from "../../../components/common/MediaUploader";

// ** Hooks imports
import useQuery from "../../../hooks/useQuery";
import useAlerts from "../../../hooks/mediaPage/useAlerts";

// **  Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { addFinishedSuggestion } from "../../../reduxState/user/userSlice";

// ** Styles Imports
import { styles } from "../../../config/index";

const Suggestion = (props) => {
  const { title, subTitle, iconClickHandler, name: suggestionName } = props;

  const [loading, setLoading] = useState(false);
  const [succes, setSucces] = useState(false);

  const { setAlertMessage, setShowAlertTime } = useAlerts();

  const query = useQuery();
  const eventId = query.get("event");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const imageCameraInputRef = useRef(null);

  const userFinishedSuggestions = useSelector(
    (state) => state.user.finishedSuggestions
  );

  const isSugestionFinished = () => {
    if (!userFinishedSuggestions[eventId]) return false;
    return userFinishedSuggestions[eventId].includes(suggestionName);
  };

  useEffect(() => {
    if (succes) {
      dispatch(addFinishedSuggestion({ eventId, suggestionName }));
    }
  }, [succes]);

  return (
    <Card orientation="horizontal" variant="outlined" sx={cardStyle}>
      <CardContent sx={cardContentStyle}>
        <Box sx={contentContainer}>
          <Box>
            <Typography
              fontWeight="md"
              textColor="primary.plainColor"
              sx={titleStyle}
            >
              {title}
            </Typography>
            <Typography level="body2" sx={subTitleStyle}>
              {subTitle}
            </Typography>
          </Box>
          <Box
            style={{
              ...iconAndMessageContainerStyle,
              ...(!isSugestionFinished() && { cursor: "pointer" }),
            }}
            onClick={() =>
              !isSugestionFinished() && iconClickHandler(imageCameraInputRef)
            }
          >
            {!isSugestionFinished() ? (
              <>
                <Box style={iconContainer}>
                  <AddAPhotoIcon
                    style={{
                      color: styles.primaryColor,
                    }}
                  />
                </Box>
                <Typography fontWeight={"700"}>Adaugă</Typography>
              </>
            ) : (
              <>
                <Box style={iconContainer}>
                  <CheckIcon style={{ color: "#82C885" }} />
                </Box>
                <Typography fontWeight={"700"}>Mulțumim</Typography>
              </>
            )}
            {!loading && (
              <MediaUploader
                eventId={eventId}
                imageCameraInputRef={imageCameraInputRef}
                setLoading={setLoading}
                setAlertMessage={setAlertMessage}
                setShowAlertTime={setShowAlertTime}
                setSucces={setSucces}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Suggestion;
