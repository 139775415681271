import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import PageHeader from "../components/PageHeader";
import { Divider, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { getDateSortedEvents, updateEventOnUser } from "../../api/eventApi";
import EventCard from "../components/dashboard/EventCard";
import ActiveEventCard from "../components/dashboard/ActiveEventCard";
import { getLocalStorageUser } from "../utils/helpers";

const Dashboard = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const query = useQuery();
  const currentEventId = query.get("event");

  const { t } = useTranslation("Dashboard");

  useEffect(() => {
    const user = getLocalStorageUser();
    user && fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const user = getLocalStorageUser();
      const sortedEvents = await getDateSortedEvents(user._id);
      setEvents(sortedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleNavigate = async (eventId) => {
    try {
      const user = getLocalStorageUser();
      const updatedUser = await updateEventOnUser(eventId, user._id);
      localStorage.setItem("user", JSON.stringify(updatedUser));
      navigate(`/event?event=${eventId}`);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleNewEvent = () => {
    location.href = "https://cloudofmemories." + import.meta.env.VITE_REGION;
  };

  return (
    <Container
      sx={{ height: "calc(100% - 50px)", width: "100%", position: "static" }}
    >
      <PageHeader
        title={t("myEventsTitle")}
        newEventButton={true}
        handleEventButton={handleNewEvent}
      />

      {/* Current Event */}
      {events
        .filter((event) => currentEventId === event._id)
        .map((event) => (
          <>
            <ActiveEventCard key={event._id} event={event} />
          </>
        ))}

      <Divider
        sx={{
          my: 1,
          ":before,:after": {
            height: "3px",
            backgroundColor: "black",
            borderRadius: "3px",
          },
        }}
      >
        <Typography level="body2" sx={{ color: "black", fontWeight: "bold" }}>
          {t("otherEventsHeader")}
        </Typography>
      </Divider>

      {/* Other Events */}
      {events.map((event) =>
        currentEventId !== event._id ? (
          <>
            <EventCard
              key={event._id}
              event={event}
              handleRedirect={handleNavigate}
            />
          </>
        ) : null
      )}

      {/* Spacer */}
      <div style={{ height: "100px" }}></div>
    </Container>
  );
};

export default Dashboard;
