import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// English translations
import AdminLoginEN from "./locales/en/AdminLogin.json";
import AdminRegisterEN from "./locales/en/AdminRegister.json";
import DashboardEN from "./locales/en/Dashboard.json";
import EntryPageEN from "./locales/en/EntryPage.json";
import EventPageEN from "./locales/en/EventPage.json";
import ForgotPasswordEN from "./locales/en/ForgotPassword.json";
import MediaPageEN from "./locales/en/MediaPage.json";
import MessagesPageEN from "./locales/en/MessagesPage.json";
import ResetPasswordEN from "./locales/en/ResetPassword.json";
import TryDemoInstantEN from "./locales/en/TryDemoInstant.json";
import ErrorsEN from "./locales/en/common/Errors.json";
import ButtonsEN from "./locales/en/common/Buttons.json";
import SuggestionsPageEN from "./locales/en/SuggestionsPage.json";
import CatalogPageEN from "./locales/en/CatalogPage.json";
import DownloadGalleryEN from "./locales/en/common/DownloadGallery.json";

// Romanian translations
import AdminLoginRO from "./locales/ro/AdminLogin.json";
import AdminRegisterRO from "./locales/ro/AdminRegister.json";
import DashboardRO from "./locales/ro/Dashboard.json";
import EntryPageRO from "./locales/ro/EntryPage.json";
import EventPageRO from "./locales/ro/EventPage.json";
import ForgotPasswordRO from "./locales/ro/ForgotPassword.json";
import MediaPageRO from "./locales/ro/MediaPage.json";
import MessagesPageRO from "./locales/ro/MessagesPage.json";
import ResetPasswordRO from "./locales/ro/ResetPassword.json";
import TryDemoInstantRO from "./locales/ro/TryDemoInstant.json";
import ErrorsRO from "./locales/ro/common/Errors.json";
import ButtonsRO from "./locales/ro/common/Buttons.json";
import SuggestionsPageRO from "./locales/ro/SuggestionsPage.json";
import CatalogPageRO from "./locales/ro/CatalogPage.json";
import DownloadGalleryRO from "./locales/ro/common/DownloadGallery.json";

// Initialize i18next with plugins and options
i18n.use(initReactI18next).init({
  resources: {
    en: {
      AdminLogin: AdminLoginEN,
      AdminRegister: AdminRegisterEN,
      Dashboard: DashboardEN,
      EntryPage: EntryPageEN,
      EventPage: EventPageEN,
      ForgotPassword: ForgotPasswordEN,
      MediaPage: MediaPageEN,
      MessagesPage: MessagesPageEN,
      ResetPassword: ResetPasswordEN,
      TryDemoInstant: TryDemoInstantEN,
      Errors: ErrorsEN,
      Buttons: ButtonsEN,
      SuggestionsPage: SuggestionsPageEN,
      CatalogPage: CatalogPageEN,
      DownloadGallery: DownloadGalleryEN,
    },
    ro: {
      AdminLogin: AdminLoginRO,
      AdminRegister: AdminRegisterRO,
      Dashboard: DashboardRO,
      EntryPage: EntryPageRO,
      EventPage: EventPageRO,
      ForgotPassword: ForgotPasswordRO,
      MediaPage: MediaPageRO,
      MessagesPage: MessagesPageRO,
      ResetPassword: ResetPasswordRO,
      TryDemoInstant: TryDemoInstantRO,
      Errors: ErrorsRO,
      Buttons: ButtonsRO,
      SuggestionsPage: SuggestionsPageRO,
      CatalogPage: CatalogPageRO,
      DownloadGallery: DownloadGalleryRO,
    },
    // Add other languages here if needed
  },
  fallbackLng: "ro", // Use 'en' if the translation is not available in the selected language
  debug: false, // Set to 'false' in production
  ns: ["common"], // 'ns' stands for namespaces; add all namespaces used across the application
  defaultNS: "common",
  keySeparator: false, // Disable using keys in form messages.key
  interpolation: {
    escapeValue: false, // Not needed for React as it escapes by default
  },
  react: {
    useSuspense: false, // Set to true if you want to use suspense
  },
});

export default i18n;
