import { differenceInDays } from "date-fns";

export const shouldRenderDownloadGalleryCard = (eventData, isUserAdmin) => {
  if (eventData) {
    const { date: eventDate } = eventData;

    const parsedEventDate = new Date(eventDate);
    const currentDate = new Date();

    const normalizedEventDate = new Date(
      parsedEventDate.getUTCFullYear(),
      parsedEventDate.getUTCMonth(),
      parsedEventDate.getUTCDate()
    );
    const normalizedCurrentDate = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    );

    const daysSinceEvent = differenceInDays(
      normalizedCurrentDate,
      normalizedEventDate
    );

    return daysSinceEvent >= 1 && isUserAdmin;
  }

  return false;
};
