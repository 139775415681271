export default {
  userNavigation: {
    firstOption: "Galerie",
    secondOption: "Feedback",
    thirdOption: "Detalii",
    forthOption: "Evenimente",
    fifthOption: "Sugestii",
  },
  messages: {
    singleTypeOfMessage: "feedback",
    addMessage: "Write a wish",
    multipleTypesOfMessage: "feedback-uri",
    genderTypeOfMessage: "male",
  },
  tutorialModal: {
    step1title: "Distribuie codul QR",
    step2title: "Galerie și Feedback",
    step3title: "Bucură-te de moment!",
    step1text:
      "Distribuie materialele cu cod QR astfel încât acestea să fie la vedere și ușor de scanat de către utilizatori.",
    step2text:
      "Primești galeria de momente foto/video și feedback de la utilizatori în timpul evenimentului sau la finalul acestuia.",
    step3text:
      "Momentele foto/video încărcate de către utilizatori apar direct în albumul digital din Cloud of Memories, de unde le vei descărca după eveniment.",
  },
  tutorialGuestsModal: {
    step1title: "Bine te-am găsit",
    step2title: "Surpinde momentele tale preferate",
    step1text: "Împarte cu noi momentele tale preferate de la nunta noastră.",
    step2text:
      "Momente de la masă🍽️, Poză de grup📸, Poze cu noi📷💑, Cel mai tare dans💃🕺, Tortul🎂, Primul dans👫💃, Un selfie cu tine🤳👩‍❤️‍💋‍👨",
  },
  demoMessages: [
    {
      text: "Team building-ul a fost o experiență cu adevărat extraordinară. Am avut ocazia să mă conectez mai bine cu colegii mei și să lucrez într-un mediu relaxant. A fost un impuls real pentru coeziunea echipei noastre.",
      user: { displayName: "Andrei Popescu" },
    },
    {
      text: "Am fost încântată de modul în care a fost organizat team building-ul. Activitățile au fost distractive și interactive, iar amestecul de echipe ne-a permis să colaborăm cu colegi pe care nu-i cunoșteam foarte bine. A fost o experiență benefică.",
      user: { displayName: "Elena Ionescu" },
    },
    {
      text: "Team building-ul a fost o oportunitate fantastică de a dezvolta abilități de colaborare și de a construi legături mai strânse în echipă. Activitățile au fost variate și am avut parte de multe momente amuzante.",
      user: { displayName: "Alex Georgescu" },
    },
    {
      text: "Petrecerea a fost un moment de bucurie și camaraderie. Am apreciat eforturile depuse de companie pentru a crea un mediu festiv și pentru a ne aduce împreună. A fost un cadou minunat!",
      user: { displayName: "Maria Popa" },
    },
  ],
  registerForm: {
    titluCardPlaceholder: "Team Building",
    textCardPlaceholder:
      "Scanează și încarcă fotografii. Vrem sa vedem prin ochii tăi cum te-ai distrat in Team Building",
  },
};
