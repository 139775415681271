// React Imports
import * as React from "react";

// ** Mui Imports
import Box from "@mui/joy/Box";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

const EventCard = ({ cardData, children }) => {
  const { title, subTitle } = cardData;

  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        width: "100%",
        marginTop: "10px",
        flexWrap: "wrap",
        zIndex: 0,
      }}
    >
      <CardContent>
        <Box>
          <Typography level="h2" fontSize="lg" textAlign="left">
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{ textAlign: "start", width: "100%" }}
            fontSize="sm"
            aria-describedby="card-description"
            mb={2}
            level="body2"
          >
            {subTitle}
          </Typography>

          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

export default EventCard;
