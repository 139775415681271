export default {
  userNavigation: {
    firstOption: "Media",
    secondOption: "Messages",
    thirdOption: "Details",
    forthOption: "Events",
    fifthOption: "Sugestions",
  },
  messages: {
    singleTypeOfMessage: "message",
    addMessage: "Write a message",
    multipleTypesOfMessage: "messages",
    genderTypeOfMessage: "neutral",
  },
  tutorialModal: {
    step1title: "Share the QR Code",
    step2title: "Media and Messages",
    step3title: "Enjoy Your Vacation!",
    step1text:
      "Distribute QR code cards for easy access to the vacation album and messaging platform.",
    step2text:
      "Collect media and messages from your travel companions in real-time.",
    step3text:
      "All the collected memories are stored in a digital album, ready for you to download and cherish after your vacation.",
  },
  tutorialGuestsModal: {
    step1title: "Bine te-am găsit",
    step2title: "Surpinde momentele tale preferate",
    step1text: "Împarte cu noi momentele tale preferate de la nunta noastră.",
    step2text:
      "Momente de la masă🍽️, Poză de grup📸, Poze cu noi📷💑, Cel mai tare dans💃🕺, Tortul🎂, Primul dans👫💃, Un selfie cu tine🤳👩‍❤️‍💋‍👨",
  },
  sugestionsGuests: {
    sugestion1title: "Mâncăruri inedite🍽️",
    sugestion2title: "Poze de cuplu📷💑",
    sugestion3title: "Cel mai tare party💃🕺",
    sugestion4title: "Un moment special🎂",
    sugestion5title: "Peisaje magnifice🏖️",
    sugestion6title: "Poză de grup📸",
    sugestion7title: "Un selfie🤳👩‍❤️‍💋‍👨",
    sugestion1subTitle:
      "Găsește aventura în fiecare gust! Capturați momentul culinar inedit în vacanță!",

    sugestion2subTitle:
      "Îmbrățișați iubirea în fiecare captură! Creați amintiri de neuitat împreună!",

    sugestion3subTitle:
      "Capturați momente de bucurie și distracție pe ringul de dans!",

    sugestion4subTitle:
      "Capturați magia în fiecare clipă! Transformați momentul într-o amintire veșnică!",

    sugestion5subTitle:
      "Încadrați splendoarea naturii în fiecare fotografie! Capturați frumusețea lumii înconjurătoare!",
    sugestion6subTitle:
      "Surprindeți-vă într-un moment magic! Faceti o poză de grup!",
    sugestion7subTitle:
      "Surprindeți-vă momentul perfect! Izbucniți în zâmbete și capturați amintiri!",
  },
  demoMessages: [
    {
      text: "Enjoy every moment of this wonderful journey! May this vacation bring you lots of sweet memories and new experiences. Have a fantastic trip!",
      user: { displayName: "Ethan Williams" },
    },
    {
      text: "Here's to a vacation filled with adventure, relaxation, and unforgettable moments. May it be everything you've dreamed of and more. Safe travels!",
      user: { displayName: "Madison Smith" },
    },
    {
      text: "Wishing you a journey filled with joy and discovery. May each day of your vacation be a treasure and bring you lasting happiness!",
      user: { displayName: "Christopher Davis" },
    },
    {
      text: "May your vacation be packed with beautiful destinations, fun adventures, and precious moments. Enjoy every second of your journey!",
      user: { displayName: "Emily Johnson" },
    },
  ],
  registerForm: {
    titluCardPlaceholder: "E.g.: Tropical Getaway",
    textCardPlaceholder:
      "E.g.: Scan and share your favorite vacation moments with us.",
  },
};
