import { useEffect, useState } from "react";

import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import axios from "axios";
import Box from "@mui/joy/Box";
import { useNavigate } from "react-router-dom";
import Card from "@mui/joy/Card/Card";
import dayjs from "dayjs";
import { styles, texts } from "../../config/index";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { useTranslation } from "react-i18next";
import AdminCard from "../common/AdminCard";
import { useEvent } from "../../hooks/useEvent";
import {
  formatDateStringShort,
  getLocalStorageToken,
} from "../../utils/helpers";

const EventDetails = ({ isUserAdmin, userData, eventData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("EventPage");
  const { t: te } = useTranslation("Errors");

  const [, , getEvent] = useEvent();

  const [data, setData] = useState({
    name: eventData.name,
    message: eventData.welcomeMessage,
    dateEvent: formatDateStringShort(eventData.date),
    sharePics: eventData.showImages,
    shareMessage: eventData.showMessages,
    status: "initial",
  });
  const [dateBeforeWarn, setDateBeforeWarn] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getLocalStorageToken();
    setData((current) => ({ ...current, status: "loading" }));
    try {
      axios
        .put(
          import.meta.env.VITE_BACKEND_URL +
            import.meta.env.VITE_REGION +
            "/api/events/" +
            eventData._id,
          {
            name: data.name,
            welcomeMessage: data.message,
            showImages: data.sharePics,
            showMessages: data.shareMessage,
            date: data.dateEvent,
          },
          token && { headers: { Authorization: token } }
        )
        .then(() => {
          getEvent();
          setData((current) => ({ ...current, status: "sent" }));
        })
        .catch((err) => {
          console.error(err);
          setData((current) => ({ ...current, status: "failure" }));
        });
    } catch (error) {
      setData((current) => ({ ...current, status: "failure" }));
    }
    setOpenEventModal(false);
  };

  useEffect(() => {
    if (eventData) {
      setData({
        name: eventData.name,
        message: eventData.welcomeMessage,
        dateEvent: formatDateStringShort(eventData.date),
        sharePics: eventData.showImages,
        shareMessage: eventData.showMessages,
        status: "initial",
      });
    }
  }, [eventData]);

  return (
    <>
      {isUserAdmin !== null &&
        isUserAdmin &&
        (userData?._id != "64b7ca4d8565dfcd3d2cdeca" || !eventData.isDemo) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Card
              variant="outlined"
              component="li"
              mb={0.5}
              sx={{ width: "100%" }}
            >
              <Typography level="h2" fontSize="lg" textAlign={"left"}>
                {t("eventDetailsTitle")}
              </Typography>
              <Box>
                <Typography
                  level="body-md"
                  textAlign={"center"}
                  sx={{ color: styles.primaryColor, m: 0, fontWeight: "bold" }}
                >
                  {eventData.name}
                </Typography>
                <Typography
                  level="body1"
                  textAlign={"center"}
                  sx={{ m: 0, p: 0 }}
                >
                  {data.dateEvent}
                </Typography>
              </Box>

              <Button
                sx={{ width: "100%" }}
                variant="solid"
                color="primary"
                size="md"
                disabled={eventData.isDemo}
                onClick={() => {
                  setOpenEventModal(true);
                }}
              >
                {t("eventDetailsChangeButton")}
              </Button>
              <Modal
                keepMounted
                open={openEventModal}
                onClose={() => setOpenEventModal(false)}
              >
                <ModalDialog
                  sx={{
                    width: "100%",
                    maxHeight: "80vh",
                    overflowY: "auto",
                    padding: "20px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    level="h2"
                    fontSize="lg"
                    textAlign={"left"}
                    mb={1}
                  >
                    {t("eventDetailsTitle")}
                  </Typography>
                  <form
                    onSubmit={handleSubmit}
                    id="demo"
                    style={{ width: "100%" }}
                  >
                    <FormControl>
                      <FormLabel>{t("eventNameLabel")}</FormLabel>
                      <Input
                        sx={{ width: "100%", marginBottom: "10px" }}
                        placeholder={t("eventNamePlaceholder")}
                        type="text"
                        required
                        disabled={eventData.isDemo}
                        variant="soft"
                        value={data.name}
                        onChange={(e) =>
                          setData({
                            ...data,
                            name: e.target.value,
                            status: "initial",
                          })
                        }
                        size="md"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("welcomeMessageLabel")}</FormLabel>
                      <Textarea
                        sx={{ width: "100%", marginBottom: "10px" }}
                        placeholder={t("welcomeMessagePlaceholder")}
                        required
                        disabled={eventData.isDemo}
                        variant="soft"
                        value={data.message}
                        onChange={(e) =>
                          setData({
                            ...data,
                            message: e.target.value,
                            status: "initial",
                          })
                        }
                        minRows={3}
                        size="md"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("eventDateLabel")}</FormLabel>
                      <Input
                        type="date"
                        sx={{ width: "100%", marginBottom: "10px" }}
                        required
                        variant="soft"
                        disabled={eventData.started || eventData.isDemo}
                        value={data.dateEvent}
                        onChange={(e) => {
                          const dateString = e.target.value;
                          let newDate = dayjs(dateString);
                          let today = dayjs();

                          newDate = newDate.startOf("day");
                          today = today.startOf("day");

                          const todayString = today.format("YYYY-MM-DD");
                          newDate.isBefore(today)
                            ? setDateBeforeWarn(true)
                            : setDateBeforeWarn(false);
                          newDate.isBefore(today)
                            ? setData({
                                ...data,
                                dateEvent: todayString,
                              })
                            : setData({
                                ...data,
                                dateEvent: dateString,
                              });
                        }}
                        size="md"
                      />
                    </FormControl>
                    {dateBeforeWarn && (
                      <FormHelperText>
                        <Typography
                          level="body2"
                          textColor={styles.primaryColor}
                        >
                          {te("dateNotInPast")}
                        </Typography>
                      </FormHelperText>
                    )}
                    {eventData.started && (
                      <FormHelperText sx={{ textAlign: "left" }}>
                        {te("dateCannotBeChanged")}
                      </FormHelperText>
                    )}
                    <FormControlLabel
                      sx={{ width: "100%" }}
                      control={
                        <Switch
                          checked={data.sharePics}
                          onChange={(e) => {
                            setData({
                              ...data,
                              sharePics: e.target.checked,
                            });
                          }}
                        />
                      }
                      label={
                        <Typography level="p">
                          {t("guestsSeeGallery")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ width: "100%", marginTop: "10px" }}
                      control={
                        <Switch
                          checked={data.shareMessage}
                          onChange={(e) => {
                            setData({
                              ...data,
                              shareMessage: e.target.checked,
                            });
                          }}
                        />
                      }
                      label={
                        <Typography level="p">
                          {t("guestsSeeWishes")}{" "}
                          {import.meta.env.VITE_REGION === "com"
                            ? texts.messages.multipleTypesOfMessage
                            : texts.messages.multipleTypesOfMessage + "le"}
                        </Typography>
                      }
                    />

                    <Button
                      sx={{ width: "100%", marginTop: "20px" }}
                      variant="solid"
                      color="primary"
                      size="md"
                      loading={data.status === "loading"}
                      type="submit"
                    >
                      {t("saveEventDetailsButton")}
                    </Button>
                  </form>
                </ModalDialog>
              </Modal>
              {data.status === "failure" && (
                <FormHelperText
                  style={{ display: "flex", justifyContent: "center" }}
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                  })}
                >
                  {te("genericError")}
                </FormHelperText>
              )}

              {data.status === "sent" && (
                <FormHelperText
                  style={{ display: "flex", justifyContent: "center" }}
                  sx={(theme) => ({
                    color: theme.vars.palette.primary[400],
                  })}
                >
                  {t("saveOkMessage")}
                </FormHelperText>
              )}
              <Button
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                variant="soft"
                color="danger"
                className="formButton"
                size="md"
                onClick={() => {
                  if (eventData.isDemo) {
                    window.location.replace("https://cloudofmemories.com");
                  } else {
                    localStorage.clear();
                    navigate("/admin-login?event=" + eventData._id);
                  }
                }}
              >
                {t("logoutButton")}
              </Button>
            </Card>
          </Box>
        )}
      <AdminCard />
    </>
  );
};

export default EventDetails;
