// ** React imports
import { useState, useEffect } from "react";

const AlertsSingleton = (function () {
  let instance;

  function createInstance() {
    let alertMessage = "";
    let showAlertTime = 3000;
    let listeners = [];

    function setAlertMessage(message) {
      alertMessage = message;
      notifyListeners();
    }

    function setShowAlertTime(time) {
      showAlertTime = time;
      notifyListeners();
    }

    function addListener(listener) {
      listeners.push(listener);
    }

    function removeListener(listener) {
      listeners = listeners.filter((l) => l !== listener);
    }

    function notifyListeners() {
      listeners.forEach((listener) =>
        listener({ alertMessage, showAlertTime })
      );
    }

    return {
      setAlertMessage,
      setShowAlertTime,
      addListener,
      removeListener,
      getAlertMessage: () => alertMessage,
      getShowAlertTime: () => showAlertTime,
    };
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

const useAlerts = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlertTime, setShowAlertTime] = useState(3000);
  const singleton = AlertsSingleton.getInstance();

  useEffect(() => {
    const updateState = ({ alertMessage, showAlertTime }) => {
      setAlertMessage(alertMessage);
      setShowAlertTime(showAlertTime);
    };

    singleton.addListener(updateState);
    updateState({
      alertMessage: singleton.getAlertMessage(),
      showAlertTime: singleton.getShowAlertTime(),
    });

    return () => {
      singleton.removeListener(updateState);
    };
  }, [singleton]);

  return {
    setAlertMessage: singleton.setAlertMessage,
    setShowAlertTime: singleton.setShowAlertTime,
    alertMessage,
    showAlertTime,
  };
};

export default useAlerts;
