const sourceLink = `${
  import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION
}/api/images`;

const generateImageSource = (eventId, image) => {
  const bucketUrl = import.meta.env.VITE_BUCKET_URL;
  const storageUrl = `https://storage.googleapis.com/${bucketUrl}/${eventId}/${image._id}`;
  return {
    type: "image",
    imageId: image._id,
    // src: `${sourceLink}/event/${eventId}/media/${image._id}`,
    src: storageUrl,
    download: {
      // url: `${sourceLink}/event/${eventId}/media/${image._id}`,
      url: storageUrl,
      filename: `${image._id}.jpg`,
    },
  };
};

const generateVideoSource = (eventId, media) => {
  if (media?.status === "uploading") {
    return {
      type: "image",
      imageId: media._id,
      src: "https://family.cloudofmemories.ro/api/images/filename/video_placeholder.png",
    };
  }

  return {
    type: "video",
    poster: `${sourceLink}/event/${eventId}/thumbnail/${media._id}`,
    controls: true,
    autoPlay: true,
    // loop: true,
    playsInline: true,
    disablePictureInPicture: true,
    controlsList: "nofullscreen",
    sources: [
      {
        src: `${sourceLink}/event/${eventId}/media/${media._id}`,
        type: "video/mp4",
      },
    ],
    download: {
      url: `${sourceLink}/event/${eventId}/media/${media._id}`,
      filename: `${media._id}.mp4`,
    },
  };
};

export const generateSlide = (eventId, media) => {
  return !media.isDemoImage
    ? media.type !== "video"
      ? generateImageSource(eventId, media)
      : generateVideoSource(eventId, media)
    : { src: media._id };
};
