// ** React Imports
import React from "react";
import { useTranslation } from "react-i18next";

// ** Mui Imports
import {
  Card,
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormHelperText,
  Checkbox,
  Button,
} from "@mui/joy";

// ** Config Imports
import { styles, texts } from "../config/index";

const {
  registerForm: { titluCardPlaceholder, textCardPlaceholder },
} = texts;

const InvitationCardForm = ({
  invitationCardData,
  handleInvitationCardChange,
  handleInvitiationCardButton = false,
}) => {
  const { t } = useTranslation("AdminRegister");

  return (
    <Card variant="outlined" sx={{ mt: 2 }}>
      <Box>
        {" "}
        <Typography level="h5" sx={{ textAlign: "start", mb: 0 }}>
          {t("physicalCardDetailsTitle")}
        </Typography>
      </Box>

      <FormControl>
        <FormLabel> {t("cardTitleLabel")}</FormLabel>
        <Input
          sx={{
            width: "100%",
            marginBottom: "10px",
          }}
          placeholder={titluCardPlaceholder}
          required
          type="text"
          variant="soft"
          slotProps={{
            input: {
              maxLength: 25,
            },
          }}
          size="md"
          value={invitationCardData.cardTitle}
          onChange={handleInvitationCardChange("cardTitle")}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t("cardTextLabel")}</FormLabel>
        <Textarea
          sx={{ width: "100%", marginBottom: "10px" }}
          placeholder={textCardPlaceholder}
          required
          variant="soft"
          slotProps={{
            textarea: {
              maxLength: 170,
            },
          }}
          minRows={3}
          size="md"
          value={invitationCardData.cardText}
          onChange={handleInvitationCardChange("cardText")}
        />
      </FormControl>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "-10px",
        }}
      >
        <Checkbox
          color="danger"
          variant="outlined"
          sx={{
            marginRight: "10px",
            color: "#e7007e",
            "&.Mui-checked": {
              color: "#e7007e",
            },
            "& .MuiSvgIcon-root": {
              color: "#e7007e",
            },
          }}
          checked={invitationCardData.checkBox}
          onChange={handleInvitationCardChange("checkBox")}
        />
        <Typography sx={{ marginRight: "10px" }}>
          {t("standardTexts")}
        </Typography>
      </Box>
      <FormHelperText>
        <Typography
          level="body2"
          sx={{
            textAlign: "left",
            fontWeight: "bold",
          }}
          textColor={styles.primaryColor}
        >
          {t("physicalCardReminder")}
        </Typography>
      </FormHelperText>
      {handleInvitiationCardButton && (
        <Button
          sx={{ width: "100%", margin: "20px 0px" }}
          variant="solid"
          color="primary"
          size="md"
          onClick={handleInvitiationCardButton}
        >
          {t("changeCardTexts")}
        </Button>
      )}
    </Card>
  );
};

export default InvitationCardForm;
