import { createModule, useLightboxState } from "yet-another-react-lightbox";
import { usePrinting } from "../../hooks/usePrinting";
import { Button } from "@mui/joy";
import axios from "axios";

const printImage = async (imageId) => {
  await axios.post(
    import.meta.env.VITE_BACKEND_URL +
      import.meta.env.VITE_REGION +
      `/api/images/${imageId}/print`,
    {
      userId: "6620f98c038eb468291bb54f",
      printsNumber: 1,
    }
  );
};

const PrintingButton = ({ children, ...props }) => {
  const { currentSlide } = useLightboxState();
  const [printingQueue] = usePrinting(props.eventId);

  return (
    <>
      {currentSlide.type !== "video" && props.isPrintingEvent && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: "1301",
            bottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "150px",
            }}
            variant="solid"
            color="primary"
            size="md"
            disabled={printingQueue.includes(currentSlide.imageId)}
            loading={printingQueue.includes(currentSlide.imageId)}
            loadingPosition="end"
            onClick={() => {
              printImage(currentSlide.imageId);
            }}
          >
            {printingQueue.includes(currentSlide.imageId)
              ? "Așteaptă"
              : "Print"}
          </Button>
        </div>
      )}
    </>
  );
};

const PrintModule = createModule("PrintButton", PrintingButton);
export const PrintPlugin = ({ addSibling, augment }) => {
  augment(({ eventId, ...restProps }) => ({
    eventId: eventId,
    ...restProps,
  }));

  addSibling("toolbar", PrintModule);
};
