// ** React imports
import { useState, useEffect } from "react";

// ** Images imports
import thumbnailVideoPlaceholder from "../../assets/video-placeholder.png";

const ThumbnailSingleton = (function () {
  let instance;

  function createInstance() {
    let thumbnails = [];
    let thumbnailProgress = {};
    let listeners = [];

    function setNewThumbnail(thumbnailUniqueId) {
      thumbnails = [
        ...thumbnails,
        {
          _id: thumbnailUniqueId,
          photoSrc: thumbnailVideoPlaceholder,
          isThumbnail: true,
          type: "image",
        },
      ];
      notifyListeners();
    }

    function setProgressForCurrentThumbnail(
      thumbnailUniqueId,
      percentCompleted
    ) {
      thumbnailProgress = {
        ...thumbnailProgress,
        [thumbnailUniqueId]: percentCompleted,
      };
      notifyListeners();
    }

    function removeThumbnail(thumbnailUniqueId) {
      thumbnails = thumbnails.filter(
        (thumbnail) => thumbnail._id !== thumbnailUniqueId
      );
      const { [thumbnailUniqueId]: _, ...newProgress } = thumbnailProgress;
      thumbnailProgress = newProgress;
      notifyListeners();
    }

    function addListener(listener) {
      listeners.push(listener);
    }

    function removeListener(listener) {
      listeners = listeners.filter((l) => l !== listener);
    }

    function notifyListeners() {
      listeners.forEach((listener) =>
        listener({ thumbnails, thumbnailProgress })
      );
    }

    return {
      setNewThumbnail,
      setProgressForCurrentThumbnail,
      removeThumbnail,
      addListener,
      removeListener,
      getThumbnails: () => thumbnails,
      getThumbnailProgress: () => thumbnailProgress,
    };
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

const useThumbnail = () => {
  const [thumbnails, setThumbnails] = useState([]);
  const [thumbnailProgress, setThumbnailProgress] = useState({});
  const singleton = ThumbnailSingleton.getInstance();

  useEffect(() => {
    const updateState = ({ thumbnails, thumbnailProgress }) => {
      setThumbnails(thumbnails);
      setThumbnailProgress(thumbnailProgress);
    };

    singleton.addListener(updateState);
    updateState({
      thumbnails: singleton.getThumbnails(),
      thumbnailProgress: singleton.getThumbnailProgress(),
    });

    return () => {
      singleton.removeListener(updateState);
    };
  }, [singleton]);

  return {
    setNewThumbnail: singleton.setNewThumbnail,
    setProgressForCurrentThumbnail: singleton.setProgressForCurrentThumbnail,
    removeThumbnail: singleton.removeThumbnail,
    thumbnails,
    thumbnailProgress,
  };
};

export default useThumbnail;
