import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// ** Fonts Imports
import MontserratBold from "./cardFonts/Montserrat-Bold.ttf";
import MontserratItalic from "./cardFonts/Montserrat-Italic.ttf";
import PinyonScriptRegular from "./cardFonts/PinyonScript-Regular.ttf";
import { splitScentenceByMaxChars } from "../../utils/helpers";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratBold,
      fontWeight: "bold",
      fontStyle: "normal",
    },
    {
      src: MontserratItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

Font.register({
  family: "PinyonScript",
  fonts: [
    {
      src: PinyonScriptRegular,
      fontWeight: "normal",
      fontStyle: "normal",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    width: 354 * 72,
    height: 1.97 * 72,
    flexDirection: "row",
    backgroundColor: "#fff",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  overlay: {
    flexGrow: 1,
    // padding: "20px 16px 20px 0px",
    marginTop: "13px",
    marginRight: "15px",
    marginLeft: "15px",
    textAlign: "center",
  },
  header: {
    fontFamily: ["PinyonScript"],
    fontWeight: "normal",
    fontSize: "18px",
  },
  title: {
    color: "#E91E63",
    fontFamily: ["Montserrat"],
    fontWeight: "bold",
    fontSize: "8px",
    marginTop: "0px",
    textAlign: "center",
  },
  description: {
    marginTop: "1px",
    fontFamily: ["Montserrat"],
    fontWeight: "normal",
    fontStyle: "italic",
    fontSize: "8px",
    textAlign: "center",
  },
  logo: {
    width: "45px",
    height: "15px",
    minHeight:"15px",
    margin: "6px",
  },
  qrCode: {
    width: "77px",
    minWidth: "77px",
    height: "77px",
  },
});



const CardsPdfDocument = ({ pdfData }) => {
  const { groomsName, title, description, bgImg, logo, qrCodeImage } = pdfData;
  return (
    <Document>
      <Page size={[3.55 * 72, 1.97 * 72]} style={styles.page}>
        <Image src={bgImg} style={styles.image} />
        <View style={styles.overlay}>
          <Text style={styles.header}>{groomsName}</Text>
          <View
            style={{
              display: "flex",
              alignItems: "start",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "8px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "85px",
                width: "100%",
              }}
            >
              {title && <Text style={styles.title}>{title}</Text>}

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <View
                  style={{
                    marginTop:"8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {splitScentenceByMaxChars(description, 35).map(
                    (sentence, key) => (
                      <Text
                        style={styles.description}
                        key={"pdf_description_" + key}
                      >
                        {sentence}
                      </Text>
                    )
                  )}
                </View>
                <Image src={logo} style={styles.logo} />
              </View>
            </View>
            <Image src={qrCodeImage} style={styles.qrCode} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CardsPdfDocument;
