import React from "react";

// ** Page Components Imports
import Suggestion from "../components/sugestionPage/sugestion/Sugestion";

// **  MUI Material Imports
import Container from "@mui/material/Container";

// ** Third party Imports
import { useTranslation } from "react-i18next";

// ** Helpers imports
import { sugestionRender } from "../components/sugestionPage/sugestion/helpers";

// ** Api Imports
import AppPageHeader from "../components/common/AppPageHeader";

const SugestionPage = () => {
  //HOOKS
  const { t } = useTranslation("SuggestionsPage");

  return (
    <>
      <AppPageHeader
        pageHeaderProps={{ title: t("pageTitle"), body: t("pageSubtitle") }}
        showImageHeader={false}
      />
      <Container sx={{ marginBottom: "80px" }}>
        {sugestionRender.map((sugestion) => {
          const { id, title, subTitle, iconClickHandler, name } = sugestion;
          return (
            <Suggestion
              key={id}
              title={title}
              subTitle={subTitle}
              iconClickHandler={iconClickHandler}
              name={name}
            />
          );
        })}
      </Container>
    </>
  );
};

export default SugestionPage;
