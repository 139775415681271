import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";

import DiscountModal from "./DiscountModal";

// ** Redux Imports
import { useSelector } from "react-redux";

// ** Common Components
import MUIAlert from "../components/MUIAlert";
import BottomNavigation from "./BottomNavigation";

const NavigationWrapper = ({ children }) => {
  const { isDiscountModalViewed } = useSelector(
    (state) => state.modals.discountModal
  );

  const isDemo = JSON.parse(localStorage.getItem("isDemo"));
  const discountCode = import.meta.env.VITE_DISCOUNT_CODE;

  const numberFromDiscountCode = (codeValue) => {
    const match = codeValue.match(/\d+/);
    const extractedNumber = parseInt(match[0], 10);

    if (match) {
      return extractedNumber;
    } else return null;
  };

  const query = useQuery();
  const navigate = useNavigate();
  useEffect(() => {
    const event = query.get("event");
    if (!event || event === "null") {
      navigate("/");
    }
  }, []);

  return (
    <>
      {!isDiscountModalViewed &&
        isDemo &&
        discountCode &&
        numberFromDiscountCode(discountCode) && (
          <DiscountModal discount={numberFromDiscountCode(discountCode)} />
        )}
      {children}
      <BottomNavigation />
      <MUIAlert />
    </>
  );
};

export default NavigationWrapper;
