// MUI Material Icons Imports
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// MUI Material Imports
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

// Assets

import { demoImages, ErrorTypes } from "../utils/helpers";

// Media Page Components
import AddingMedia from "../components/mediaPage/AddingMedia";
import MediaGallery from "../components/mediaPage/MediaGallery";

// Hooks
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import useThumbnail from "../hooks/mediaPage/useThumbnail";

// API and Configuration
import { styles } from "../config/index";

// ** MUI Imports
import { Typography } from "@mui/joy";

// ** Helpers Imports
import { getLocalStorageToken } from "../utils/helpers";
import { useTranslation } from "react-i18next";

// ** Hooks Imports
import useAlerts from "../hooks/mediaPage/useAlerts";

// ** Third party libraries imports
import Loading from "../components/common/Loading";
import AppPageHeader from "../components/common/AppPageHeader";
import { getAllImages, getGuestUserPhotos } from "../../api/eventApi";
import useEventBlock from "../hooks/useEventBlock";
import NetworkWrapper from "../components/common/NetworkWrapper";

function MediaPage() {
  // Layout context
  const [eventData, userData, isUserAdmin] = useOutletContext();

  // HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation("MediaPage");
  const query = useQuery();
  const { setAlertMessage, setShowAlertTime } = useAlerts();
  const [, setErrorType] = useEventBlock();
  const { thumbnails } = useThumbnail();

  // STATES
  const [uploadedImages, setUploadedImages] = useState([]);
  const [userPhotos, setUserPhotos] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingSingleUpload, setLoadingSingleUpload] = useState(false);
  const [demoImagesLoaded, setDemoImagesLoaded] = useState(false);

  const getUploadedFiles = async (refetch = false) => {
    const token = getLocalStorageToken();

    refetch && setPage(1);
    const requestPage = refetch ? 1 : page;

    try {
      const eventImages = await getAllImages(
        query.get("event"),
        userData._id,
        requestPage
      );

      setTotalPages(eventImages.totalPages);

      // If it's a refetch due to new image uploads, clear existing data
      let newImages = refetch
        ? [...eventImages.images]
        : [...uploadedImages, ...eventImages.images];

      // Append demo images only once, when the data is first loaded and the event is a demo
      if (eventData.isDemo && (!demoImagesLoaded || refetch)) {
        newImages = [...demoImages, ...newImages];
        setDemoImagesLoaded(true); // Mark demo images as loaded
      }

      setUploadedImages(newImages);
      setIsLoaded(true);
      if (!token) getEventAndUser();
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.clear();
        navigate("/");
      } else {
        setUploadedImages([]);
        setErrorType(ErrorTypes.EventBroken);
        if (error.response?.data?.message === "Autentificare incorecta") {
          if (userData._id === "64b7ca4d8565dfcd3d2cdeca") {
            navigate("/demo");
          } else {
            navigate("/admin-login");
          }
        }
        localStorage.clear();
        navigate("/");
        setIsLoaded(true);
      }
      localStorage.clear();
      navigate("/");
      setIsLoaded(true);
    }
  };

  const loadMore = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const reloadNewGuestsPhotos = async (oldImages) => {
    try {
      const eventImages = await getAllImages(
        query.get("event"),
        userData._id,
        1
      );

      const idsInOldImagesArr = oldImages.map((image) => image._id);

      const newUploadedImages = eventImages.images.filter(
        (image) => !idsInOldImagesArr.includes(image._id)
      );
      let newImages = [];

      if (eventData.isDemo) {
        const numberOfDemoImages = demoImages.length;
        newImages = [...oldImages];
        newImages.splice(numberOfDemoImages, 0, ...newUploadedImages);
      } else {
        newImages = [...newUploadedImages, ...oldImages];
      }
      setUploadedImages(newImages);
    } catch (e) {
      console.log("Error getting all images:", e);
      localStorage.clear();
      navigate("/");
    }
  };

  useEffect(() => {
    if (page != 1) getUploadedFiles();
  }, [page]);

  const getEventAndUser = async () => {
    const userPhotos = await getGuestUserPhotos(
      query.get("event"),
      userData._id
    );
    eventData?.showImages && setUserPhotos(userPhotos);
  };

  useEffect(() => {
    if (userData) {
      setIsLoaded(false);
      getUploadedFiles(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      const refetchTime =
        import.meta.env.VITE_GALERY_REFETCH_INTERVAL_SECONDS || 600000;

      const handleFocus = () => {
        reloadNewGuestsPhotos(uploadedImages);
      };

      window.addEventListener("focus", handleFocus);

      const refetchInterval = setInterval(() => {
        reloadNewGuestsPhotos(uploadedImages);
      }, refetchTime);

      return () => {
        window.removeEventListener("focus", handleFocus);
        clearInterval(refetchInterval);
      };
    }
  }, [userData, uploadedImages]);

  return (
    <>
      <Loading isLoaded={isLoaded} />
      <AppPageHeader isUserAdmin={isUserAdmin} renderDownload={true} />
      <NetworkWrapper>
        <>
          {userPhotos && userPhotos.length > 0 && isLoaded && eventData && (
            <>
              <Accordion
                TransitionProps={{
                  timeout: 0,
                }}
                sx={{ marginTop: "6px !important" }}
                disableGutters={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Typography level="h6" sx={{ margin: 0 }}>
                    {t("yourGallery")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MediaGallery
                    uploadedImages={userPhotos}
                    eventId={eventData._id}
                    handleRefetch={getUploadedFiles}
                    setAlertMessage={setAlertMessage}
                    setShowAlertTime={setShowAlertTime}
                    showDelete={userPhotos ? true : false}
                    isUserImages={true}
                    eventIsDemo={eventData.isDemo}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}

          <Container style={{ marginBottom: "150px", borderRadius: "0px" }}>
            {loadingSingleUpload ? (
              <>
                <div
                  style={{
                    height: "45vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              </>
            ) : (
              isLoaded &&
              eventData && (
                <>
                  {uploadedImages.length > 0 || thumbnails.length > 0 ? (
                    <Typography
                      level="h5"
                      style={{ textAlign: "start", marginTop: "10px" }}
                    >
                      {t("entireGallery")}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        height: "45vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography level="h6" style={{ textAlign: "center" }}>
                        {t("emptyGalleryMessage")}
                        <br />
                        <span
                          style={{
                            fontWeight: "800",
                            color: styles.primaryColor,
                          }}
                        >
                          {t("beFirstToUpload")}
                        </span>
                      </Typography>
                    </div>
                  )}

                  <MediaGallery
                    isLoaded={isLoaded}
                    uploadedImages={uploadedImages}
                    eventId={eventData._id}
                    loadMore={loadMore}
                    page={page}
                    totalPages={totalPages}
                    handleRefetch={getUploadedFiles}
                    setAlertMessage={setAlertMessage}
                    setShowAlertTime={setShowAlertTime}
                    showDelete={userPhotos ? false : true}
                    isPrintingEvent={eventData.printsLimit}
                    eventIsDemo={eventData.isDemo}
                  />

                  <AddingMedia
                    handleRefetch={getUploadedFiles}
                    eventData={eventData}
                    setLoadingSingleUpload={setLoadingSingleUpload}
                  />
                </>
              )
            )}
          </Container>
        </>
      </NetworkWrapper>
    </>
  );
}

export default MediaPage;
