import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { styles, images } from "./config/index.js";

// ** Redux imports
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { initSentryOptions } from "./utils/sentrySetup.js";

// Sentry Setup

Sentry.init(initSentryOptions);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/sw.js").then(
      function (registration) {
        // Registration was successful
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      function (err) {
        // registration failed :(
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

function toKebabCase(string) {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
}

for (let property in styles) {
  if (styles.hasOwnProperty(property)) {
    const cssVarName = `--${toKebabCase(property)}`;
    document.documentElement.style.setProperty(cssVarName, styles[property]);
  }
}

/**
 * Capitalizes the first letter of a string and converts the rest to lower case.
 * @returns {string} The capitalized string.
 */
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

function isiOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

if (isiOS()) {
  document.addEventListener(
    "touchmove",
    function (event) {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );
}
ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <meta name="theme-color" content={styles.primaryColor} />
        <title>Cloud of Memories</title>
        <meta
          name="description"
          content="Bun venit la aplicatia Cloud of Memories!"
        />
        <link rel="icon" href={images.soloLogo} />
      </Helmet>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  </>
);
