//** React Imports
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// ** MUI Imports
import Button from "@mui/joy/Button/Button";
import Typography from "@mui/joy/Typography/Typography";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

// ** Component Imports
import AdminRegisterForm from "../components/AdminRegisterForm";
import PageHeader from "../components/PageHeader";

// ** Hookss Imports
import useQuery from "../hooks/useQuery";

// ** Utils Imports
import { ErrorMessages } from "../utils/helpers";

// ** Api Imports
import { getPaymentByTk } from "../../api/paymentApi";

const AdminRegister = () => {
  const query = useQuery();

  const [paymentData, setPaymentData] = useState(null);
  const [isPaymentUsed, setIsPaymentUsed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { t } = useTranslation("AdminRegister");

  const handleError = (error) => {
    console.error(error);

    const errorMessage = error.response?.data?.message || "";

    if (errorMessage === ErrorMessages["PaymentUsed"]) {
      setIsPaymentUsed(true);
      setIsLoaded(true);
    } else if (errorMessage === ErrorMessages["PaymentNotFound"]) {
      setIsPaymentUsed(true);
      setIsLoaded(true);
    }
  };

  const getPayments = async (tk) => {
    try {
      const data = await getPaymentByTk(tk);
      setIsLoaded(true);
      setPaymentData(data);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const tk = query.get("tk");
    if (!tk)
      window.location.href =
        "https://cloudofmemories." + import.meta.env.VITE_REGION;
    else {
      getPayments(tk);
    }

    localStorage.clear();
  }, []);

  return (
    <>
      <CssBaseline />
      <Container
        sx={{ height: "calc(100% - 50px)", width: "100%", position: "static" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!isLoaded}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isLoaded && (
          <>
            {isPaymentUsed ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  minHeight: 400,
                }}
              >
                <PageHeader />
                <Typography
                  level="h5"
                  style={{
                    color: "red",
                    marginBottom: "20px",
                    textAlign: "left",
                  }}
                >
                  {t("paymentErrorTitle")}
                </Typography>
                <Typography
                  level="p"
                  style={{ marginBottom: "20px", textAlign: "left" }}
                >
                  {t("paymentErrorDetail")}
                </Typography>
                <Typography
                  level="p"
                  style={{ marginBottom: "20px", textAlign: "left" }}
                >
                  {t("paymentErrorContact1")}{" "}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        "https://cloudofmemories." +
                        import.meta.env.VITE_REGION +
                        "/contact";
                    }}
                  >
                    {t("paymentErrorContact2")}
                  </Link>
                  .
                </Typography>
                <Button
                  onClick={() => {
                    window.location.href =
                      "https://cloudofmemories." + import.meta.env.VITE_REGION;
                  }}
                  style={{ alignSelf: "center" }}
                >
                  {t("returnHomeButton")}
                </Button>
              </div>
            ) : (
              <>
                <PageHeader alignImg={"center"} />
                <Box sx={{ width: "100%", marginTop: "-15px" }}>
                  <AdminRegisterForm
                    paymentData={paymentData}
                    setIsPaymentUsed={setIsPaymentUsed}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default AdminRegister;
