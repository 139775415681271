import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box } from "@mui/joy";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import TextArea from "@mui/joy/Textarea";
import Input from "@mui/joy/Input";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import * as React from "react";
import { texts } from "../config/index";
import { useTranslation } from "react-i18next";

// ** Custom Hooks

import useDiscountModalTimer from "../hooks/useDiscountModalTimer";

export default function AddMessage({
  handleSubmit,
  message,
  setMessage,
  messageStatus,
  emptyTypeDataError,
}) {
  const { t } = useTranslation("MessagesPage");
  const { t: te } = useTranslation("Errors");
  const { pauseTimer, resumeTimer } = useDiscountModalTimer();

  const placeholderTextArea = () => {
    let text =
      t("textAreaPlaceholder") +
      " " +
      (import.meta.env.VITE_REGION === "com"
        ? "a " + texts.messages.singleTypeOfMessage
        : texts.messages.genderTypeOfMessage === "male"
        ? "un " + texts.messages.singleTypeOfMessage
        : "o " + texts.messages.singleTypeOfMessage);
    return text;
  };

  const handleFocus = () => {
    pauseTimer();
  };

  const handleBlur = () => {
    resumeTimer();
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "70px",
        width: "100%",
        display: "flex",
        left: 0,
        padding: "20px",
        zIndex: 999,
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={(event) => handleSubmit(event, false)}
        id="demo"
        style={{ width: "100%", maxWidth: "700px" }}
      >
        <FormControl>
          {messageStatus === "failure" && (
            <FormHelperText
              sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
            >
              {te("genericError")}
            </FormHelperText>
          )}
          {emptyTypeDataError && (
            <FormHelperText
              sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
            >
              {te("emptyTypeError")}
            </FormHelperText>
          )}

          <TextArea
            sx={{
              width: "100%",
              minHeight: "70px",
              padding: "21px 78px 21px 36px",
              position: "relative",
              ".MuiTextarea-textarea": {
                marginRight: "16px",
                paddingRight: "0px",
              },
            }}
            placeholder={placeholderTextArea()}
            type="test"
            required
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            error={messageStatus === "failure"}
            size="lg"
            onFocus={handleFocus}
            onBlur={handleBlur}
          ></TextArea>

          <EmailIcon
            color="#73738C"
            sx={{
              position: "absolute",
              left: "10px",
              top:
                emptyTypeDataError || messageStatus === "failure"
                  ? "44px"
                  : "22px",
              color: "#73738C",
            }}
          />

          <Button
            sx={{
              position: "absolute",
              right: "10px",
              top:
                emptyTypeDataError || messageStatus === "failure"
                  ? "60%"
                  : "50%",
              transform: "translate(0%, -50%)",
            }}
            variant="solid"
            color="primary"
            size="md"
            loading={messageStatus === "loading"}
            type="submit"
          >
            {t("sendMessageButton")}
          </Button>
        </FormControl>
      </form>
    </Box>
  );
}
