import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { getEventData } from "../../api/eventApi";

export function useEvent() {
  const query = useQuery();
  const [eventData, setEventData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPrintingEvent, setIsPrintingEvent] = useState(
    JSON.parse(localStorage.getItem("isPrintingEvent"))
  );

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoaded(false);
    getEvent();
  }, [query]);

  const getEvent = async () => {
    const eventId = query.get("event");
    !eventId && navigate("/");

    try {
      const eventResponse = await getEventData(eventId);

      localStorage.setItem("isDemo", eventResponse.isDemo);
      localStorage.setItem("isPrintingEvent", !!eventResponse.printsLimit);
      // Update state
      setEventData(eventResponse);
      setIsLoaded(true);
      setIsPrintingEvent(!!eventResponse.printsLimit);
    } catch (error) {
      navigate("/");
      setIsLoaded(true);
    }
  };

  return [eventData, isPrintingEvent, getEvent, isLoaded];
}
