import React from "react";
import { Typography } from "@mui/joy";
import useNetwork from "../../hooks/useNetwork";
import { styles } from "../../config/index";
import { useTranslation } from "react-i18next";

const NetworkWrapper = ({ children }) => {
  const { networkState } = useNetwork();
  const { t: te } = useTranslation("Errors");

  if (networkState?.online) {
    return <>{children}</>;
  }

  return (
    <div
      style={{
        height: "45vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography level="h6" style={{ textAlign: "center" }}>
        {te("networkError")}
        <br />
        <span style={{ fontWeight: "800", color: styles.primaryColor }}>
          {te("networkError2")}
        </span>
      </Typography>
    </div>
  );
};

export default NetworkWrapper;
