import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import AdminLoginForm from "../components/AdminLoginForm";
import PageHeader from "../components/PageHeader";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AdminLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('AdminLogin');

  return (
    <>
      <CssBaseline />
      <Container
        sx={{ height: "calc(100% - 50px)", width: "100%", position: "static" }}
      >
        <PageHeader title={t("loginTitle")} />
        <AdminLoginForm />
      </Container>
    </>
  );
};

export default AdminLogin;
