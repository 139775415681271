export default {
  userNavigation: {
    firstOption: "Galerie",
    secondOption: "Urări",
    thirdOption: "Detalii",
    forthOption: "Evenimente",
    fifthOption: "Sugestii",
  },
  messages: {
    singleTypeOfMessage: "urare",
    addMessage: "Scrie o urare",
    multipleTypesOfMessage: "urări",
    genderTypeOfMessage: "female",
  },
  tutorialModal: {
    step1title: "Distribuie codul QR",
    step2title: "Galerie și Urări",
    step3title: "Bucură-te de moment!",
    step1text:
      "Distribuie cardurile cu cod QR astfel încât acestea să fie la vedere și ușor de scanat de către invitați.",
    step2text:
      "Colectați fotografii, clipuri și urări în timpul evenimentului.",
    step3text:
      "Elementele încărcate de utilizatori apar direct în albumul digital din Cloud of Memories, de unde le veți descărca după eveniment.",
  },
  tutorialGuestsModal: {
    step1title: "Bine te-am găsit",
    step2title: "Surprinde momente",
    step1text:
      "Împarte cu noi momentele tale preferate de la evenimentul nostru.",
    step2text:
      "Momente de la masă🍽️, Poză de grup📸, Poze cu noi📷💑, Cel mai tare dans💃🕺, Tortul🎂, Primul dans👫💃, Un selfie cu tine🤳👩‍❤️‍💋‍👨",
  },
  sugestionsGuests: {
    sugestion1title: "Un selfie cu tine 🤳👩‍❤️‍💋‍👨",
    sugestion2title: "Primul dans👫💃",
    sugestion3title: "Tortul🎂",
    sugestion4title: "Cel mai tare dans💃",
    sugestion5title: "Fotografie de cuplu📷💑",
    sugestion6title: "Poză de grup📸",
    sugestion7title: "Momente de la masă🍽️",
    sugestion1subTitle:
      "Se face prezența! Încarcă un selfie!",

    sugestion2subTitle:
      "Surprindeți în imagini momentul magic al primului dans!",

    sugestion3subTitle:
      "Surprinde într-o fotografie momentul special al tăierii tortului!",

    sugestion4subTitle:
      "Imortalizează cele mai extravagante dansuri pe care le-ai observat!",

    sugestion5subTitle: "Surprinde un moment de bucurie împreună cu jumătatea ta!",
    sugestion6subTitle:
      "Gașca ta e cea mai tare! Faceți o poză de grup!",
    sugestion7subTitle: "Surprinde momente frumoase de la masa festivă!",
  },
  demoMessages: [
    {
      text: "Să înceapă cel mai tare party! Să strângem cât mai multe amintiri!",
      user: { displayName: "Daria Sturza" },
    },
    {
      text: "Vă doresc toate cele bune tuturor! Bucurați-vă de moment!",
      user: { displayName: "lonut Dumitrescu" },
    },
    {
      text: "Abia aștept să văd ce amintiri o să strângem aici! La mulți ani!",
      user: { displayName: "Valentina Elena Negru" },
    },
    {
      text: "La mulți ani! P.S: Să vedeți ce poze tari am încărcat!",
      user: { displayName: "Radu Toma" },
    },
  ],
  registerForm: {
    titluCardPlaceholder: "Ex.: Graduation party / 18th Birthday Party",
    textCardPlaceholder:
      "Ex.: Scanează și încarcă cele mai tari momente de la acest eveniment!",
  },
};
