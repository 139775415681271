import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button/Button";
import { styles } from "../../config/index";

const RemindMeLaterModal = ({
  modalRemindMeLater,
  setModalRemindMeLater,
  firstTimeEmail,
  setFirstTimeEmail,
  remindMeLater,
}) => {
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleRemindMeLater = () => {
    if (validateEmail(firstTimeEmail)) {
      setEmailError(false);
      remindMeLater(true, setErrorMessage);
    } else {
      setEmailError(true);
      setErrorMessage("Emailul nu este valid!");
    }
  };

  return (
    <Modal
      aria-labelledby="modal-remind-me-later"
      aria-describedby="modal-remind-me-later-email"
      onClose={() => setModalRemindMeLater(false)}
      open={modalRemindMeLater}
    >
      <ModalDialog sx={{ width: 450, bgcolor: "background.paper" }}>
        <ModalClose
          sx={{
            width: 20,
            height: 20,
            color: "black!important",
            zIndex: 9999,
          }}
        />

        <FormControl sx={{ mb: 2 }}>
          <Typography level="h4">Amintește-mi mai târziu</Typography>
          <Typography
            level="body2"
            textColor={styles.primaryColor}
            textAlign={"left"}
            mb={1}
          >
            Introdu email-ul tău și noi îți vom trimite un mesaj a doua zi ca să
            poți adauga momentele tale de la acest eveniment!
          </Typography>
          <FormLabel>Email *</FormLabel>
          <Input
            fullWidth
            label="Email"
            name="email"
            value={firstTimeEmail}
            onChange={(event) => {
              setFirstTimeEmail(event.target.value);
              if (emailError) setEmailError(false);
              if (errorMessage) setErrorMessage("");
            }}
            variant="outlined"
            margin="normal"
            placeholder={"example@email." + import.meta.env.VITE_REGION}
            error={emailError}
            sx={{ borderColor: emailError ? "red" : "inherit" }}
          />
          {errorMessage && (
            <Typography color="error" sx={{ mt: 1, color: "red" }}>
              {errorMessage}
            </Typography>
          )}
        </FormControl>
        <Button variant="contained" onClick={handleRemindMeLater}>
          Amintește-mi mai târziu
        </Button>
      </ModalDialog>
    </Modal>
  );
};

export default RemindMeLaterModal;
