import React, { useState } from "react";

import Container from "@mui/material/Container";
import PageHeader from "../PageHeader";
import { useEvent } from "../../hooks/useEvent";
import ChangeEventImageModal from "../eventPage/ChangeEventImageModal";
import EventHeader from "../eventPage/EventHeader";
import { shouldRenderDownloadGalleryCard } from "../../utils/helperFunctions";
import DownloadCard from "./DownloadCard";

const AppPageHeader = ({
  renderDownload,
  isUserAdmin,
  pageHeaderProps,
  showImageHeader = true,
}) => {
  const [eventData, isPrintingEvent] = useEvent();
  const [eventImage, setEventImage] = useState(null);
  const [modalEventImageOpen, setModalEventImageOpen] = useState(false);

  return (
    <>
      <Container sx={{ height: "calc(100% - 50px)", overflow: "hidden" }}>
        <PageHeader {...pageHeaderProps} />
        {eventData && (
          <>
            {showImageHeader && (
              <EventHeader
                eventData={eventData}
                isUserAdmin={isUserAdmin}
                setEventImage={setEventImage}
                setModalEventImageOpen={setModalEventImageOpen}
              />
            )}
            {renderDownload &&
              shouldRenderDownloadGalleryCard(eventData, isUserAdmin) && (
                <DownloadCard event={eventData} />
              )}
          </>
        )}
        <ChangeEventImageModal
          modalEventImageOpen={modalEventImageOpen}
          setModalEventImageOpen={setModalEventImageOpen}
          eventImage={eventImage}
          eventData={eventData}
        />
      </Container>
    </>
  );
};

export default AppPageHeader;
