// ** React Imports
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorageToken } from "../../utils/helpers";

// ** MUI IMPORTS
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card/Card";
import Typography from "@mui/joy/Typography/Typography";

const AdminCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("EventPage");

  const isUserAdmin = !!getLocalStorageToken();

  if (isUserAdmin) return null;

  return (
    <Card
      variant="outlined"
      component="li"
      mb={0.5}
      sx={{ width: "100%", marginTop: "10px" }}
    >
      <Typography
        level="h4"
        fontSize="lg"
        id="card-description"
        sx={{ textAlign: "left" }}
      >
        {t("YouAdmin")}
      </Typography>
      <Button
        variant={"outlined"}
        onClick={() => {
          navigate("/admin-login");
        }}
      >
        {t("YouAdminButton")}
      </Button>
    </Card>
  );
};

export default AdminCard;
