import axios from "axios";
import { getLocalStorageToken } from "../src/utils/helpers";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const validateEventById = async (eventId) => {
  const response = await axios.get(
    `${BASE_URL}/api/events/validate/${eventId}`
  );
  return response.data;
};

export const getGuestUserPhotos = async (eventId, userId) => {
  const response = await axios.get(
    BASE_URL + "/api/images/event/" + eventId + "/user-guest/" + userId
  );

  // Reverse to have them last in first out
  return response.data.reverse();
};

export const getAllImages = async (eventId, userId, page) => {
  const token = getLocalStorageToken();

  const config = token
    ? {
      headers: {
        Authorization: token,
      },
    }
    : {};

  try {
    const response = await axios.get(
      `${BASE_URL}/api/images/event/${eventId}${!token ? "/user/" + userId : ""
      }?page=${page}`,
      config
    );
    const images = response.data.images;
    if (!Array.isArray(images)) {
      throw "Images missing";
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventData = async (eventId) => {
  try {
    const response = await axios.get(BASE_URL + "/api/events/" + eventId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const startDownload = async (eventId) => {
  const token = getLocalStorageToken();

  try {
    const response = await axios.get(
      BASE_URL + "/api/archive/start/event/" + eventId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getArchiveStatus = async (eventId) => {
  try {
    const response = await axios.get(
      BASE_URL + "/api/archive/status/event/" + eventId
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDateSortedEvents = async (userId) => {
  try {
    const { data: events } = await axios.get(
      `${BASE_URL}/api/events/user/${userId}`
    );
    const sortedEvents = events.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB; // Change to 'dateB - dateA' for descending order
    });

    return sortedEvents;
  } catch (error) {
    throw error;
  }
};

export const archiveLink = (eventId) => {
  return BASE_URL + "/api/archive/event/" + eventId + "/media";
};

export const updateEventOnUser = async (eventId, userId) => {
  try {
    const { data: userData } = await axios.put(`${BASE_URL}/api/users`, {
      eventId,
      id: userId,
    });
    return userData;
  } catch (error) {
    throw error;
  }
};

export const createNewEvent = async (payload, token) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/events`, payload, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  } catch (error) {
    throw error;
  }
};
