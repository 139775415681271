import axios from "axios";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

const createUser = async (payload) => {
  const response = await axios.post(`${BASE_URL}/api/users`, payload);
  return response.data;
};

export const createGuestUser = async (eventData, displayName) => {
  const currentDate = new Date();
  const payload = {
    displayName: displayName ? displayName : "Anonim" + currentDate.getTime(),
    eventId: eventData._id,
    isDemoUser: eventData.demoId ? true : false,
  };
  try {
    const user = await createUser(payload);

    localStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("isTutorialDone", false);
    localStorage.removeItem("token");
    return user;
  } catch (error) {
    throw error;
  }
};

export const getUserUsedEmail = async (email, paymentId) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/users/verifyEmailAndPayment?email=${email}&ptk=${paymentId}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const createNewAdminAccount = async (payload) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/users/admin`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const subscribeNewsLetter = async (eventId, payload) => {
  try {
    await axios.post(`${BASE_URL}/api/users/subscriber/${eventId}`, payload);

    return data;
  } catch (error) {
    console.log("Failed to add subscriber:", error);
  }
};

export const adminLogin = async (payload) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/users/admin/login`,
      payload
    );

    const { token, user } = data;

    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    return data;
  } catch (error) {
    throw error;
  }
};
