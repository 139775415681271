// React Imports
import * as React from "react";

// ** Mui Imports
import Box from "@mui/joy/Box";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import Button from "@mui/joy/Button";

import { useTranslation } from "react-i18next";

import {
  archiveLink,
  getArchiveStatus,
  startDownload,
} from "../../../api/eventApi";
import { useState } from "react";
import { useEffect } from "react";
import { formatDateString } from "../../utils/helpers";

const DownloadCard = ({ event }) => {
  const { t } = useTranslation("EventPage");

  const [archiveStatus, setArchiveStatus] = useState();

  const getArchiveInfo = async () => {
    const archiveInfo = await getArchiveStatus(event._id);
    if (archiveInfo._id) {
      setArchiveStatus(archiveInfo);
    }
  };

  const generateArchive = async () => {
    await startDownload(event._id);
    getArchiveInfo();
  };

  useEffect(() => {
    getArchiveInfo();
  }, [event]);

  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        width: "100%",
        marginTop: "10px",
        flexWrap: "wrap",
        zIndex: 0,
      }}
    >
      <CardContent>
        {archiveStatus?.status === "completed" && (
          <>
            <Box>
              <Typography level="h2" fontSize="lg" textAlign="left">
                {t("downloadGallery")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{ textAlign: "start", width: "100%" }}
                fontSize="sm"
                aria-describedby="card-description"
                mb={1}
                level="body2"
              >
                {t("downloadGalleryInfo")}
              </Typography>
              <Typography
                sx={{ textAlign: "start", width: "100%" }}
                fontSize="sm"
                aria-describedby="card-description"
                mb={2}
                level="body2"
              >
                {t("downloadGalleryTime")}{" "}
                <b>{formatDateString(archiveStatus.createdAt)}</b>
              </Typography>

              <a
                href={archiveLink(event._id)}
                download="media"
                style={{ color: "white" }}
              >
                <Button
                  sx={{ width: "100%", marginTop: "10px" }}
                  variant="solid"
                  color="primary"
                  size="sm"
                >
                  {t("downloadGallery")}
                </Button>
              </a>
            </Box>
          </>
        )}
        <Box>
          <Typography
            level="h2"
            fontSize="lg"
            textAlign="left"
            marginTop={"10px"}
          >
            {t("generateGallery")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{ textAlign: "start", width: "100%" }}
            fontSize="sm"
            aria-describedby="card-description"
            mb={2}
            level="body2"
          >
            {t("generateGalleryInfo")}
          </Typography>
          {archiveStatus?.status === "pending" ? (
            <Typography
              sx={{
                textAlign: "start",
                width: "100%",
                fontWeight: "bold",
                color: "#e7007e",
              }}
              fontSize="md"
            >
              {t("generateArchiveLoading")}
            </Typography>
          ) : (
            <Button
              sx={{ width: "100%", marginTop: "10px" }}
              variant={archiveStatus?.status === "completed" ? "soft" : "solid"}
              color={"primary"}
              size="sm"
              disabled={event.nrOfImages === 0 && event.isDemo === false}
              onClick={() => {
                generateArchive();
              }}
            >
              {event.nrOfImages === 0 && event.isDemo === false
                ? t("noFilesUploaded")
                : archiveStatus?.status === "completed"
                ? t("generateButtonAgain")
                : t("generateButton")}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default DownloadCard;
