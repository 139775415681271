// ** React imports
import { useState, useEffect } from "react";

const ErrorsObserver = (function () {
  let instance;

  function createInstance() {
    let errorType = "";
    let listeners = [];

    function setErrorType(message) {
      errorType = message;
      notifyListeners();
    }

    function addListener(listener) {
      listeners.push(listener);
    }

    function removeListener(listener) {
      listeners = listeners.filter((l) => l !== listener);
    }

    function notifyListeners() {
      listeners.forEach((listener) => listener(errorType));
    }

    return {
      setErrorType,
      addListener,
      removeListener,
      getErrorMessage: () => errorType,
    };
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

const useEventBlock = () => {
  const ErrorsInstance = ErrorsObserver.getInstance();
  const [errorType, setErrorType] = useState("");

  useEffect(() => {
    ErrorsInstance.addListener(setErrorType);

    setErrorType(ErrorsInstance.getErrorMessage());

    return () => {
      ErrorsInstance.removeListener(setErrorType);
    };
  }, [ErrorsInstance]);

  return [errorType, ErrorsInstance.setErrorType];
};

export default useEventBlock;
