// ** React Imports
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocalStorageToken } from "../utils/helpers";

// ** Mui Imports
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Alert,
} from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";

// Component imports
import InvitationCard from "../components/common/InvitationCard";
import PageHeader from "../components/PageHeader";
import InvitationCardForm from "../components/InvitationCardForm";
import AdminCard from "../components/common/AdminCard";

// ** Hooks Imports
import useCatalog from "../hooks/useCatalog";
import useQuery from "../hooks/useQuery";

// ** Third party librarie imports
import axios from "axios";

// ** Helpers Imports
import { cardsDesignBackgroundsAndTitles } from "../utils/helpers";

const CatalogPage = () => {
  const [eventData, setEventData] = useState(null);
  const [invitationCardData, setInvitationCardData] = useState({
    cardTitle: "",
    cardText: "",
    checkBox: false,
  });

  const { catalogData, isEventDemo, refetchCatalogData } = useCatalog();
  const query = useQuery();
  const { t } = useTranslation("CatalogPage");
  const defaultCardText = t("defaultCardText");

  const isUserAdmin = !!getLocalStorageToken();

  const cardsBackgroundImagesAndTitles = (() => {
    const eventType = import.meta.env.VITE_EVENT_TYPE;
    return cardsDesignBackgroundsAndTitles[eventType];
  })();

  const shouldRenderInvitationForm = () => {
    return isUserAdmin && !!eventData;
  };

  const handleDescriptionText = (isEventDemo) => {
    const subtitleKey = isEventDemo ? "demoPageSubtitle" : "pageSubtitle";
    return t(subtitleKey);
  };

  const handleInvitiationCardButton = () => {
    const { cardTitle, cardText } = invitationCardData;
    updateEventData(cardTitle, cardText);
  };

  const updateInvitationCardData = (data) => {
    const { cardTitle, cardText, name } = data;

    setInvitationCardData((prev) => ({
      ...prev,
      checkBox: cardTitle === name && cardText === defaultCardText,
      cardTitle: cardTitle || prev.cardTitle,
      cardText: cardText || prev.cardText,
    }));
  };

  const handleInvitationCardChange = (field) => (event) => {
    const { type, value, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setInvitationCardData((prev) => {
      if (prev.checkBox === false && checked === true) {
        return {
          checkBox: true,
          cardTitle: eventData.name,
          cardText: defaultCardText,
        };
      } else if (
        prev.checkBox === true &&
        (prev.cardTitle === eventData.name ||
          prev.cardText === defaultCardText) &&
        fieldValue !== false
      ) {
        return {
          ...prev,
          checkBox: false,
          [field]: fieldValue,
        };
      } else if (
        prev.checkBox === true &&
        checked === false &&
        prev.cardTitle === eventData.name &&
        prev.cardText === defaultCardText
      ) {
        return {
          checkBox: false,
          cardTitle: "",
          cardText: "",
        };
      } else
        return {
          ...prev,
          [field]: fieldValue,
        };
    });
  };

  const getEvent = async () => {
    const url = import.meta.env.VITE_BACKEND_URL;
    const region = import.meta.env.VITE_REGION;

    const eventId = query.get("event");
    const apiUrl = url + region + "/api/events/" + eventId;

    axios
      .get(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        const { cardText, cardTitle, name } = data;

        const newCardData = {
          ...data,
          cardText: cardText || defaultCardText,
          cardTitle: cardTitle || name,
        };
        updateInvitationCardData(newCardData);
        setEventData(newCardData);
      })
      .catch((err) => {
        console.log("THE ERROR IS:", err);
      });
  };

  const updateEventData = (cardTitle, cardText) => {
    const url = import.meta.env.VITE_BACKEND_URL;
    const region = import.meta.env.VITE_REGION;

    const token = getLocalStorageToken();
    const apiUrl = url + region + "/api/events/" + eventData._id;

    axios
      .put(
        apiUrl,
        {
          cardTitle,
          cardText,
        },
        token && { headers: { Authorization: token } }
      )
      .then((data) => {
        const { data: eventData } = data;

        updateInvitationCardData(eventData);

        refetchCatalogData();
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("token");
          refetchCatalogData();
        }
        console.error(err);
      });
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <>
      <CssBaseline />
      {catalogData && (
        <Container
          sx={{
            height: "calc(100% - 50px)",
            overflow: "hidden",
          }}
        >
          <PageHeader
            title={t("pageTitle")}
            body={handleDescriptionText(isEventDemo)}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Alert
              icon={<AnnouncementIcon fontSize="inherit" />}
              severity="warning"
            >
              {t("informationText")}
            </Alert>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "calc(100% - 150px)",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingBottom: "30px",
            }}
          >
            {eventData && (
              <Grid container marginTop={5}>
                {cardsBackgroundImagesAndTitles.map(
                  (cardsBackgroundImageNameAndTitle, index) => (
                    <InvitationCard
                      key={index}
                      cardData={catalogData}
                      cardsBackgroundImageNameAndTitle={
                        cardsBackgroundImageNameAndTitle
                      }
                      eventData={eventData}
                    />
                  )
                )}
              </Grid>
            )}
            {shouldRenderInvitationForm() && (
              <InvitationCardForm
                invitationCardData={invitationCardData}
                handleInvitationCardChange={handleInvitationCardChange}
                handleInvitiationCardButton={handleInvitiationCardButton}
              />
            )}
            <AdminCard />
          </Box>
        </Container>
      )}
    </>
  );
};

export default CatalogPage;
