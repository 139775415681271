// ** Third party libraries imports
import axios from "axios";

// ** React Router imports
import { useLocation } from "react-router-dom";

// ** Hooks Imports
import { useTranslation } from "react-i18next";
import useThumbnail from "./useThumbnail";
import useAlerts from "./useAlerts";
import { getLocalStorageUser } from "../../utils/helpers";

const videoMaxValue = Number(import.meta.env.VITE_VIDEO_SLIDER_VALUES);
const backEndUrl = import.meta.env.VITE_BACKEND_URL;
const region = import.meta.env.VITE_REGION;

const useVideoHandler = (handleRefetch) => {
  const { setNewThumbnail, setProgressForCurrentThumbnail, removeThumbnail } =
    useThumbnail();

  const { search } = useLocation();

  const { setAlertMessage, setShowAlertTime } = useAlerts();

  const { t: te } = useTranslation("Errors");

  function extractEventId(url) {
    const match = url.match(/\?event=([a-zA-Z0-9]+)/);
    return match ? match[1] : null;
  }

  const handleVideoUpload = async (event) => {
    const eventId = extractEventId(search);

    const file = event.target.files[0];
    const thumbnailUniqueId = Math.random().toString();

    const formData = new FormData();
    const user = getLocalStorageUser();

    formData.append("user", user._id);
    formData.append("event", eventId);
    formData.append("startTime", 0);
    formData.append("endTime", videoMaxValue);
    formData.append("video", file, `trimmed_${file.name}`);

    const uploadErrorHandling = (message) => {
      setShowAlertTime(message ? 7000 : 3000);
      setAlertMessage(message || te("genericError"));
      removeThumbnail(thumbnailUniqueId);
      handleRefetch(true);
    };

    setNewThumbnail(thumbnailUniqueId);

    try {
      const CancelToken = axios.CancelToken;
      const newSource = CancelToken.source();

      await axios.post(`${backEndUrl}${region}/api/images/video`, formData, {
        cancelToken: newSource.token,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressForCurrentThumbnail(thumbnailUniqueId, percentCompleted);
        },
      });

      removeThumbnail(thumbnailUniqueId);
      handleRefetch(true);
    } catch (error) {
      if (!axios.isCancel(error)) {
        const message =
          error.response?.data?.message ??
          (typeof error.response?.data === "string"
            ? error.response.data
            : null);

        switch (message) {
          case "Over the limit of space!":
            uploadErrorHandling(te("videoErrorTooBig"));
            break;
          case "Before event started!":
            uploadErrorHandling(te("eventNotStartedError"));
            break;
          case "Already passed 90 days after event!":
            uploadErrorHandling(te("eventEndedError"));
            break;
          default:
            uploadErrorHandling();
        }
      }
    }
  };

  return { handleVideoUpload };
};

export default useVideoHandler;
