import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  finishedSuggestions: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addFinishedSuggestion: (state, action) => {
      const { eventId, suggestionName } = action.payload;
      if (!(eventId in state.finishedSuggestions)) {
        state.finishedSuggestions[eventId] = [suggestionName];
      } else {
        state.finishedSuggestions[eventId].push(suggestionName);
      }
    },
  },
});

export const { addFinishedSuggestion } = userSlice.actions;
export default userSlice.reducer;
