import { initializeApp } from "firebase/app";
import { collection, doc, getFirestore, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDoORCOTmb6Yz383cXfU0eMl0SjsE6nk5g",
  authDomain: "cloudofmemoriesprint.firebaseapp.com",
  projectId: "cloudofmemoriesprint",
  storageBucket: "cloudofmemoriesprint.appspot.com",
  messagingSenderId: "343688369808",
  appId: "1:343688369808:web:91165e171c4923f1486a15",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export const printingRef = collection(db, "prints");
