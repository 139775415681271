import { toDataURL, QRCodeToDataURLOptions } from "qrcode";

export function getColumnCount() {
  const breakpoints = [1080, 640, 384];
  const cols = [6, 4, 3, 3];
  // Get the current width of the screen
  const width = window.innerWidth;

  // Find the index of the first breakpoint that is greater than or equal to the current width
  const breakpointIndex = breakpoints.findIndex(
    (breakpoint) => width >= breakpoint
  );

  // If no breakpoint is found, use the default column count
  if (breakpointIndex === -1) {
    return cols[cols.length - 1];
  }

  // Return the corresponding number of columns based on the breakpoint index
  return cols[breakpointIndex];
}

export const getQRCode = (value) => {
  let qrValue = undefined;
  const options = {
    width: 400,
    margin: 2,
  };
  toDataURL(value, options, (err, url) => {
    if (err) {
      console.error(err);
      return;
    }
    qrValue = url;
  });

  return qrValue;
};
