import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  pauseDiscountModalTimer,
  resumeDiscountModalTimer,
} from "../reduxState/modals/modalsSlice";

const useDiscountModalTimer = () => {
  const dispatch = useDispatch();

  const pauseTimer = () => {
    dispatch(pauseDiscountModalTimer());
  };

  const resumeTimer = () => {
    dispatch(resumeDiscountModalTimer());
  };

  return { pauseTimer, resumeTimer };
};

export default useDiscountModalTimer;
