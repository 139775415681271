// ** React Imports
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// ** MUI Imports
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Checkbox from "@mui/joy/Checkbox";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Typography } from "@mui/joy";

//**  Third party libraries
import axios from "axios";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import PhotoAlbum from "react-photo-album";

// ** Components imports
import DeleteNotification from "../DeleteNotification";
import { PrintPlugin } from "./PrintButton";
import PrintGalleryButton from "./PrintGalleryButton";

// ** Utils Imports
import { generateSlide } from "../../utils/media";
import mediaAlbum from "./photos";
import { styles } from "../../config/index";
import { demoImages, getLocalStorageUser } from "../../utils/helpers";

// ** Hooks Imports
import useThumbnail from "../../hooks/mediaPage/useThumbnail";

export default function MediaGallery({
  isPrintingEvent,
  eventId,
  handleRefetch,
  setAlertMessage,
  setShowAlertTime,
  showDelete,
  isUserImages,
  loadMore,
  page,
  totalPages,
  uploadedImages,
  eventIsDemo,
}) {
  const [index, setIndex] = useState(-1);
  const [checkedPhotos, setCheckedPhotos] = useState([]);
  const [newUploadedImages, setNewUploadedImages] = useState(null);

  const { t } = useTranslation("MediaPage");
  const { t: te } = useTranslation("Errors");

  const { thumbnails, thumbnailProgress } = useThumbnail();

  const getProgressValue = (thumbnailProgress) => {
    if (!thumbnailProgress) return 0;
    if (Number.isNaN(thumbnailProgress)) return 100;
    if (thumbnailProgress < 0) return 0;
    if (thumbnailProgress > 100) return 100;
    return thumbnailProgress;
  };

  const deletePhotos = () => {
    axios
      .post(
        import.meta.env.VITE_BACKEND_URL +
          import.meta.env.VITE_REGION +
          "/api/images/delete/" +
          eventId,
        {
          images: checkedPhotos,
          user: getLocalStorageUser()._id,
        }
      )
      .then((response) => response.data)
      .then((data) => {
        handleRefetch(true);
        cancelDelete();
      })
      .catch((err) => {
        setShowAlertTime(3000);
        console.error(err);
        setAlertMessage(te("deleteError"));
      });
  };

  const cancelDelete = () => {
    setCheckedPhotos([]);
  };

  useEffect(() => {
    if (!isUserImages && page < totalPages) {
      if (thumbnails.length !== 0) {
        setNewUploadedImages([
          ...thumbnails,
          ...uploadedImages,
          { type: "button" },
        ]);
      } else setNewUploadedImages([...uploadedImages, { type: "button" }]);
    } else if (thumbnails.length !== 0) {
      if (eventIsDemo) {
        const numberOfDemoImages = demoImages.length;
        const firstSixUploadedImages = uploadedImages.slice(
          0,
          numberOfDemoImages
        );
        const remainingUploadedImages =
          uploadedImages.slice(numberOfDemoImages);

        const newImagesArray = [
          ...firstSixUploadedImages,
          ...thumbnails,
          ...remainingUploadedImages,
        ];
        setNewUploadedImages(newImagesArray);
      } else {
        setNewUploadedImages([...thumbnails, ...uploadedImages]);
      }
    } else setNewUploadedImages(uploadedImages);
  }, [uploadedImages, thumbnails]);

  useEffect(() => {
    if (index >= 0) {
      const url = window.location.href;
      window.history.pushState(null, "", url);
    }
    const handlePopState = (event) => {
      event.preventDefault();
      if (index >= 0) {
        setIndex(-1);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [index]);

  return (
    <Box className="images_background">
      {newUploadedImages ? (
        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 65px)",
            overflowY: "scroll",
            overflowX: "hidden",
            userSelect: "none",
          }}
          tabIndex="-1"
        >
          <PhotoAlbum
            layout="rows"
            onClick={({ index }) => setIndex(index)}
            photos={mediaAlbum(
              newUploadedImages,
              eventId,
              loadMore,
              showDelete
            )}
            renderPhoto={(props) => {
              const [imageIsloaded, setImageIsLoaded] = useState(false);
              const { layoutOptions, imageProps, photo } = props;
              const {
                isDemoImage,
                isThumbnail,
                photoType,
                photoId,
                showDelete,
                isLastItem,
              } = photo;

              const { src, alt, type, style, ...restImageProps } = imageProps;

              const containerStyle = {
                position: "relative",
                borderRadius: "4px",
                boxSizing: "content-box",
                alignItems: "center",
                width: style?.width,
                padding: `${layoutOptions.padding - 2}px`,
                paddingBottom: 0,
                overflow: "hidden",
              };

              const imgStyle = {
                ...style,
                objectFit: "cover",
                width: "100%",
                height: "100%",
                padding: 0,
              };

              const buttonStyle = {
                ...style,
                width: "100%",
                borderRadius: "0px",
                padding: 0,
              };

              const checkBoxStyle = {
                position: "absolute",
                bottom: "10px",
                right: "10px",
                pointerEvents: "all",

                "--joy-palette-danger-outlinedHoverBorder": styles.primaryColor,
                "--joy-palette-danger-outlinedBorder": styles.primaryColor,
                "--joy-palette-danger-outlinedHoverBg":
                  styles.infoBackgroundColor,
                "--joy-palette-danger-outlinedBg": styles.infoBackgroundColor,
                "--joy-palette-primary-outlinedHoverBorder":
                  styles.primaryColor,
                "--joy-palette-primary-outlinedColor": styles.primaryColor,
                "--joy-palette-primary-outlinedBorder": styles.primaryColor,
                "--joy-palette-primary-outlinedHoverBg":
                  styles.infoBackgroundColor,
                "--joy-palette-primary-outlinedBg": styles.infoBackgroundColor,
              };
              return (
                <div style={containerStyle} key={photoId} id={photoId}>
                  {(photoType === "image" || photoType === "video") && (
                    <img
                      loading="lazy"
                      src={src}
                      alt={alt}
                      style={imgStyle}
                      {...restImageProps}
                      onLoad={() => {
                        setImageIsLoaded(true);
                      }}
                      {...restImageProps}
                    />
                  )}
                  {photoType === "button" && (
                    <Button
                      style={buttonStyle}
                      onClick={() => {
                        loadMore();
                      }}
                    >
                      <Typography level="p">{t("seeMoreButton")}</Typography>
                    </Button>
                  )}
                  {photoType === "video" && (
                    <Box
                      sx={{
                        pointerEvents: "none",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        padding: 0,
                      }}
                    >
                      <PlayArrowIcon
                        sx={{
                          backgroundColor: "rgba(0,0,0,0.5)",

                          transform: "translate(-50%,-50%)",
                          color: styles.primaryColor,
                          fontSize: "40px",
                          borderRadius: "50%",
                          textShadow: "2px 2px 2px black",
                        }}
                      ></PlayArrowIcon>
                    </Box>
                  )}
                  {isThumbnail && (
                    <Box
                      sx={{
                        pointerEvents: "none",
                        position: "absolute",
                        top: `calc(50% - 32px)`,
                        left: `calc(50% - 27px)`,
                        padding: 0,
                      }}
                    >
                      <Box
                        sx={{ position: "relative", display: "inline-flex" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={getProgressValue(thumbnailProgress[photoId])}
                          size={55}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                          >
                            {getProgressValue(thumbnailProgress[photoId]) + "%"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {isPrintingEvent && (
                    <PrintGalleryButton
                      openGallery={() => {
                        uploadedImages.length >= 0 && setIndex(0);
                      }}
                    />
                  )}

                  {showDelete &&
                    photoType !== "button" &&
                    !isDemoImage &&
                    !isThumbnail &&
                    imageIsloaded && (
                      <Checkbox
                        sx={checkBoxStyle}
                        checked={checkedPhotos.includes(photoId)}
                        color={"primary"}
                        orientation="vertical"
                        size="lg"
                        variant="outlined"
                        onChange={() => {
                          if (checkedPhotos.includes(photoId)) {
                            setCheckedPhotos((prevIds) =>
                              prevIds.filter((id) => id !== photoId)
                            );
                          } else {
                            setCheckedPhotos((prevIds) => [
                              ...prevIds,
                              photoId,
                            ]);
                          }
                        }}
                      />
                    )}
                </div>
              );
            }}
          />

          <Lightbox
            slides={uploadedImages.map((image) =>
              generateSlide(eventId, image)
            )}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            eventId={eventId}
            isPrintingEvent={isPrintingEvent}
            plugins={[Fullscreen, Thumbnails, Video, Download, PrintPlugin]}
          />

          <DeleteNotification
            nrOfPhotos={checkedPhotos.length}
            handleCancel={cancelDelete}
            handleConfirm={deletePhotos}
          />
        </Box>
      ) : (
        <>Loading</>
      )}
    </Box>
  );
}
