import Logo from "../../assets/logo.svg";
import SoloLogo from "../../assets/solologo.svg";
import TutorialStep1 from "../../assets/Poza_1_Family.png";
import TutorialStep2 from "../../assets/Poza_2_Family.png";
import TutorialStep3 from "../../assets/Poza_3_Family.png";

import DefaultEventImage from "../../assets/familyDefaultEvent.jpg";


const logoImages = {
  Logo,
  SoloLogo,
};

const tutorialStepsImages = {
  TutorialStep1,
  TutorialStep2,
  TutorialStep3,
};

const eventImages = {
    DefaultEventImage,
  };

export default { logoImages, tutorialStepsImages, eventImages };
