import { useEffect, useRef, useState } from "react";
import useQuery from "../hooks/useQuery";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Tabs from "@mui/joy/Tabs";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { TabList, Typography } from "@mui/joy";
import { texts } from "../config/index";

import MonochromePhotosRoundedIcon from "@mui/icons-material/MonochromePhotosRounded";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import { useEvent } from "../hooks/useEvent";
import { getLocalStorageUser } from "../utils/helpers";

const NavigationWrapper = ({ children }) => {
  const [, isPrintingEvent] = useEvent();

  const [currentIndex, setCurrentIndex] = useState();
  // TODO: Change this to something better xD
  const userData = useRef();
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  userData.current = getLocalStorageUser();

  const routes = (() => {
    const eventId = query.get("event");

    const renderSuggestionPage = (() => {
      const eventType = import.meta.env.VITE_EVENT_TYPE;
      return (
        !isPrintingEvent &&
        (eventType === "family" || eventType === "vacation") &&
        userData.current?._id !== userData.current?.event?.user
      );
    })();

    const isUserMultievent =
      userData.current && userData.current.isMultiEventUser;

    return [
      {
        link: `/media?event=${eventId}`,
        color: "primary",
        icon: <MonochromePhotosRoundedIcon />,
        text: texts.userNavigation.firstOption,
      },
      {
        link: `/messages?event=${eventId}`,
        color: "warning",
        icon: <ArticleOutlinedIcon />,
        text: texts.userNavigation.secondOption,
      },
      ...(renderSuggestionPage
        ? [
            {
              link: `/sugestions?event=${eventId}`,
              color: "success",
              icon: <AssignmentTurnedInOutlinedIcon />,
              text: texts.userNavigation.fifthOption,
            },
          ]
        : []),
      {
        link: `/event?event=${eventId}`,
        color: "danger",
        icon: <CelebrationRoundedIcon />,
        text: texts.userNavigation.thirdOption,
      },
      ...(isUserMultievent
        ? [
            {
              link: `/dashboard?event=${eventId}`,
              color: "danger",
              icon: <CalendarMonthRoundedIcon />,
              text: texts.userNavigation.forthOption,
            },
          ]
        : []),
    ];
  })();

  useEffect(() => {
    const event = query.get("event");
    if (!event || event === "null") {
      navigate("/");
    }
  }, [navigate, query.get("event")]);

  useEffect(() => {
    const locationIndex = routes.findIndex((route) =>
      route.link.includes(location.pathname)
    );
    setCurrentIndex(locationIndex);
  }, [location]);

  return (
    <>
      {children}
      {userData.current && (
        <Box sx={{ position: "fixed", bottom: "10px", left: 0, width: "100%" }}>
          <Box paddingX={"5px"}>
            {currentIndex > -1 && (
              <Tabs
                size="sm"
                aria-label="Bottom Navigation"
                value={currentIndex}
                // value={1}
                onChange={(e, value) => {
                  setCurrentIndex(value);
                }}
                sx={(theme) => ({
                  borderRadius: "md",
                  maxWidth: 400,
                  mx: "auto",
                  boxShadow: theme.shadow.md,
                  "--Tabs-gap": "6px",
                  "--joy-shadowChannel":
                    "var(--joy-palette-info-darkChannel, 48 23 97)",
                  [`& .${tabClasses.root}`]: {
                    boxShadow: "none",
                    borderRadius: "md",
                    whiteSpace: "nowrap",
                    transition: "0.3s",
                    fontWeight: "xl",
                    flex: 1,
                    [`&:not(.${tabClasses.selected}):not(:hover)`]: {
                      opacity: 0.85,
                    },
                  },
                })}
              >
                <TabList
                  variant="plain"
                  sx={{ "--ListItemDecorator-size": "28px" }}
                >
                  {routes.map((route, index) => (
                    <Tab
                      orientation="vertical"
                      disableIndicator
                      component={Link}
                      tabIndex={index}
                      key={"navigation_" + index}
                      to={route.link}
                      {...(currentIndex === index && {
                        variant: "outlined",
                        color: route.color,
                      })}
                      onClick={() => {
                        setCurrentIndex(index);
                      }}
                    >
                      <ListItemDecorator>{route.icon}</ListItemDecorator>
                      <Typography
                        level="body3"
                        fontSize={"0.8rem"}
                        color={"black"}
                      >
                        {route.text}
                      </Typography>
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default NavigationWrapper;
