export default {
  userNavigation: {
    firstOption: "Galerie",
    secondOption: "Urări",
    thirdOption: "Detalii",
    forthOption: "Evenimente",
    fifthOption: "Sugestii",
  },
  messages: {
    singleTypeOfMessage: "urare",
    addMessage: "Scrie o urare",
    multipleTypesOfMessage: "urări",
    genderTypeOfMessage: "female",
  },
  tutorialModal: {
    step1title: "Distribuie codul QR",
    step2title: "Galerie și Urări",
    step3title: "Bucură-te de moment!",
    step1text:
      "Distribuie cardurile cu cod QR astfel încât acestea să fie la vedere și ușor de scanat de către invitați.",
    step2text:
      "Primești fotografii, clipuri și urări de la invitați în timpul evenimentului.",
    step3text:
      "Elementele încărcate de utilizatori apar direct în albumul digital din Cloud of Memories, de unde le vei descărca după eveniment.",
  },
  tutorialGuestsModal: {
    step1title: "Bine te-am găsit",
    step2title: "Surprinde momente",
    printTitle: "Adaugă poze, Printează!",
    step1text:
      "Împarte cu noi momentele tale preferate de la evenimentul nostru.",
    printText:
      "Adaugă momentele tale favorite de la eveniment si printează-le!",
    step2text:
      "Momente de la masă🍽️, Poză de grup📸, Poze cu noi📷💑, Cel mai tare dans💃🕺, Tortul🎂, Primul dans👫💃, Un selfie cu tine🤳👩‍❤️‍💋‍👨",
  },
  sugestionsGuests: {
    sugestion1title: "O poză cu tine 🤳👩‍❤️‍💋‍👨",
    sugestion2title: "Primul dans👫💃",
    sugestion3title: "Tortul🎂",
    sugestion4title: "Cel mai tare dans💃",
    sugestion5title: "Fotografie de cuplu📷💑",
    sugestion6title: "Poză de grup📸",
    sugestion7title: "Momente de la masă🍽️",
    sugestion1subTitle: "Se face prezența! Încarcă o poză cu tine!",

    sugestion2subTitle:
      "Surprindeți în imagini momentul magic al primului dans!",

    sugestion3subTitle:
      "Surprinde într-o fotografie momentul special al tăierii tortului!",

    sugestion4subTitle:
      "Imortalizează cele mai extravagante dansuri pe care le-ai observat!",

    sugestion5subTitle:
      "Surprinde un moment de bucurie împreună cu jumătatea ta!",
    sugestion6subTitle: "Gașca ta e cea mai tare! Faceți o poză de grup!",
    sugestion7subTitle: "Surprinde momente frumoase de la masa festivă!",
  },
  demoMessages: [
    {
      text: "De ziua voastră specială, vă dorim o călătorie a vieții plină de iubire, fericire și înțelegere. Să vă bucurați de fiecare pas al călătoriei voastre împreună și să vă sprijiniți mereu unul pe altul. Felicitări pentru nunta voastra!",
      user: { displayName: "Daria Sturza" },
    },
    {
      text: "Astăzi ne-am adunat aici pentru a sărbători iubirea voastră şi angajamentul vostru unul față de celălalt. Vă dorim ca această călătorie a căsătoriei să vă aducă zile senine, momente de neuitat şi multe bucurii. Felicitări şi toate cele bune în căsnicia voastra!",
      user: { displayName: "lonut Dumitrescu" },
    },
    {
      text: "La începutul acestei noi etape a vieții voastre, vă urăm să vă bucurați de fiecare moment special pe care îl veţi trăi împreună. Fie ca iubirea voastră să crească în fiecare zi și să vă însenineze viețile. Vă felicităm pentru căsătoria voastră și vă dorim tot binele din lume!",
      user: { displayName: "Valentina Elena Negru" },
    },
    {
      text: "La începutul acestei călătorii frumoase numită căsnicie, vă dorim multă dragoste, fericire şi împliniri. Să aveți mereu încredere unul în celălalt și să treceți prin toate provocările cu răbdare și înțelepciune. Felicitări pentru nunta voastră și toate cele bune în viața voastră împreună!",
      user: { displayName: "Radu Toma" },
    },
  ],
  registerForm: {
    titluCardPlaceholder: "Ex.: Mihai & Ioana",
    textCardPlaceholder:
      "Ex.: Scanează și împarte cu noi momentele tale preferate de la evenimentul nostru.",
  },
};
