import Logo from "../../assets/logoCorporate.svg";
import SoloLogo from "../../assets/solologoCorporate.svg";
import TutorialStep1 from "../../assets/Poza_1_Corporate.png";
import TutorialStep2 from "../../assets/Poza_2_Corporate.png";
import TutorialStep3 from "../../assets/Poza_3_Corporate.png";
import DefaultEventImage from "../../assets/corporateDefaultEvent.png";

const logoImages = {
  Logo,
  SoloLogo,
};

const tutorialStepsImages = {
  TutorialStep1,
  TutorialStep2,
  TutorialStep3,
};

const eventImages = {
  DefaultEventImage,
};

export default { logoImages, tutorialStepsImages, eventImages };
