import {
  Button,
  Card,
  CardContent,
  FormControl,
  Input,
  Typography,
} from "@mui/joy";
import React, { useState } from "react";
import { styles, texts } from "../../config/index";
import { useTranslation } from "react-i18next";

const EventUser = ({
  userData,
  eventData,
  isUserAdmin,
  updateUserDisplayName,
  query,
  remindMeLater,
  navigate,
}) => {
  const [firstTimeName, setFirstTimeName] = useState("");

  const { t } = useTranslation("EventPage");
  const { t: te } = useTranslation("Errors");

  return (
    <>
      {((isUserAdmin !== null && !isUserAdmin) ||
        userData?._id == "64b7ca4d8565dfcd3d2cdeca" ||
        !eventData?.isDemo) && (
        <>
          {!userData?.displayName?.includes("Anonim") && !isUserAdmin && (
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <CardContent
                sx={{
                  width: "250px",
                  bgcolor: "background.body",
                  alignItems: "flex-center",
                  zIndex: 0,
                }}
              >
                <Typography
                  level="h5"
                  id="card-description"
                  sx={{ color: styles.primaryColor }}
                >
                  {userData?.displayName}
                </Typography>
              </CardContent>
            </Card>
          )}
          {!isUserAdmin && (
            <Card
              variant="outlined"
              component="li"
              mb={0.5}
              sx={{ width: "100%" }}
            >
              <Button
                variant={"solid"}
                onClick={() => {
                  navigate("/media?event=" + query.get("event"));
                }}
              >
                {t("addMomentsButton")}
              </Button>
              <Button
                variant={"solid"}
                onClick={() => {
                  navigate("/messages?event=" + query.get("event"));
                }}
              >
                {t("addWishesButton")}{" "}
                {texts.messages.multipleTypesOfMessage.capitalize()}
              </Button>
              {/* TODO:: fix remind me later in next sprint */}
              {/* {!userData?.rmlDate &&
                userData?._id != "64b7ca4d8565dfcd3d2cdeca" && (
                  <Button
                    onClick={() => {
                      remindMeLater(false);
                    }}
                  >
                    {t("remindMeLaterButton")}
                  </Button>
                )} */}
            </Card>
          )}
          {userData?.displayName?.includes("Anonim") && (
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <CardContent
                sx={{
                  width: "250px",
                  bgcolor: "background.body",
                  alignItems: "flex-start",
                  zIndex: 0,
                }}
              >
                <Typography
                  level="h4"
                  fontSize="lg"
                  id="card-description"
                  mb={0.5}
                >
                  {t("nameAndSurnameTitle")}
                </Typography>
                <Typography
                  sx={{ textAlign: "start" }}
                  fontSize="sm"
                  aria-describedby="card-description"
                  mb={1}
                  level="body2"
                >
                  {t("nameAndSurnameDescription")}
                </Typography>
                <FormControl sx={{ width: "100%!important" }}>
                  <Input
                    sx={{ width: "100%!important" }}
                    placeholder={t("nameAndSurnameInputLabel")}
                    type="text"
                    variant="soft"
                    value={firstTimeName}
                    onChange={(event) => {
                      setFirstTimeName(event.target.value);
                    }}
                    endDecorator={
                      <Button
                        variant={"solid"}
                        onClick={() => {
                          updateUserDisplayName(firstTimeName);
                        }}
                      >
                        {t("nameAndSurnameSaveButton")}
                      </Button>
                    }
                    size="md"
                  />
                </FormControl>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default EventUser;
