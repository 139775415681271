import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function DeleteNotification({
  nrOfPhotos,
  handleConfirm,
  handleCancel,
}) {
  const { t } = useTranslation("MediaPage");
  const { t: te } = useTranslation("Errors");

  const getFileMessage = (totalFiles) => {
    if (totalFiles === 1) {
      return t("deleteOneFile");
    }
    return `${totalFiles} ${t("deleteMultipleFiles")}`;
  };

  return (
    <>
      {nrOfPhotos > 0 && (
        <Stack
          spacing={2}
          sx={{
            maxWidth: 400,
            position: "fixed",
            zIndex: "99999",
            top: "10px",
            left: "10px",
            right: "10px",
            WebkitTransform: "translate3d(0,0,0)",
          }}
        >
          <Alert
            variant="soft"
            color="danger"
            invertedColors
            sx={{ alignItems: "flex-start", "--Alert-gap": "1rem" }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ mt: 1 }}>
                {getFileMessage(nrOfPhotos)}
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                <Button
                  variant="outlined"
                  className="formButton"
                  size="sm"
                  onClick={handleCancel}
                >
                  {t("cancelButton")}
                </Button>
                <Button
                  variant="soft"
                  className="formButton"
                  size="sm"
                  onClick={handleConfirm}
                >
                  {t("deleteButton")}
                </Button>
              </Box>
            </Box>
          </Alert>
        </Stack>
      )}
    </>
  );
}
