// ** React imports
import React, { useRef } from "react";

// ** Hooks Imports
import useVideoHandler from "../../hooks/mediaPage/useVideoHandler";

const VideoUploader = ({ videoCameraInputRef, handleRefetch }) => {
  const { handleVideoUpload } = useVideoHandler(handleRefetch);

  return (
    <>
      <input
        ref={videoCameraInputRef}
        type="file"
        accept="video/*"
        onChange={handleVideoUpload}
        style={{ display: "none" }}
      />
    </>
  );
};

export default VideoUploader;
