// ** React Imports
import React, { useEffect, useState } from "react";

// ** Muy Imports
import { Button, Modal, ModalDialog, Typography } from "@mui/joy";

// ** Translation Imports
import { useTranslation } from "react-i18next";

// ** Styles Imports
import { styles } from "../config";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  resetDiscountModalTimer,
  startDiscountModalTimer,
  toggleDiscountModalViewed,
} from "../reduxState/modals/modalsSlice";

const discountCode = import.meta.env.VITE_DISCOUNT_CODE;
const eventType = import.meta.env.VITE_EVENT_TYPE;
const region = import.meta.env.VITE_REGION;

const DiscountModal = ({ discount }) => {
  const [open, setOpen] = useState(false);

  const { modalShowTimer, isTimerRunning, discountModalDelaySeconds } =
    useSelector((state) => state.modals.discountModal);
  const dispatch = useDispatch();
  const { t } = useTranslation("MediaPage");

  const handleDiscountModalViewed = () => {
    dispatch(toggleDiscountModalViewed(true));
  };

  const handleStart = () => {
    dispatch(startDiscountModalTimer());
  };

  const handleResetDiscountModalTimer = () => {
    dispatch(resetDiscountModalTimer());
  };

  const handleClose = () => {
    handleDiscountModalViewed();
    handleResetDiscountModalTimer();
    setOpen(false);
  };

  const handleRedirect = () => {
    handleDiscountModalViewed();
    handleResetDiscountModalTimer();
    window.location.href = `https://cloudofmemories.${region}/${eventType}?code=${discountCode}`;
  };

  if (Number(discountModalDelaySeconds) <= modalShowTimer) {
    if (open === false) {
      setOpen(true);
    }
  }

  useEffect(() => {
    if (!isTimerRunning && modalShowTimer === 0) handleStart();
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        style={{
          background: "white",
          padding: "50px 20px 25px 20px",
          borderRadius: "10px",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            top: -14,
            right: -14,
            p: "0px!important",
            m: 0,
            fontFamily: "monospace",
            lineHeight: 0,
            minHeight: "30px",
            height: "30px",
            width: "30px",
            backgroundColor: "black!important",
            transform: "scale(0.85)",
            fontSize: "20px",
            borderRadius: "50%!important",
          }}
          onClick={handleClose}
        >
          X
        </Button>
        <Typography
          style={{
            color: styles.primaryColor,
            fontSize: "16px",
            marginBottom: "10px",
          }}
        >
          {t("discountModalText")}
        </Typography>
        <Typography
          style={{
            color: styles.primaryColor,
            fontSize: "18px",
            marginBottom: "30px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {discount}% DISCOUNT
        </Typography>
        <Button
          variant="contained"
          style={{
            background: styles.infoColor,
            color: "#ffffff",
            marginTop: "10px",
          }}
          onClick={handleRedirect}
        >
          Vezi prețurile!
        </Button>
      </ModalDialog>
    </Modal>
  );
};

export default DiscountModal;
