// ** React Imports
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";

// ** Mui Imports
import { Box, Button } from "@mui/joy";
import ArrowForward from "@mui/icons-material/ArrowForward";

const TutoriaButtonGroup = ({ tutorialStep, nextStep, stepsLength }) => {
  const { t } = useTranslation("Buttons");
  const query = useQuery();
  const navigate = useNavigate();

  // OPEN CAMERA ON TUTORIAL FUNCTION // Maybe remove later
  // const tryNowButtonRedirect = (eventType) => {
  //   const queryEventId = query.get("event");
  //   switch (eventType) {
  //     case "corporate":
  //       localStorage.setItem("openCamera", "true");
  //       return "/media?event=" + queryEventId;
  //     default:
  //       return "/sugestions?event=" + queryEventId;
  //   }
  // };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        minWidth: "300px",
      }}
    >
      {tutorialStep === stepsLength - 1 ? (
        <>
          <Button
            sx={{
              width: "135px",
            }}
            //variant="outlined"
            onClick={() => {
              nextStep(true);
              navigate("/media?event=" + query.get("event"));
            }}
          >
            {t("seeAlbum")}
          </Button>
          {/* OPEN CAMERA BUTTON */}
          {/* <Button
            sx={{
              width: "135px",
            }}
            onClick={() => {
              nextStep(true);
              navigate(tryNowButtonRedirect(eventType));
            }}
          >
            {t("tryNow")}
          </Button> */}
        </>
      ) : (
        <Button
          sx={{
            width: "105px",
            fontFamily: "Noto Sans Mono",
            fontWeight: "400",
            fontSize: "18px",
            paddingLeft: "29px!important",
          }}
          onClick={() => {
            nextStep();
          }}
        >
          {tutorialStep + 1}/{stepsLength}
          <ArrowForward sx={{ ml: 1 }} fontSize="small"></ArrowForward>
        </Button>
      )}
    </Box>
  );
};

export default TutoriaButtonGroup;
