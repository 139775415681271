import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/joy/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { Box } from "@mui/joy";
import ImageUploader from "./ImageUploader";
import VideoUploader from "./VideoUploader";
import { useTranslation } from "react-i18next";

import { handleVisibilityChange } from "../../utils/helpers";

const AddingMedia = ({ handleRefetch, eventData, setLoadingSingleUpload }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [loading, setLoading] = useState(false);

  const imageFileInputRef = useRef(null);
  const imageCameraInputRef = useRef(null);
  const videoCameraInputRef = useRef(null);
  const containerRef = useRef(null);

  const { t } = useTranslation("MediaPage");

  const addMedia = (type) => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);

    switch (type) {
      case "video":
        videoCameraInputRef.current.click();
        break;
      case "file":
        imageFileInputRef.current.click();
        break;
      case "image":
        imageCameraInputRef.current.click();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const openCamera = localStorage.getItem("openCamera");

    if (openCamera === "true") {
      imageCameraInputRef.current.click();
      localStorage.removeItem("openCamera");
    }

    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowButtons(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        right: "30px",
        bottom: "100px",
        display: "flex",
        flexDirection: "column-reverse",
        zIndex: 800,
      }}
      ref={containerRef}
    >
      <Button
        variant="solid"
        color="primary"
        onClick={() => {
          setShowButtons((prev) => !prev);
        }}
        startDecorator={<AddCircleRoundedIcon />}
        tabIndex={-1}
      >
        {t("addButton")}
      </Button>
      {showButtons && (
        <>
          <Button
            onClick={() => addMedia("video")}
            variant="soft"
            startDecorator={<MovieRoundedIcon />}
            tabIndex={-1}
            sx={{ mb: 1 }}
          >
            {t("videoButton")}
          </Button>
          <Button
            onClick={() => addMedia("file")}
            disabled={loading}
            variant="soft"
            startDecorator={<FileCopyRoundedIcon />}
            tabIndex={-1}
            sx={{ mb: 0.5 }}
          >
            {t("galleryButton")}
          </Button>
          <Button
            onClick={() => addMedia("image")}
            disabled={loading}
            variant="soft"
            startDecorator={<CameraAltRoundedIcon />}
            tabIndex={-1}
            sx={{
              mb: 0.5,
            }}
          >
            {t("cameraButton")}
          </Button>
        </>
      )}
      <ImageUploader
        eventData={eventData}
        handleRefetch={handleRefetch}
        imageFileInputRef={imageFileInputRef}
        imageCameraInputRef={imageCameraInputRef}
        setLoadingSingleUpload={setLoadingSingleUpload}
        setLoading={setLoading}
        setShowButtons={setShowButtons}
      />
      <VideoUploader
        videoCameraInputRef={videoCameraInputRef}
        handleRefetch={handleRefetch}
      />
    </Box>
  );
};

export default AddingMedia;
