// ** React Imports
import React from "react";

// ** Third party libraries imports
import { PDFDownloadLink } from "@react-pdf/renderer";
import CardsPdfDocument from "./CardsPdfDocument";

const CardsDesignPdfGenerator = ({ pdfData, documentName }) => {
  return (
    <PDFDownloadLink
      document={<CardsPdfDocument pdfData={pdfData} />}
      fileName={`${documentName}.pdf`}
    >
      {({ loading }) =>
        loading ? (
          <button>Loading Document...</button>
        ) : (
          <button>Download</button>
        )
      }
    </PDFDownloadLink>
  );
};

export default CardsDesignPdfGenerator;
