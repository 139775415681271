import demoImageFamily1 from "../assets/demo1.jpg";
import demoImageFamily2 from "../assets/demo2.jpg";
import demoImageFamily3 from "../assets/demo3.jpg";
import demoImageFamily4 from "../assets/demo4.jpg";
import demoImageFamily5 from "../assets/demo5.jpg";
import demoImageFamily6 from "../assets/demo6.jpg";

import demoImageCorporate1 from "../assets/demo1-corporate.jpg";
import demoImageCorporate2 from "../assets/demo2-corporate.jpg";
import demoImageCorporate3 from "../assets/demo3-corporate.jpg";
import demoImageCorporate4 from "../assets/demo4-corporate.jpg";
import demoImageCorporate5 from "../assets/demo5-corporate.jpg";
import demoImageCorporate6 from "../assets/demo6-corporate.jpg";
import demoImageCorporate7 from "../assets/demo7-corporate.jpg";

import demoImageVacation1 from "../assets/demo1-vacation.jpg";
import demoImageVacation2 from "../assets/demo2-vacation.jpg";
import demoImageVacation3 from "../assets/demo3-vacation.jpg";
import demoImageVacation4 from "../assets/demo4-vacation.jpg";
import demoImageVacation5 from "../assets/demo5-vacation.jpg";
import demoImageVacation6 from "../assets/demo6-vacation.jpg";
import demoImageVacation7 from "../assets/demo7-vacation.jpg";

import demoImageCelebration1 from "../assets/demo1-celebration.jpg";
import demoImageCelebration2 from "../assets/demo2-celebration.jpg";
import demoImageCelebration3 from "../assets/demo3-celebration.jpg";
import demoImageCelebration4 from "../assets/demo4-celebration.jpg";
import demoImageCelebration5 from "../assets/demo5-celebration.jpg";
import demoImageCelebration6 from "../assets/demo6-celebration.jpg";
import demoImageCelebration7 from "../assets/demo7-celebration.jpg";

import CardImage1 from "../assets/Eucalyptus_1.png";
import CardImage2 from "../assets/Eucalyptus_2.png";
import CardImage3 from "../assets/Eucalyptus_3.png";
import CardImage4 from "../assets/Floral_Blue.png";
import CardImage5 from "../assets/Floral_Corners_Blue.png";
import CardImage6 from "../assets/Floral_Corners_Red.png";
import CardImage7 from "../assets/Floral_Peach.png";
import CardImage8 from "../assets/Floral_Purple_1.png";
import CardImage9 from "../assets/Floral_Purple_2.png";
import CardImage10 from "../assets/Floral_Red.png";
import CardImage11 from "../assets/Floral_WhiteGreen.png";
import CardImage12 from "../assets/Golden_Frames_1.png";
import CardImage13 from "../assets/Golden_Frames_2.png";
import CardImage14 from "../assets/Golden_Frames_3.png";
import CardImage15 from "../assets/Hearts_Purple_1.png";
import CardImage16 from "../assets/Hearts_Purple_2.png";
import CardImage17 from "../assets/Hearts_Gold_1.png";
import CardImage18 from "../assets/Hearts_Gold_2.png";
import CardImage19 from "../assets/Hearts_Pink_1.png";
import CardImage20 from "../assets/Hearts_Pink_2.png";
import CardImage21 from "../assets/Standard.png";
import CardImage22 from "../assets/Wedding_Ring_1.png";
import CardImage23 from "../assets/Wedding_Ring_2.png";

import printDemoImage from "../assets/print_polaroid.webp";

import { texts, images } from "../config/index";
const eventType = import.meta.env.VITE_EVENT_TYPE ?? "family";

export const demoImagesArrays = {
  family: [
    {
      _id: demoImageFamily1,
      photoSrc: demoImageFamily1,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageFamily2,
      photoSrc: demoImageFamily2,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageFamily3,
      photoSrc: demoImageFamily3,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageFamily4,
      photoSrc: demoImageFamily4,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageFamily5,
      photoSrc: demoImageFamily5,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageFamily6,
      photoSrc: demoImageFamily6,
      isDemoImage: true,
      type: "image",
    },
  ],
  corporate: [
    {
      _id: demoImageCorporate1,
      photoSrc: demoImageCorporate1,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCorporate2,
      photoSrc: demoImageCorporate2,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCorporate3,
      photoSrc: demoImageCorporate3,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCorporate4,
      photoSrc: demoImageCorporate4,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCorporate5,
      photoSrc: demoImageCorporate5,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCorporate6,
      photoSrc: demoImageCorporate6,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCorporate7,
      photoSrc: demoImageCorporate7,
      isDemoImage: true,
      type: "image",
    },
  ],
  vacation: [
    {
      _id: demoImageVacation1,
      photoSrc: demoImageVacation1,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageVacation2,
      photoSrc: demoImageVacation2,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageVacation3,
      photoSrc: demoImageVacation3,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageVacation4,
      photoSrc: demoImageVacation4,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageVacation5,
      photoSrc: demoImageVacation5,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageVacation6,
      photoSrc: demoImageVacation6,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageVacation7,
      photoSrc: demoImageVacation7,
      isDemoImage: true,
      type: "image",
    },
  ],
  celebration: [
    {
      _id: demoImageCelebration1,
      photoSrc: demoImageCelebration1,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCelebration2,
      photoSrc: demoImageCelebration2,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCelebration3,
      photoSrc: demoImageCelebration3,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCelebration4,
      photoSrc: demoImageCelebration4,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCelebration5,
      photoSrc: demoImageCelebration5,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCelebration6,
      photoSrc: demoImageCelebration6,
      isDemoImage: true,
      type: "image",
    },
    {
      _id: demoImageCelebration7,
      photoSrc: demoImageCelebration7,
      isDemoImage: true,
      type: "image",
    },
  ],
};

export const demoImages = demoImagesArrays[eventType];

export const demoTutorialSteps = [
  {
    title: texts.tutorialModal.step1title,
    text: texts.tutorialModal.step1text,
    image: images.tutorialStep1,
  },
  {
    title: texts.tutorialModal.step2title,
    text: texts.tutorialModal.step2text,
    image: images.tutorialStep2,
  },
  {
    title: texts.tutorialModal.step3title,
    text: texts.tutorialModal.step3text,
    image: images.tutorialStep3,
  },
];

export const getTutorialSteps = (eventData) => {
  return eventData.isDemo ? demoTutorialSteps : getGuestSteps(eventData);
};

export const getGuestSteps = (eventData) => {
  const { name, welcomeMessage, eventImage, printsLimit } = eventData;
  return [
    {
      title: name === "" ? texts.tutorialGuestsModal.step1title : name,
      text:
        welcomeMessage === ""
          ? texts.tutorialGuestsModal.step1text
          : welcomeMessage,
      image: eventImage ? eventImage : images.defaultEventImage,
    },
    ...(printsLimit
      ? [
          {
            title: texts.tutorialGuestsModal.printTitle,
            text: texts.tutorialGuestsModal.printText,
            image: printDemoImage,
          },
        ]
      : []),
  ];
};

export const handleVisibilityChange = () => {
  if (document.visibilityState === "visible") {
    window.location.reload();
  }
};

export const cardsDesignBackgroundsAndTitles = {
  family: [
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 1",
      bgImg: CardImage1,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 2",
      bgImg: CardImage2,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 3",
      bgImg: CardImage3,
    },
    { title: null, name: "Floral Blue", bgImg: CardImage4 },
    { title: null, name: "Floral Corners Blue", bgImg: CardImage5 },
    { title: null, name: "Floral Corners Red", bgImg: CardImage6 },
    { title: null, name: "Floral Peach", bgImg: CardImage7 },
    { title: null, name: "Floral Purple 1", bgImg: CardImage8 },
    { title: null, name: "Floral Purple 2", bgImg: CardImage9 },
    { title: null, name: "Floral Red", bgImg: CardImage10 },
    { title: null, name: "Floral White Green", bgImg: CardImage11 },
    { title: null, name: "Golden Frames 1", bgImg: CardImage12 },
    { title: null, name: "Golden Frames 2", bgImg: CardImage13 },
    { title: null, name: "Golden Frames 3", bgImg: CardImage14 },
    { title: null, name: "Heart Purple 1", bgImg: CardImage15 },
    { title: null, name: "Heart Purple 2", bgImg: CardImage16 },
    { title: null, name: "Heart Gold 1", bgImg: CardImage17 },
    { title: null, name: "Heart Gold 2", bgImg: CardImage18 },
    { title: null, name: "Hearts Pink 1", bgImg: CardImage19 },
    { title: null, name: "Hearts Pink 2", bgImg: CardImage20 },
    {
      title: "Fă parte din povestea noastră!",
      name: "Standard",
      bgImg: CardImage21,
    },
    { title: null, name: "Wedding Ring 1", bgImg: CardImage22 },
    { title: null, name: "Wedding Ring 2", bgImg: CardImage23 },
  ],
  celebration: [
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 1",
      bgImg: CardImage1,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 2",
      bgImg: CardImage2,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 3",
      bgImg: CardImage3,
    },
    { title: null, name: "Floral Blue", bgImg: CardImage4 },
    { title: null, name: "Floral Corners Blue", bgImg: CardImage5 },
    { title: null, name: "Floral Corners Red", bgImg: CardImage6 },
    { title: null, name: "Floral Peach", bgImg: CardImage7 },
    { title: null, name: "Floral Purple 1", bgImg: CardImage8 },
    { title: null, name: "Floral Purple 2", bgImg: CardImage9 },
    { title: null, name: "Floral Red", bgImg: CardImage10 },
    { title: null, name: "Floral White Green", bgImg: CardImage11 },
    { title: null, name: "Golden Frames 1", bgImg: CardImage12 },
    { title: null, name: "Golden Frames 2", bgImg: CardImage13 },
    { title: null, name: "Golden Frames 3", bgImg: CardImage14 },
    { title: null, name: "Heart Purple 1", bgImg: CardImage15 },
    { title: null, name: "Heart Purple 2", bgImg: CardImage16 },
    { title: null, name: "Heart Gold 1", bgImg: CardImage17 },
    { title: null, name: "Heart Gold 2", bgImg: CardImage18 },
    { title: null, name: "Hearts Pink 1", bgImg: CardImage19 },
    { title: null, name: "Hearts Pink 2", bgImg: CardImage20 },
    {
      title: "Fă parte din povestea noastră!",
      name: "Standard",
      bgImg: CardImage21,
    },
    { title: null, name: "Wedding Ring 1", bgImg: CardImage22 },
    { title: null, name: "Wedding Ring 2", bgImg: CardImage23 },
  ],
  vacation: [
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 1",
      bgImg: CardImage1,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 2",
      bgImg: CardImage2,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 3",
      bgImg: CardImage3,
    },
    { title: null, name: "Floral Blue", bgImg: CardImage4 },
    { title: null, name: "Floral Corners Blue", bgImg: CardImage5 },
    { title: null, name: "Floral Corners Red", bgImg: CardImage6 },
    { title: null, name: "Floral Peach", bgImg: CardImage7 },
    { title: null, name: "Floral Purple 1", bgImg: CardImage8 },
    { title: null, name: "Floral Purple 2", bgImg: CardImage9 },
    { title: null, name: "Floral Red", bgImg: CardImage10 },
    { title: null, name: "Floral White Green", bgImg: CardImage11 },
    { title: null, name: "Golden Frames 1", bgImg: CardImage12 },
    { title: null, name: "Golden Frames 2", bgImg: CardImage13 },
    { title: null, name: "Golden Frames 3", bgImg: CardImage14 },
    { title: null, name: "Heart Purple 1", bgImg: CardImage15 },
    { title: null, name: "Heart Purple 2", bgImg: CardImage16 },
    { title: null, name: "Heart Gold 1", bgImg: CardImage17 },
    { title: null, name: "Heart Gold 2", bgImg: CardImage18 },
    { title: null, name: "Hearts Pink 1", bgImg: CardImage19 },
    { title: null, name: "Hearts Pink 2", bgImg: CardImage20 },
    {
      title: "Fă parte din povestea noastră!",
      name: "Standard",
      bgImg: CardImage21,
    },
    { title: null, name: "Wedding Ring 1", bgImg: CardImage22 },
    { title: null, name: "Wedding Ring 2", bgImg: CardImage23 },
  ],
  corporate: [
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 1",
      bgImg: CardImage1,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 2",
      bgImg: CardImage2,
    },
    {
      title: "Fă parte din povestea noastră!",
      name: "Eucalyptus 3",
      bgImg: CardImage3,
    },
    { title: null, name: "Floral Blue", bgImg: CardImage4 },
    { title: null, name: "Floral Corners Blue", bgImg: CardImage5 },
    { title: null, name: "Floral Corners Red", bgImg: CardImage6 },
    { title: null, name: "Floral Peach", bgImg: CardImage7 },
    { title: null, name: "Floral Purple 1", bgImg: CardImage8 },
    { title: null, name: "Floral Purple 2", bgImg: CardImage9 },
    { title: null, name: "Floral Red", bgImg: CardImage10 },
    { title: null, name: "Floral White Green", bgImg: CardImage11 },
    { title: null, name: "Golden Frames 1", bgImg: CardImage12 },
    { title: null, name: "Golden Frames 2", bgImg: CardImage13 },
    { title: null, name: "Golden Frames 3", bgImg: CardImage14 },
    { title: null, name: "Heart Purple 1", bgImg: CardImage15 },
    { title: null, name: "Heart Purple 2", bgImg: CardImage16 },
    { title: null, name: "Heart Gold 1", bgImg: CardImage17 },
    { title: null, name: "Heart Gold 2", bgImg: CardImage18 },
    { title: null, name: "Hearts Pink 1", bgImg: CardImage19 },
    { title: null, name: "Hearts Pink 2", bgImg: CardImage20 },
    {
      title: "Fă parte din povestea noastră!",
      name: "Standard",
      bgImg: CardImage21,
    },
    { title: null, name: "Wedding Ring 1", bgImg: CardImage22 },
    { title: null, name: "Wedding Ring 2", bgImg: CardImage23 },
  ],
};

export const splitScentenceByMaxChars = (text, maxChars) => {
  const sentences = [];

  const words = text.split(" ");

  let currentSentence = "";

  for (let word of words) {
    if (currentSentence.length + word.length + 1 > maxChars) {
      sentences.push(currentSentence.trim());
      currentSentence = word;
    } else {
      currentSentence += (currentSentence.length === 0 ? "" : " ") + word;
    }
  }

  if (currentSentence) {
    sentences.push(currentSentence.trim());
  }

  return sentences;
};

export const ErrorTypes = {
  EventLimit: "EventLimit",
  EventOver: "EventOver",
  EventOverUser: "EventOverUser",
  EventBroken: "EventBroken",
};

export function formatDateString(isoString) {
  const date = new Date(isoString);

  const region = import.meta.env.VITE_REGION;
  const localeString = region === "ro" ? "ro-RO" : "default";
  const day = date.getUTCDate();
  const month = date.toLocaleString(localeString, { month: "long" });
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

export function formatDateStringShort(isoString) {
  const date = new Date(isoString);
  const region = import.meta.env.VITE_REGION;
  const localeString = region === "ro" ? "ro-RO" : "default";
  const day = date.getUTCDate();
  const month = date.toLocaleString(localeString, { month: "long" });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year} `;
}

export function getLocalStorageToken() {
  const jwtPattern = /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_+/]*$/;

  const token = localStorage.getItem("token");

  if (!token) return null;

  if (token === undefined || token === "undefined" || !jwtPattern.test(token)) {
    localStorage.clear();
    window.location.href = "/";
  }
  return token;
}

export function getLocalStorageUser() {
  const userData = JSON.parse(localStorage.getItem("user"));

  if (!userData) return null;

  if (
    (!userData._id || !userData.event || !userData.event?._id) &&
    userData._id !== "64b7ca4d8565dfcd3d2cdeca"
  ) {
    console.log("SUNTEM PE IF");
    localStorage.clear();
    window.location.href = "/";
  }
  return userData;
}

export const updateLocalUser = (displayName, email) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (email !== null) user.email = email;
  if (displayName !== null) user.displayName = displayName;
  localStorage.setItem("user", JSON.stringify(user));
};

export const ErrorMessages = {
  PaymentUsed: "Payment already used",
  UserNotFound: "User not found",
  PaymentNotFound: "Payment not found",
};

export function generatePin() {
  const pinLength = 6;
  let pin = "";

  for (let i = 0; i < pinLength; i++) {
    // Generate a random number between 0 and 9
    const randomDigit = Math.floor(Math.random() * 10);
    pin += randomDigit.toString();
  }

  return pin;
}

export const createEventPayload = (
  userId,
  data,
  paymentData,
  invitationCardData
) => ({
  user: userId,
  name: data.name,
  welcomeMessage: data.message,
  showImages: data.sharePics,
  showMessages: data.shareMessage,
  date: data.dateEvent,
  ptk: paymentData.uuid,
  pin: generatePin(),
  cardTitle: invitationCardData.cardTitle,
  cardText: invitationCardData.cardText,
});
