import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storageLocal from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import modalsReducer from "../reduxState/modals/modalsSlice";
import userReducer from "../reduxState/user/userSlice";

const userPersistConfig = {
  key: "user",
  storage: storageLocal,
};

const modalPersistConfig = {
  key: "modals",
  storage: storageSession,
};

let intervalId;

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  modals: persistReducer(modalPersistConfig, modalsReducer),
});

const ModalTimerMiddleware = (store) => (next) => (action) => {
  if (action.type === "modals/startDiscountModalTimer") {
    intervalId = setInterval(() => {
      store.dispatch({ type: "modals/tickDiscountModalTimer" });
    }, 1000);
  } else if (action.type === "modals/resetDiscountModalTimer") {
    clearInterval(intervalId);
  }

  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(ModalTimerMiddleware),
});

export const persistor = persistStore(store);
