export default {
	primaryColor: "#e7007e",
	infoColor: "#c0006a",
	infoBackgroundColor: "#fbe1ff",
	disabledBackgroundColor: "#e165a9",
	trackBackgroundColor: "#ec7fff",
	autofillBackgroundColor: "#d0d3ed",
	autofillBoxShadowColor: "#f7cdff",
	errorColor: "#ff3333",
};
