import axios from "axios";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const getEventById = async (eventId) => {
  const response = await axios.get(`${BASE_URL}/api/events/${eventId}`);
  return response.data;
};

export const createUser = async (payload) => {
  const response = await axios.post(`${BASE_URL}/api/users`, payload);
  return response.data;
};

export const updateUserById = async (payload) => {
  const response = await axios.put(`${BASE_URL}/api/users`, payload);
  return response.data;
};

export const createRemindMeLaterForUser = async (payload) => {
  const response = await axios.post(
    `${BASE_URL}/api/users/remindMeLater`,
    payload
  );
  return response.data;
};
