import { texts } from "../../../config/index";

const { sugestionsGuests } = texts;

const {
  sugestion1title,
  sugestion2title,
  sugestion3title,
  sugestion4title,
  sugestion5title,
  sugestion6title,
  sugestion7title,
  sugestion1subTitle,
  sugestion2subTitle,
  sugestion3subTitle,
  sugestion4subTitle,
  sugestion5subTitle,
  sugestion6subTitle,
  sugestion7subTitle,
} = sugestionsGuests;

export const sugestionRender = [
  {
    id: 1,
    name: "selfieWithYou",
    title: sugestion1title,
    subTitle: sugestion1subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
      console.log("Un selfie click", imageCameraInputRef);
    },
  },
  {
    id: 2,
    name: "firstDance",
    title: sugestion2title,
    subTitle: sugestion2subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
    },
  },
  {
    id: 3,
    name: "cake",
    title: sugestion3title,
    subTitle: sugestion3subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
    },
  },
  {
    id: 4,
    name: "bestDance",
    title: sugestion4title,
    subTitle: sugestion4subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
    },
  },
  {
    id: 5,
    name: "photoWithUs",
    title: sugestion5title,
    subTitle: sugestion5subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
    },
  },
  {
    id: 6,
    name: "groupPhotos",
    title: sugestion6title,
    subTitle: sugestion6subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
    },
  },
  {
    id: 7,
    name: "photoFromTable",
    title: sugestion7title,
    subTitle: sugestion7subTitle,
    iconClickHandler: (imageCameraInputRef) => {
      imageCameraInputRef.current.click();
    },
  },
];
