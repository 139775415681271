// ** React Imports
import React, { useEffect, useState } from "react";

// ** Mui Imports
import { Box, IconButton, Typography } from "@mui/joy";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// ** Third Party Imports
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Config Imports
import { images, styles } from "../../config/index";

// ** Components Imports
import TutoriaButtonGroup from "../TutoriaButtonGroup";

// ** Custom hooks
import useDiscountModalTimer from "../../hooks/useDiscountModalTimer";

const TutorialModal = ({ steps }) => {
  const [tutorialStep, setTutorialStep] = useState(0);
  const { pauseTimer, resumeTimer } = useDiscountModalTimer();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isTutorialOpened, setIsTutorialOpened] = useState(false);

  const completeTutorial = () => {
    setIsTutorialOpened(false);
    sessionStorage.setItem("isTutorialDone", "true");
  };

  const nextStep = (skipAll) => {
    setImageLoaded(false);
    if (skipAll) {
      completeTutorial();
    } else if (tutorialStep === steps.length - 1) {
      completeTutorial();
    } else {
      setTutorialStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    pauseTimer();
    return () => resumeTimer();
  }, []);

  useEffect(() => {
    const storageisTutorialDone = !!!JSON.parse(
      sessionStorage.getItem("isTutorialDone")
    );
    setIsTutorialOpened(storageisTutorialDone);
  }, []);

  return (
    <>
      {isTutorialOpened && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            top: 0,
            left: 0,
            width: "100vw",
            minHeight: "100vh",
            zIndex: 9999999,
            backgroundColor: "#F4F1F2",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              mt: 2,
              px: 3,
            }}
          >
            <LazyLoadImage
              src={images.logo}
              width={150}
              effect="blur"
              alt="logo"
              style={{
                visibility: "visible",
                backgroundColor: "#F4F1F2",
                opacity: 1,
                filter: "none",
              }}
            />

            <IconButton
              className="formButton"
              variant="soft"
              size="sm"
              sx={{
                color: "black",
                borderRadius: "50%!important",
                backgroundColor: "#E6E6E6",
                minHeight: "35px",
                height: "35px",
                width: "35px",
              }}
            >
              <CloseRoundedIcon
                onClick={() => {
                  nextStep(true);
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              top: 0,
              left: 0,
              zIndex: 9999999,
              height: "100%",
              backgroundColor: "#F4F1F2",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "60px",
            }}
          >
            <img
              src={steps[tutorialStep].image}
              alt={`Step ${tutorialStep + 1}`}
              style={{
                borderRadius: "15px",
                objectFit: "cover",
                width: "100%",
                maxWidth: "270px",
                height: "270px",
                backgroundColor: "#F4F1F2",
                opacity: imageLoaded ? 1 : 0.25,
                filter: imageLoaded ? "none" : "blur(10px)",
                transition:
                  "opacity 0.5s ease-in-out, filter 0.35s ease-in-out",
              }}
              onLoad={() => setImageLoaded(true)}
            />

            <Box>
              <Typography
                level="h1"
                sx={{ marginTop: "15px" }}
                textColor={styles.primaryColor}
                fontSize={30}
              >
                {steps[tutorialStep].title}
              </Typography>
              <Typography
                level="body2"
                sx={{
                  marginTop: "10px",
                  paddingBottom: "20px",
                  maxWidth: "350px",
                  margin: "auto",
                  fontWeight: 600,
                  overflowY: "auto",
                  maxHeight: "calc(1.5em * 8)",
                }}
                fontSize={14}
                textColor={"black"}
              >
                {steps[tutorialStep].text.trim()}
              </Typography>
              <Box sx={{ marginTop: "12px", mb: 4 }}>
                <TutoriaButtonGroup
                  tutorialStep={tutorialStep}
                  nextStep={nextStep}
                  stepsLength={steps.length}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TutorialModal;
