const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const dimensions = [
  { width: 1080, height: 800 },
  { width: 1080, height: 1620 },
  { width: 1080, height: 720 },
  { width: 1080, height: 721 },
  { width: 1080, height: 1620 },
  { width: 1080, height: 607 },
  { width: 1080, height: 608 },
  { width: 1080, height: 720 },
  { width: 1080, height: 1549 },
  { width: 1080, height: 720 },
  { width: 1080, height: 694 },
  { width: 1080, height: 1620 },
  { width: 1080, height: 720 },
  { width: 1080, height: 1440 },
  { width: 1080, height: 1620 },
  { width: 1080, height: 810 },
  { width: 1080, height: 610 },
  { width: 1080, height: 810 },
  { width: 1080, height: 720 },
  { width: 1080, height: 1440 },
];

const getImageSrc = (photo, eventId) => {
  const sourceLink = `${
    import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION
  }/api/images`;

  if (photo?.photoSrc) {
    return photo.photoSrc;
  }

  if (photo?.status === "uploading") {
    return "https://family.cloudofmemories.ro/api/images/filename/video_placeholder.png";
  }

  return `${sourceLink}/event/${eventId}/thumbnail/${photo._id}`;
};

const mediaAlbum = (uploadedImages, eventId, loadMore, showDelete) =>
  uploadedImages.map((photo, index) => {
    const width = breakpoints[0];
    const { _id: photoId, type: photoType, isDemoImage, isThumbnail } = photo;

    const { height: photoHeight, width: photoWidth } =
      dimensions[index % dimensions.length];

    const height = (photoHeight / photoWidth) * width;

    const img = getImageSrc(photo, eventId);

    const isLastItem = index === uploadedImages.length - 2;

    return {
      src: img,
      width,
      photoType,
      isDemoImage,
      isThumbnail,
      key: photoId,
      photoId,
      height,
      showDelete,
      loadMore,
      isLastItem,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((photoHeight / photoWidth) * breakpoint);
        return {
          src: img,
          width: breakpoint,
          height,
        };
      }),
    };
  });

export default mediaAlbum;
